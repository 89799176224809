import React from "react";
import LayoutPortal from "../components/Portal/LayoutPortal";
import WebEditEmbed from "../components/Tableau/WebEditEmbed";

function Report() {
  const [viz, setViz] = React.useState();
  const [vizUrl, setVizUrl] = React.useState(
    "https://clientreporting.theinformationlab.co.uk/t/WebEditEmbed/views/SuperstoreKPIs/Sample"
  );
  const [number, setNumber] = React.useState(0);
  const [text, setText] = React.useState("Edit Dashboard");

  function getEditMode() {
    viz.getCurrentUrlAsync().then((vizUrl) => {
      let editUrl = vizUrl.split("?")[0].replace("/views", "/authoring");
      if (viz) {
        console.log(editUrl);
        setViz(null);
        setNumber(() => number + 1);
        viz.dispose();
        setVizUrl(() => editUrl);
        setText("Back to View");
      }
    });
  }

  return (
    <LayoutPortal>
      <div className="px-4 py-4 sm:px-6">
        <button
          type="button"
          onClick={() => viz.showExportPDFDialog()}
          className="inline-flex mb-4 items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150 mr-2"
        >
          Export to PDF
        </button>
        <button
          type="button"
          onClick={() => viz.showExportCrossTabDialog()}
          className="inline-flex mb-4 items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150 mr-2"
        >
          Get Data
        </button>
        <button
          type="button"
          onClick={() => viz.showExportImageDialog()}
          className="inline-flex mb-4 items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150 mr-2"
        >
          Print Image
        </button>
        <button
          type="button"
          onClick={getEditMode}
          className="inline-flex mb-4 items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
        >
          {text}
        </button>
        {/* <img src={imageUrl} alt="dashboard" /> */}
        <WebEditEmbed url={vizUrl} viz={viz} setViz={setViz} number={number} />
      </div>
    </LayoutPortal>
  );
}

export default Report;
