import React from "react";
import LayoutPortal from "./LayoutPortal";

// grab the dashboards on the server
import { dashboardsQuery } from "../../utils/graphql";

import { API, Auth } from "aws-amplify";

// import dashboardCards
import DashboardCard from "./DashboardCard";
import { getAuthTokenTableau, setAuthTokenTableau } from "../../utils/auth";
import TabLayout from "./TabLayout";
import ModalDashboard from "./ModalDashboard";
import Loading from "./Loading";
// import { navigate } from "@reach/router";

function UserDashboards() {
  const [dashboard, setDashboard] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [fetchedDashboards, setfetchedDashboards] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const localState = JSON.parse(localStorage.getItem("localStatedashboards"));

  // this filter the dashboards on search
  const searchDashboards = !search
    ? fetchedDashboards
    : fetchedDashboards.filter((dashboard) =>
        dashboard.name.toLowerCase().includes(search)
      );

  function toggleModal() {
    setModal(!modal);
  }

  console.log("[UserDashoards.js] renders");

  async function login() {
    // login and also request auth token
    // console.log("About to get a token!");
    try {
      // uncomment for token request
      setLoading(true);
      if (!getAuthTokenTableau()) {
        let apiName = "embeddingAPI";
        let path = "/data/auth";
        let myInit = {
          // OPTIONAL
          headers: {}, // OPTIONAL
        };
        const token = await API.get(apiName, path, myInit);
        setAuthTokenTableau(token.authToken);
        // console.log("Got a token", getAuthTokenTableau());
        // setLoading(false);
      }
      const outputDataFromFetch = await fetchTableauResource().then((data) => {
        setLoading(false);
        return data;
      });
      if (outputDataFromFetch.data.error) {
        console.log("Error", outputDataFromFetch);
        window.sessionStorage.authToken = null;

        setError(true);
      } else {
        // console.log("Success", outputDataFromFetch);
        window.localStorage.setItem(
          "localStatedashboards",
          JSON.stringify(outputDataFromFetch.data.data.dashboards)
        );

        setfetchedDashboards(outputDataFromFetch.data.data.dashboards);
        setError(false);
      }
    } catch (err) {
      // if there's error show error component and also set authToken to null
      console.log("There is an error");
      setLoading(false);

      setError(true);

      window.sessionStorage.authToken = null;
      return;
    }
  }

  async function fetchTableauResource() {
    const query = dashboardsQuery();
    const authToken = await getAuthTokenTableau();
    try {
      let apiName = "embeddingAPI";
      let path = "/data/graphql";
      let myInit = {
        body: { query, authToken },
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = await API.post(apiName, path, myInit);
      return data;
    } catch (error) {
      console.log("Got an error", error);
      return;
    }
  }

  React.useEffect(() => {
    if (localState === null || !localState) {
      // Grabbing the dashboards from API request
      console.log("Getting dashboards by API request");
      login();
    } else {
      // Grabbing the dashboards from local state and setting the dashboard state
      console.log("Getting dashboards by local state");
      setfetchedDashboards(localState);
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log({ user });
      })
      .catch((err) => {
        window.localStorage.setItem("localStatedashboards", null);
        window.sessionStorage.authToken = null;

        setError(err);
      });
  }, []);

  return (
    <LayoutPortal>
      <TabLayout />
      <div className="px-4 py-4 sm:px-6">
        <div className="py-4">
          <label
            htmlFor="filter_courses"
            className="block text-sm font-medium leading-5 text-gray-700"
          ></label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                />
              </svg>
            </div>
            <input
              id="filter_courses"
              className="form-input block w-full pl-10 sm:text-sm sm:leading-5"
              placeholder="Search dashboards..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* <FilterSections /> */}
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          {loading && <Loading />}
          {error && <p>Error! Please login again.</p>}
          {fetchedDashboards && !loading && (
            <ul>
              {modal && (
                <ModalDashboard
                  modal={modal}
                  setModal={setModal}
                  dashboard={dashboard}
                />
              )}
              {/* {listOfDashboards.map((dashboard, index) => (
                <DashboardCard
                  key={index}
                  toggleModal={toggleModal}
                  dashboard={dashboard}
                  setDashboard={setDashboard}
                />
              ))} */}
              {searchDashboards.map((fetchDashboard, index) => (
                <DashboardCard
                  key={index}
                  toggleModal={toggleModal}
                  dashboard={fetchDashboard}
                  setDashboard={setDashboard}
                />
              ))}
              {searchDashboards.length === 0 && <p>No results found</p>}
            </ul>
          )}
        </div>
      </div>
    </LayoutPortal>
  );
}

export default UserDashboards;
