import React from "react";
import { useOnClickOutside } from "../../utils/useOnClickOutside";
import { getTrustedTicket } from "../../utils/trustedToken";
import { fetchJWT } from "../../utils/connectedApp";
// import { getCurrentUser } from "../../utils/auth";
import { GlobalContext } from "../../context/GlobalState";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

function ModalDashboard({ modal, setModal, dashboard }) {
  const [viz, setViz] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [width, setWidth] = React.useState(getWidth());
  const [vizReady, setVizReady] = React.useState(false);

  const vizIsReady = async (event) => {
    console.log("Viz is ready");
    setVizReady(true);
    setshowEl(true);
  };
  const vizRef = React.useRef(null);

  React.useEffect(() => {
    console.log("[ModalDashboard.js] VizRef", vizRef);
    if (vizRef.current) {
      console.log("[ModalDashboard.js] VizRef", vizRef);
      const vizEl = vizRef.current;
      vizEl.addEventListener("firstinteractive", vizIsReady);
    }
  }, [vizRef]);

  const ref = React.useRef();
  const dashboardRef = React.useRef(null);
  const { userAttributes } = React.useContext(GlobalContext);
  const [showEl, setshowEl] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(false);
  const dropdownRef = React.useRef();
  const vizUrl = `https://clientreporting.theinformationlab.co.uk/t/Embedding/views/${dashboard.path}`;

  useOnClickOutside(ref, () => setModal(false));
  useOnClickOutside(dropdownRef, () => setDropdown(false));

  const loadViz = () => {
    setViz(
      <tableau-viz
        ref={vizRef}
        id="tableauViz"
        src={vizUrl}
        hide-tabs={true}
        token={token}
        toolbar="hidden"
        device="desktop"
        width={1200}
        height={ref.offsetHeight}
      />
    );
  };

  async function getToken() {
    try {
      const centralTILMail = "andre.devries@theinformationlab.co.uk";
      const person =
        userAttributes.email === "tim.ngwena@theinformationlab.co.uk"
          ? "Tim Ngwena"
          : centralTILMail;
      const jwtToken = await fetchJWT(person);
      setToken(jwtToken);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError(err);
    }
  }

  React.useEffect(() => {
    if (token) {
      loadViz();
      setTimeout(() => {
        const vizEl = vizRef.current;
        vizEl.addEventListener("firstinteractive", vizIsReady);
      }, 1000);
    }
    return () => {
      console.log("Cleaning up!");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  React.useEffect(() => {
    getToken();
  }, []);

  return (
    <div>
      <div
        className={`${
          modal ? "block" : "hidden"
        }  fixed  bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center`}
      >
        <div
          className={`${
            modal ? "block" : "hidden"
          } fixed inset-0 transition-opacity`}
        >
          <div className="absolute inset-0 bg-gray-500  opacity-75"></div>
        </div>

        <div
          ref={ref}
          className={`${
            modal ? "block" : "hidden"
          }  bg-white max-w-7xl mx-auto sm:px-6 lg:px-8 rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all`}
        >
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {dashboard.name}
              </h3>
              {showEl && (
                <div className="flex justify-end">
                  <span
                    ref={dropdownRef}
                    className="relative z-50 inline-flex shadow-sm"
                  >
                    <button
                      type="button"
                      // onClick={() => setDropdown(!dropdown)}
                      className="relative inline-flex items-center px-2.5 py-1.5 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                    >
                      Export
                    </button>
                    <span className="-ml-px relative block">
                      <button
                        type="button"
                        onClick={() => setDropdown(!dropdown)}
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                      >
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {/* // dropdown menu */}
                      <div
                        className={`${
                          dropdown
                            ? "origin-top-right absolute right-0 mt-2 -mr-1 w-32 rounded-md shadow-lg text-left z-50"
                            : "hidden"
                        } `}
                      >
                        <div className="rounded-md bg-white shadow-xs">
                          <div className="py-1">
                            <div
                              type="button"
                              onClick={() => viz.showExportImageDialog()}
                              className="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            >
                              Image
                            </div>
                            <div
                              type="button"
                              onClick={() => viz.showExportCrossTabDialog()}
                              className="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            >
                              Data
                            </div>
                            <div
                              type="button"
                              onClick={() => viz.showExportPDFDialog()}
                              className="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            >
                              PDF
                            </div>
                            <div
                              type="button"
                              onClick={() => viz.showExportPowerPointDialog()()}
                              className="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            >
                              PowerPoint
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
              )}
              <div className="mt-2 z-0">
                <div className="text-sm leading-5 text-gray-500">
                  <div className="w-auto h-auto" ref={dashboardRef} />
                  {viz}
                </div>
              </div>
            </div>
          </div>
          {showEl && (
            <div className={`${showEl ? "block" : "hidden"} mt-5 sm:mt-6`}>
              <span className="flex w-full rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={(modal) => setModal(!modal)}
                  className="inline-flex mb-4 items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                >
                  Go back to index
                </button>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalDashboard;
