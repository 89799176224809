import React, { useContext } from "react";
import IndustryCard from "../components/Industry/IndustryCard";
import Layout from "../components/Layout";
import { industries } from "../utils/content/industries";
import { usePageTitle } from "../utils/usePageTitle";
import { GlobalContext } from "../context/GlobalState";

function AllIndustries({ title }) {
  usePageTitle(title);
  const { language, Dictionary } = useContext(GlobalContext);
  return (
    <Layout>
      <div className="max-w-screen-xl-til mx-auto py-6 sm:px-6 lg:px-8 antialiased">
        <div className="overflow-hidden">
          <div className="relative max-w-screen-xl-til mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-til-blue text-white">
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="lg:col-span-1">
              <h3 className="mt-4 text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                {Dictionary.industries[language].title}
              </h3>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl  md:mt-5 md:text-xl lg:mx-0">
                {Dictionary.industries[language].desc}
              </p>
            </div>
            <div className="grid gap-5 max-w-lg mx-auto lg:mt-8 sm:mt-24 md:mt-24 sm:grid-cols-2 sm:max-w-none md:grid-cols-3 md:max-w-none lg:grid-cols-3 lg:max-w-none">
              {industries.map((industry) => {
                return <IndustryCard key={industry.id} industry={industry} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AllIndustries;
