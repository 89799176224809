import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

const socialLinks = {
  en: {
    facebook: "https://en-gb.facebook.com/theinformationlab/?ref=page_internal",
    twitter: "https://twitter.com/infolabUK",
    github: "https://github.com/TheInformationLab",
    website: "https://theinformationlab.co.uk",
  },
  fr: {
    facebook: null,
    twitter: "https://twitter.com/infolabFR",
    github: null,
    website: "https://theinformationlab.fr",
  },
  es: {
    facebook: null,
    twitter: "https://twitter.com/infolabES",
    github: null,
    website: "https://theinformationlab.es",
  },
  it: {
    facebook: "https://www.facebook.com/INFOLABIT/",
    twitter: "https://twitter.com/infolabit",
    github: null,
    website: "https://theinformationlab.it",
  },
  de: {
    facebook: null,
    twitter: "https://twitter.com/infolabde",
    github: null,
    website: "https://theinformationlab.de",
  },
};

const copyright = {
  en: <span>The Information Lab UK. All rights reserved.</span>,
  es: <span>The Information Lab ES. Todos los derechos reservados.</span>,
  it: <span>The Information Lab IT. Tutti i diritti riservati.</span>,
  de: <span>The Information Lab DE. Alle Rechte vorbehalten.</span>,
  fr: (
    <span>
      The Information Lab FR.{" "}
      <a
        href="https://www.theinformationlab.fr/cookies/"
        target="_blank"
        rel="noopener noreferrer"
        className="ml-6"
      >
        Cookies
      </a>{" "}
      |{" "}
      <a
        href="https://www.theinformationlab.fr/mentions-legales/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Mentions légales
      </a>{" "}
      |{" "}
      <a
        href="https://www.theinformationlab.fr/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Données personnelles
      </a>
    </span>
  ),
};

function Footer() {
  const { language } = useContext(GlobalContext);
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl-til mx-auto overflow-hidden py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center md:order-2">
          {socialLinks[language].facebook ? (
            <a
              href={socialLinks[language].facebook}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          ) : null}
          {socialLinks[language].twitter ? (
            <a
              href={socialLinks[language].twitter}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-6 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          ) : null}
          {socialLinks[language].github ? (
            <a
              href={socialLinks[language].github}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-6 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">GitHub</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          ) : null}
          {socialLinks[language].website ? (
            <a
              href={socialLinks[language].website}
              rel="noopener noreferrer"
              target="_blank"
              className="ml-6 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Web</span>
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
              </svg>
            </a>
          ) : null}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base leading-6 text-gray-400">
            &copy; <span>{new Date().getFullYear()}</span> {copyright[language]}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
