import React from "react";

function Certified({ certified }) {
  const upStreamCertified =
    certified.upstreamDatasources.length > 0
      ? certified.upstreamDatasources[0].isCertified
      : false;
  if (upStreamCertified) {
    return (
      <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
        <svg
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
        Certified Datasource
      </div>
    );
  } else {
    return (
      <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
        <svg
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            fillRule="evenodd"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            clipRule="evenodd"
          />
        </svg>
        Datasource Not Certified
      </div>
    );
  }
}

export default Certified;
