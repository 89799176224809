import React from "react";

import { Link } from "@reach/router";
import IndustryIcons from "../components/Industry/IndustryIcons";

function IndustryItem({
  industryName,
  industryNameEn,
  industryDescription,
  slug,
  open,
}) {
  return (
    <div className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
      <IndustryIcons icon={industryNameEn} />
      <div className="space-y-1">
        <p className="text-base leading-6 font-medium text-gray-900">
          {industryName}
        </p>
        <p className="text-sm leading-5 text-gray-500">{industryDescription}</p>
      </div>
    </div>
  );
}

export default IndustryItem;
