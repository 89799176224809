import React from "react";
import ReactDOM from "react-dom";
import "./styles/tailwind.css";
// import "./index.css";
import App from "./App";
import Amplify from "aws-amplify";
import config from "./aws-exports";

const { NODE_ENV } = process.env;
const DEFAULT_URL = `http://localhost:3000/`;

if (NODE_ENV === "development") {
  // window.LOG_LEVEL = "DEBUG";
  console.log("Dev mode");
  config.oauth.redirectSignIn = `${DEFAULT_URL}portal/dashboards/`;
  config.oauth.redirectSignOut = `${DEFAULT_URL}login/`;
  console.log(config);
} else {
  //   console.log("Prod mode");
  //   this is for auth-dev branch
  //   config.oauth.redirectSignIn = `https://auth-dev.d2dtuo4vwwb28p.amplifyapp.com/portal/dashboards/`;
  //   config.oauth.redirectSignOut = `https://auth-dev.d2dtuo4vwwb28p.amplifyapp.com/login/`;
  //   this is for prod branch
  config.oauth.redirectSignIn = `https://embedding.theinformationlab.co.uk/portal/dashboards/`;
  config.oauth.redirectSignOut = `https://embedding.theinformationlab.co.uk/login/`;
}

Amplify.configure(config);

ReactDOM.render(<App />, document.getElementById("root"));
