import React, { useContext } from "react";
import { Link } from "@reach/router";
import AnimatedHeader from "../utils/AnimatedHeader";
import { GlobalContext } from "../context/GlobalState";

// import global context

function Landing() {
  const [value, setValue] = React.useState(0);
  const { language, Dictionary } = useContext(GlobalContext);

  return (
    <>
      <main>
        <div className="max-w-screen-xl-til mx-auto py-8 sm:px-6 lg:px-8 antialiased">
          <div className="px-4 py-4 sm:px-0">
            <div className="rounded-lg h-72">
              <div className="mt-8 mx-auto max-w-screen-xl-til px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-16 lg:px-8 xl:mt-20">
                <div className="sm:text-center lg:text-left">
                  <AnimatedHeader value={value} setValue={setValue} />
                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    {Dictionary.home[language].desc}
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      <Link
                        to="/contact"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-til-blue hover:bg-til-purple focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      >
                        {Dictionary.home[language].contactUs}
                      </Link>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      {/*  */}
                      <Link
                        to="/basic"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-700 bg-blue-100 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      >
                        {Dictionary.home[language].liveExample}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Landing;
