import { navigate } from "@reach/router";
import React from "react";

// import Layout
import Layout from "../components/Layout";
// import login
import Login from "../components/Portal/Login";
import { GlobalContext } from "../context/GlobalState";
import { usePageTitle } from "../utils/usePageTitle";

function Portal({ title }) {
  console.log("[Portal.js] is rendering");
  const { userAttributes } = React.useContext(GlobalContext);

  if (Object.keys(userAttributes).length === 0) {
    console.log("Not logged in yet");
  } else {
    console.log("We got details, and can redirect if needed");
    navigate("/portal/dashboards/");
  }

  // console.log("Global user attributes", userAttributes);

  usePageTitle(title);
  return (
    <Layout>
      <Login />
    </Layout>
  );
}

export default Portal;
