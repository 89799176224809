import { API } from "aws-amplify";

export const fetchJWT = async (username) => {
  const apiName = "embeddingAPI";
  const path = `/auth/token/${username}`;
  const config = {
    method: "POST",
    headers: {},
  };
  console.log({ first: username, second: config });
  const res = await API.post(apiName, path, config);

  if (res) {
    const { token } = res;
    console.log(token);
    return token;
  } else {
    console.error("No token");
    return;
  }
};
