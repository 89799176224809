import React from "react";
import LayoutPortal from "./LayoutPortal";
import TabLayout from "./TabLayout";
// import Loading from "./Loading";

function UserViews() {
  return (
    <LayoutPortal>
      <TabLayout />
      <div className="px-4 py-4 sm:px-6">
        <p>These are my views</p>
        {/* <Loading /> */}
      </div>
    </LayoutPortal>
  );
}

export default UserViews;
