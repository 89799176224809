import React, { useContext } from "react";
import Layout from "../Layout";
import BreadCrumb from "./BreadCrumb";
import { industries } from "../../utils/content/industries";
import DesktopEmbed from "../Tableau/DesktopEmbed";
import { GlobalContext } from "../../context/GlobalState";

function IndustryDashboardPage(props) {
  const [viz, setViz] = React.useState(null);
  const { language } = useContext(GlobalContext);

  console.log(industries);

  const dashboardToShow = industries
    .filter((industry) => {
      const industryName = industry.name["en"];
      console.log(
        "[IndustryDashboardPage.js] selected industry:",
        props.selectedIndustry
      );
      console.log("[IndustryDashboardPage.js] industryName:", industryName);
      return (
        industryName.replace(/ /g, "").replace(/\?/g, "").toLowerCase() ===
        props.selectedIndustry
      );
    })[0]
    .dashboards.filter(
      (d) =>
        d.dashboardName["en"].replace(/\s|\?/g, "").toLowerCase() ===
        props.dashboard
    )[0];
  const selectedIndustry = props.selectedIndustry;
  const selectedIndustryIdx = industries.findIndex((industry) => {
    const industryName = industry.name["en"];
    return (
      industryName.replace(/ /g, "").toLowerCase() === props.selectedIndustry
    );
  });
  const selectedIndustryName = industries[selectedIndustryIdx].name[language];
  const dashboardProp = {
    ...dashboardToShow,
    selectedIndustry,
    selectedIndustryName,
  };

  console.log(props);
  console.log(dashboardProp);

  return (
    <Layout>
      <div className="max-w-screen-xl-til mx-auto py-6 sm:px-6 lg:px-8 antialiased">
        <div className="overflow-hidden">
          <BreadCrumb dashboardProps={dashboardProp} viz={viz} />
          <div className="flex relative max-w-screen-xl-til mx-auto py-8 ">
            <div className="content-center mx-auto">
              {dashboardProp.url && (
                <DesktopEmbed
                  url={dashboardProp.url}
                  viz={viz}
                  setViz={setViz}
                  hideTabs={dashboardProp.hideTabs ?? true}
                />
              )}
              {!dashboardProp.url && <div>Dashboard here!</div>}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IndustryDashboardPage;
