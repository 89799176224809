import React, { useContext } from "react";
// import Layout
import Layout from "../components/Layout";
// import card component
import Card from "../components/Card";

// import the examples from utils folder
import { content } from "../utils/examples";
import { usePageTitle } from "../utils/usePageTitle";
import { GlobalContext } from "../context/GlobalState";

function Examples({ title }) {
  usePageTitle(title);
  const { language, Dictionary } = useContext(GlobalContext);
  return (
    <Layout>
      <div className="max-w-screen-xl-til mx-auto py-6 sm:px-6 lg:px-8 antialiased">
        <div className="overflow-hidden">
          <div className="relative max-w-screen-xl-til mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <svg
              className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="svg-pattern-squares-1"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#svg-pattern-squares-1)"
              />
            </svg>

            <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8 gap-4">
              <div className="lg:col-span-1">
                <h3 className="mt-4 text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  {Dictionary.inspire[language].title}
                </h3>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl  md:mt-5 md:text-xl lg:mx-0">
                  {Dictionary.inspire[language].desc}
                </p>
              </div>

              <div className="mt-10 sm:ml-2 sm:gap-8 sm:grid sm:grid-cols-3 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
                {content.map((example, id) => {
                  return <Card key={id} example={example} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Examples;
