import React from "react";
import Tab from "./Tab";
import { Link } from "@reach/router";

function TabLayout() {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (index) => {
    setValue(index);
    console.log(index);
  };

  const NavLink = React.forwardRef((props, ref) => (
    <Link
      {...props}
      getProps={({ isPartiallyCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        if (isPartiallyCurrent) {
          handleTabChange(props.index);
        }
      }}
    />
  ));

  return (
    <>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <Tab
              index={0}
              label={"Your Views"}
              component={NavLink}
              toLink={"/portal/dashboards"}
              onClick={(e, val) => {
                setValue(val);
                console.log(value);
              }}
            />
            <Tab
              index={1}
              label={"Recommended Views"}
              component={NavLink}
              toLink={"/portal/views"}
            />
            <Tab
              index={2}
              label={"Your Account"}
              component={NavLink}
              toLink={"/portal/account"}
            />
            <Tab
              index={3}
              label={"Settings"}
              component={NavLink}
              toLink={"/portal/settings"}
              onClick={(e, val) => {
                setValue(val);
                console.log(value);
              }}
            />
          </nav>
        </div>
      </div>
    </>
  );
}

export default TabLayout;
