import React from "react";
import { withAuthenticator, SignUp, Greetings } from "aws-amplify-react";

function SignUpComponent() {
  return (
    <div>
      <p>You are already signed in! Please sign out to create an account</p>
    </div>
  );
}

export default withAuthenticator(SignUpComponent, {
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
  },
});
