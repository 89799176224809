import React, { createContext, useState, useEffect } from "react";
import Dictionary from "./language.json";
// import AppReducer from "./AppReducer";

// initial state
const initialState = {
  darkMode: false,
};

export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [mode, setMode] = useState(false);
  const [language, setLanguage] = useState("en");
  const [globalUser, setglobalUser] = useState(null);
  const [authToken, setAuthToken] = useState();
  const [userAttributes, setUserAttributes] = useState({});

  useEffect(() => {
    let lang = "en";
    const browserLang =
      navigator.userLanguage ||
      (navigator.languages &&
        navigator.languages.length &&
        navigator.languages[0]) ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      "en";
    if (
      window.location.hostname.indexOf(".fr") > -1 ||
      browserLang.indexOf("fr") > -1
    ) {
      lang = "fr";
    }
    if (
      window.location.hostname.indexOf(".es") > -1 ||
      browserLang.indexOf("es") > -1
    ) {
      lang = "es";
    }
    if (
      window.location.hostname.indexOf(".it") > -1 ||
      browserLang.indexOf("it") > -1
    ) {
      lang = "it";
    }
    if (
      window.location.hostname.indexOf(".de") > -1 ||
      browserLang.indexOf("de") > -1
    ) {
      lang = "de";
    }
    console.log("[Globaltate.js] Detected language:", lang);
    setLanguage(lang);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        mode,
        setMode,
        globalUser,
        setglobalUser,
        authToken,
        setAuthToken,
        userAttributes,
        setUserAttributes,
        language,
        setLanguage,
        Dictionary,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
