import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "@reach/router";

// import NavLinks from utils
import { NavLink, NavLinkHome } from "../utils/Links";

// import global context
import { GlobalContext } from "../context/GlobalState";

// import useOutside click hook
import { useOnClickOutside } from "../utils/useOnClickOutside";
import { useEscape } from "../utils/useEscape";

// User login navigation in the header
import { isLoggedIn, gotSessionToken } from "../utils/auth";
import Logo from "../utils/Logo";
import IndustryLink from "../utils/IndustryLink";
// import CircleDropdown from "./CircleDropdown";
import LanguageSelect from "../utils/LanguageSelect";

function Header() {
  // create a ref
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [mobile, setMobile] = useState(false);

  // set routes for the portal
  const [portalRoute, setPortalRoute] = useState("/login");
  // dark mode context
  // eslint-disable-next-line
  const { mode, setMode, language, setLanguage, Dictionary } =
    useContext(GlobalContext);

  // hooks for outside click and escape key
  useOnClickOutside(ref, () => setOpen(false));
  useEscape(open, () => setOpen(false));

  // toggle the state of the three dots dropdown
  function toggle() {
    setOpen(!open);
    if (mobile) {
      setMobile(!mobile);
    }
  }
  function mobileToggle() {
    setMobile(!mobile);
    if (open) {
      setMobile(!mobile);
    }
  }

  useEffect(() => {
    if (isLoggedIn() && gotSessionToken()) {
      setPortalRoute("/portal/dashboards");
    }
  }, []);

  return (
    <nav className="shadow antialiased z-50 bg-white">
      <div className="max-w-screen-xl-til mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex">
              <NavLinkHome mode={mode.toString()} to="/">
                {Dictionary.menu[language].home}
              </NavLinkHome>
              <NavLink mode={mode.toString()} to="/basic">
                {Dictionary.menu[language].explore}
              </NavLink>
              <NavLink mode={mode.toString()} to="/examples">
                {Dictionary.menu[language].inspire}
              </NavLink>
              <NavLink mode={mode.toString()} to="/videos">
                {Dictionary.menu[language].learn}
              </NavLink>
              {/* <NavLink
                mode={mode.toString()}
                to="https://playground.theinformationlab.io/"
              >
                {Dictionary.menu[language].playground}
              </NavLink> */}
              <NavLink mode={mode.toString()} to="/contact">
                {Dictionary.menu[language].contact}
              </NavLink>
              {/* <NavLink mode={mode.toString()} to={portalRoute}>
                Portal
              </NavLink> */}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center space-x-8">
            <LanguageSelect
              language={language}
              selectLanguage={(lang) => setLanguage(lang)}
            />
            <IndustryLink />
            {/* <CircleDropdown /> */}
            <span className="ml-3 shadow-sm rounded-md hidden">
              <Link to={portalRoute}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-til-blue hover:bg-til-purple focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  {Dictionary.menu[language].login}
                </button>
              </Link>
            </span>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={mobileToggle}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  className="inline-flex"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className="hidden"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${mobile ? "block" : "hidden"}`}>
        <div className="pt-2 pb-3">
          <NavLink
            to="/"
            className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
          >
            {Dictionary.menu[language].home}
          </NavLink>
          <NavLink
            to="/basic"
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            {Dictionary.menu[language].explore}
          </NavLink>
          <NavLink
            to="/examples"
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            {Dictionary.menu[language].inspire}
          </NavLink>
          <NavLink
            to="/videos"
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            {Dictionary.menu[language].learn}
          </NavLink>
          <NavLink
            to="/contact"
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            {Dictionary.menu[language].contact}
          </NavLink>
          <NavLink
            to={portalRoute}
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            {Dictionary.menu[language].portal}
          </NavLink>
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4"></div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
