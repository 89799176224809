import React, { useContext } from "react";

import background from "../images/bg-img.svg";
import { motion } from "framer-motion";
import { GlobalContext } from "../context/GlobalState";

function Feature() {
  const { language, Dictionary } = useContext(GlobalContext);
  return (
    <div className="py-8 bg-white antialiased">
      <div
        className="max-w-screen-xl-til mx-auto px-4 sm:px-6 lg:px-8 pb-8 pt-8"
        style={{ backgroundImage: `url(${background})`, fillOpacity: "0.09" }}
      >
        <div className="lg:text-center">
          <p className="text-base leading-6 text-til-blue font-semibold tracking-wide uppercase">
            {Dictionary.home[language].ea}
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {Dictionary.home[language].eaCallout}
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            {Dictionary.home[language].eaDesc}
          </p>
        </div>

        <div className="mt-10">
          <ul className="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
            <motion.li whileHover={{ scale: 1.1 }} className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-til-blue text-white">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    {Dictionary.home[language].ea1Title}
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    {Dictionary.home[language].ea1Desc}
                  </p>
                </div>
              </div>
            </motion.li>
            <motion.li whileHover={{ scale: 1.1 }} className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-til-blue text-white">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    {Dictionary.home[language].ea2Title}
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    {Dictionary.home[language].ea2Desc}
                  </p>
                </div>
              </div>
            </motion.li>
            <motion.li whileHover={{ scale: 1.09 }} className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-til-blue text-white">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    {Dictionary.home[language].ea3Title}
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    {Dictionary.home[language].ea3Desc}
                  </p>
                </div>
              </div>
            </motion.li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Feature;
