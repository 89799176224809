import React, { useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "@reach/router";

import { industries } from "../utils/content/industries";
import IndustryItem from "./IndustryItem";
import { GlobalContext } from "../context/GlobalState";

function DropdownLink(props) {
  const { href, children, ...rest } = props;
  return (
    <Link
      to={href}
      className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
    >
      <a {...rest}>{children}</a>
    </Link>
  );
}

function IndustryLink() {
  const { language, Dictionary } = useContext(GlobalContext);
  return (
    <div className="relative outline-none">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              type="button"
              className="text-gray-500 group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              <span>{Dictionary.home[language].industries}</span>
              <svg
                className="h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Menu.Button>
            <Transition
              show={open}
              enter={"transition ease-out duration-100"}
              enterFrom={"opacity-0 translate-y-1"}
              enterTo={"opacity-100 translate-y-0"}
              leave={"transition ease-in duration-75"}
              leaveFrom={"opacity-100 translate-y-0"}
              leaveTo={"opacity-0 translate-y-1"}
            >
              <Menu.Items className="origin-top-right right-0 absolute mt-3 px-2 w-screen max-w-xs sm:px-0 outline-none">
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg shadow-xs overflow-hidden">
                    <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      {industries.slice(0, 4).map((industry) => {
                        return (
                          <Menu.Item
                            key={industry.id}
                            as={DropdownLink}
                            href={`//showcase.theinformationlab.co.uk/${language}${industry.slug.replace("/industry", "")}`}
                          >
                            {({ active }) => (
                              <IndustryItem
                                industryName={industry.name[language]}
                                industryNameEn={industry.name.en}
                                open={open}
                                industryDescription={
                                  industry.shortDescription[language]
                                }
                                slug={industry.slug}
                              />
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                    <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                      <div className="flow-root">
                        <a
                          href={`https://showcase.theinformationlab.co.uk/${language}`}
                          className="-m-3 p-3 flex items-center space-x-3 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>{Dictionary.home[language].allIndustries}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default IndustryLink;
