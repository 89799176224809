export const userAuthentication = (email) => `{
   tableauUsers (filter: {email: "${email}"}) {
     name
     email
  }
}`;

export const basicQuery = () => `{
  tableauUsers {
    name
    email
  }
}`;

export const dashboardsQuery = () => `{
  dashboards {
    name
    id
    path
    workbook {
      name
      projectName
      owner {
        id
        name
      }
      description
      createdAt
      updatedAt
      upstreamDatabases {
        name
        isCertified
      }
      upstreamDatasources {
        name
        isCertified
      }
    }
  }
}`;
