import React from "react";
import { motion } from "framer-motion";

const loadingContainer = {
  start: {
    transition: {
      staggerChildren: 0.3,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const loadingBar = {
  start: {
    y: "0%",
    backgroundColor: "#D3D3D3",
  },
  end: {
    y: "100%",
    backgroundColor: "#152939",
  },
};

const loadingBarTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: "easeInOut",
};

function Loading() {
  return (
    <motion.div
      variants={loadingContainer}
      initial="start"
      animate="end"
      className="text-center flex flex-row gap-1 px-32 py-32 sm:px-6 items-center justify-center"
    >
      <motion.div
        variants={loadingBar}
        transition={loadingBarTransition}
        className="m-1 h-16 w-4 bg-teal-900"
      ></motion.div>
      <motion.div
        variants={loadingBar}
        transition={loadingBarTransition}
        className="m-1 h-16 w-4 bg-teal-900"
      ></motion.div>
      <motion.div
        variants={loadingBar}
        transition={loadingBarTransition}
        className="m-1 h-16 w-4 bg-teal-900"
      ></motion.div>
      <motion.div
        variants={loadingBar}
        transition={loadingBarTransition}
        className="m-1 h-16 w-4 bg-teal-900"
      ></motion.div>
      <motion.div
        variants={loadingBar}
        transition={loadingBarTransition}
        className="m-1 h-16 w-4 bg-teal-900"
      ></motion.div>
    </motion.div>
  );
}

export default Loading;
