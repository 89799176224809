import React from "react";
import { Link } from "@reach/router";

// the first link has no 8 margin on the left
export const NavLinkHome = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent
          ? "inline-flex items-center px-1 pt-1 border-b-2 border-til-blue text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out"
          : "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out",
      };
    }}
  />
);
// this is for all the other links
export const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      let darkModeLink = props.mode === "true";
      let setClassName;
      if (!darkModeLink && isCurrent) {
        setClassName = {
          className:
            "ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-til-blue text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out",
        };
      } else if (!darkModeLink && !isCurrent) {
        setClassName = {
          className:
            "ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out",
        };
      } else if (darkModeLink && isCurrent) {
        setClassName = {
          className:
            "ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-til-blue text-sm font-medium leading-5 text-gray-100 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out",
        };
      } else if (darkModeLink && !isCurrent) {
        setClassName = {
          className:
            "ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-200 hover:border-gray-300 focus:outline-none focus:text-gray-300 focus:border-gray-300 transition duration-150 ease-in-out",
        };
      }
      return setClassName;
    }}
  />
);

// creat active navlink for portal
export const NavLinkPortal = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent
          ? "px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:text-white focus:bg-gray-700 bg-gray-900 text-white"
          : "px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:text-white focus:bg-gray-700",
      };
    }}
  />
);
