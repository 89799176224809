import React, { useContext } from "react";
import DashboardItem from "../components/Industry/DashboardItem";
import Layout from "../components/Layout";
import { Link } from "@reach/router";
import { GlobalContext } from "../context/GlobalState";

import { usePageTitle } from "../utils/usePageTitle";

import { industries } from "../utils/content/industries";

function IndustryPage(props) {
  // filter to the industry of the page and grab first element because we get an array back
  const industryContent = industries.filter(
    (industry) => industry.slug === props.uri
  )[0];
  const { language } = useContext(GlobalContext);

  usePageTitle(industryContent.name[language]);

  return (
    <Layout>
      <div className="max-w-screen-xl-til mx-auto py-6 sm:px-6 lg:px-8 antialiased">
        <div className="overflow-hidden">
          <div className="relative max-w-screen-xl-til mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <nav className="mb-2">
              <Link
                to="/industries"
                className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
              >
                <svg
                  className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                All
              </Link>
            </nav>
            <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
              <div className="space-y-5 sm:space-y-4">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">
                  {industryContent.name[language]}
                </h2>
                <p className="text-xl leading-7 text-gray-500">
                  {industryContent.longDescription[language]}
                </p>
              </div>
              <div className="lg:col-span-2">
                <ul
                  role="list"
                  className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
                >
                  <DashboardItem industryContent={industryContent} />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IndustryPage;
