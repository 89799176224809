import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";

import Error from "../Error";

import { Auth, Hub } from "aws-amplify";
import { setUser, isLoggedIn } from "../../utils/auth";

function Login() {
  const [username, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // state for Google auth
  // const [user, setUser] = useState(null);

  // get user function for Google auth
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  function checkUser() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log({ user });
      })
      .catch((err) => setError(err));
  }

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          checkUser();
        case "cognitoHostedUI":
          // getUser().then((userData) => console.log(userData));
          // checkUser();
          break;
        case "signOut":
          setUser({});
          break;
        case "signIn_failure":
          console.log("There is an error with the log in!");
          setUser({});
          navigate("/login");

        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          setUser({});
          navigate("/login");

          break;
      }
    });

    getUser()
      .then((userData) => {
        if (userData) {
          const userInfo = {
            ...userData.attributes,
            username: userData.username,
            "custom:department": "Back office",
          };
          console.log(userInfo);
          console.log("Logging in!");
          if (isLoggedIn()) navigate("/portal/dashboards");
        }
      })
      .catch((err) => {
        console.log("We have an error", err);
        navigate("/login");
      });
  }, []);

  async function login(e) {
    // login and also request auth token
    try {
      // uncomment for token request
      // const token = await API.get(apiName, path, myInit);
      // setAuthTokenTableau(token.authToken);
      e.preventDefault();
      await Auth.signIn(username, password);
      const user = await Auth.currentAuthenticatedUser();
      const userInfo = {
        ...user.attributes,
        username: user.username,
      };
      setUser(userInfo);
      if (isLoggedIn()) navigate("/portal/dashboards");
    } catch (err) {
      // if there's error show error component and also set authToken to null
      setError(err);
      window.sessionStorage.authToken = null;
    }
  }

  return (
    <div className="max-w-screen-xl-til mx-auto py-6 sm:px-6 lg:px-8 antialiased flex items-center justify-center">
      <div className="max-w-md w-full px-12 py-12 sm:px-0">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 600 600"
            className="mx-auto h-12 w-auto"
          >
            <path
              d="M341.64 158.34c0-85.87-69.62-155.47-155.48-155.47-85.85 0-155.46 69.6-155.46 155.47 0 85.88 69.61 155.48 155.46 155.48 85.87 0 155.48-69.61 155.48-155.48"
              fill="#3b97d3"
            />
            <path
              d="M324.85 516.13c0-44.14-35.78-79.89-79.89-79.89-44.14 0-79.91 35.75-79.91 79.89 0 44.11 35.76 79.89 79.91 79.89 44.11-.01 79.89-35.79 79.89-79.89"
              fill="#7ea9ba"
            />
            <path
              d="M170.13 415.86c0-41.21-33.42-74.64-74.63-74.64-41.21 0-74.61 33.42-74.61 74.64 0 41.21 33.4 74.62 74.61 74.62s74.63-33.41 74.63-74.62"
              fill="#f1893d"
            />
            <path
              d="M578.38 353.48c0-73.24-59.38-132.63-132.64-132.63-73.23 0-132.62 59.39-132.62 132.63 0 73.25 59.4 132.64 132.62 132.64 73.26 0 132.64-59.4 132.64-132.64"
              fill="#7383a9"
            />
            <circle cx="95.54" cy="564.89" r="28.17" fill="#010202" />
          </svg>
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <input
                aria-label="Email address"
                onChange={(e) => setuserName(e.target.value)}
                value={username}
                name="text"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Email address"
              />
            </div>
            <div className="-mt-px">
              <input
                id="password"
                name="password"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                // onKeyDown={(e) => (e.keyCode == 13 ? login() : null)}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Password"
              />
            </div>
          </div>

          {/* <div className="mt-6 flex items-center justify-end">
            <div className="text-sm leading-5">
              <a
                href="mailto:andre.devries@theinformationlab.co.uk?Subject=Embed%20Portal%20Access"
                target="_top"
                className="font-medium text-til-blue hover:text-til-purple focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Do you want an account?
              </a>
            </div>
          </div> */}

          <div className="mt-6">
            <button
              type="submit"
              onClick={login}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-til-blue hover:bg-til-purple focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              <span className="absolute left-0 inset-y pl-3">
                <svg
                  className="h-5 w-5 text-til-teal group-hover:text-til-teal transition ease-in-out duration-150"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
        {error && (
          <div className="mt-8">
            <Error error={error} toggleError={() => setError("")} />
          </div>
        )}
        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm leading-5">
            <span className="px-2 bg-white text-gray-500">
              Or sign in with Google
            </span>
          </div>
        </div>
        <div className="mt-6">
          <span className="w-full inline-flex">
            <button
              type="button"
              className="w-full inline-flex justify-center focus:outline-none"
              aria-label="Sign in with Google"
              onClick={() => Auth.federatedSignIn({ provider: "Google" })}
            >
              <img
                className="w-auto h-12"
                alt="Login with Google"
                src="https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/Misc/login-google.png"
              />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Login;
