export const videos = [
  {
    id: 0,
    title: "Course Introduction",
    url: "https://www.youtube.com/watch?v=PxyFrk4zrgw",
    length: "4 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/0_-_Course_Introduction.png",
    description:
      "In the first video I welcome you to the mini-course and show what the end goal is. I recorded this after I completed the recording of all the other videos and I share and show the Github page where you can find the finished code.",
  },
  {
    id: 1,
    title: "What is Embedded Analytics?",
    url: "https://www.youtube.com/watch?v=3DB9PSaumwo",
    length: "16 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/01_-_What_is_embedded_analytics.png",
    description:
      "This should be the video to watch if you want to see examples of Tableau embed projects. I also dive deeper into the reasons and the theory of Embedded Analytics.",
  },
  {
    id: 2,
    title: "Getting Setup",
    url: "https://www.youtube.com/watch?v=1ahKN3EGSgg",
    length: "7 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/02_-_Getting_Setup.png",
    description:
      "This is where we are installing the necessary tools to get up and running. It's a very minimal setup, we just need a text editor and a few extensions that allow us to setup our own server.",
  },
  {
    id: 3,
    title: "Basic Dashboard Embedding",
    url: "https://www.youtube.com/watch?v=WRs1kFPyk3M",
    length: "10 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/03_-_Basic_Embed.png",
    description:
      "There are two ways to embed Tableau dashboards in a webpage. In this video we are looking at the first and quickest way by using the embed code.",
  },
  {
    id: 4,
    title: "JavaScript API Intro",
    url: "https://www.youtube.com/watch?v=3Rzd5xTzaoM",
    length: "18 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/04_-_JavaScript_API_Intro.png",
    description:
      "All Tableau Embed projects use the Tableau JavaScript API. In this video we are taking a look at the documentation, how to enable the API in your webpage and how to display a dashboard on your page.",
  },
  {
    id: 5,
    title: "Setting Viz Options",
    url: "https://www.youtube.com/watch?v=YPEKISthQV0",
    length: "10 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/05_-_JavaScript_API_Setting_Options.png",
    description:
      "Once you have a Tableau Dashboard embedded in your webpage you can customise how this is being displayed. You can set the height, width and several other options of the dashboard. This is a way to make sure the dashboard aligns with the web application style.",
  },
  {
    id: 6,
    title: "Adding Page Interactions",
    url: "https://www.youtube.com/watch?v=fPOcLRuCHY0",
    length: "9 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/06_-_JavaScript_API_page_interactions.png",
    description:
      "The real power of embedded analytics with Tableau is the way you can interact with the dashboard through the webpage. In this video we are creating buttons to show and hide the dashboard.",
  },
  {
    id: 7,
    title: "PDF & Image Export Options",
    url: "https://www.youtube.com/watch?v=AZwFn0b63FQ",
    length: "6 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825482/Embedding%20Tableau%20Video%20Series/07_-_JavaScript_API_export_options.png",
    description:
      "Exporting PDFs and Images of your dashboard is a native functionality in Tableau Server. You can also port this to your embedded project by using the JavaScript API.",
  },
  {
    id: 8,
    title: "Apply Filtering",
    url: "https://www.youtube.com/watch?v=wqm_upH4Sz0",
    length: "19 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/08_-_JavaScript_API_FILTERING.png",
    description:
      "The next step in creating a fully integrated experience for your customers is to create custom filtering experiences. In this example the user can interact with the webpage and apply filtering to the embedded dashboard.",
  },
  {
    id: 9,
    title: "Authentication & Wrap Up",
    url: "https://www.youtube.com/watch?v=aiYb9HEL1dU",
    length: "9 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1585825481/Embedding%20Tableau%20Video%20Series/09_-_JavaScript_wrap_up.png",
    description:
      "In the last video I wrap up the course and give a high level overview of the types of authentication methods you have available when you are embedding Tableau.",
  },
  {
    id: 10,
    title: "React & Tableau JS API",
    url: "https://www.youtube.com/watch?v=hc4UCBTACTU",
    length: "28 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1589989554/Embedding%20Tableau%20Video%20Series/ReactTableau.png",
    description:
      "This video was recorded after I recorded the previous videos. This video is a little bit more advanced as it shows you how to use the Tableau JavaScript API with React.",
  },
  {
    id: 11,
    title: "Auto Refresh Embedded Tableau Dashboards",
    url: "https://www.youtube.com/watch?v=aLNwkdSO2Kw",
    length: "12 mins",
    image:
      "https://res.cloudinary.com/dmim37dbf/image/upload/v1598437041/Embedding%20Tableau%20Video%20Series/refreshDataAsync.png",
    description:
      "In this video I describe how to create a webpage in which you can embed a Tableau dashboard that auto-refreshes on a time.",
  },
];
