import React from "react";
import LayoutPortal from "./LayoutPortal";
import TabLayout from "./TabLayout";

function UserSettings() {
  return (
    <LayoutPortal>
      <TabLayout />
      <div className="px-4 py-4 sm:px-6">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <p>User settings</p>
        </div>
      </div>
    </LayoutPortal>
  );
}

export default UserSettings;
