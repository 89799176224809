import React, { useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { GlobalContext } from "../context/GlobalState";

function LanguageSelect(props) {
  const { language, Dictionary } = useContext(GlobalContext);
  return (
    <div className="relative outline-none">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              type="button"
              className="text-gray-500 group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              <span>{Dictionary.menu[language].language}</span>
              <svg
                className="h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Menu.Button>
            <Transition
              show={open}
              enter={"transition ease-out duration-100"}
              enterFrom={"opacity-0 translate-y-1"}
              enterTo={"opacity-100 translate-y-0"}
              leave={"transition ease-in duration-75"}
              leaveFrom={"opacity-100 translate-y-0"}
              leaveTo={"opacity-0 translate-y-1"}
            >
              <Menu.Items
                static
                className="origin-top-right right-0  absolute mt-3 px-2 max-w-xs sm:px-0 outline-none"
              >
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg shadow-xs overflow-hidden">
                    <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => props.selectLanguage("en")}
                            className="cursor-pointer"
                          >
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-gray-900">
                                English
                              </p>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => props.selectLanguage("fr")}
                            className="cursor-pointer"
                          >
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-gray-900">
                                French
                              </p>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            // to-do update to ES
                            onClick={() => props.selectLanguage("es")}
                            className="cursor-pointer"
                          >
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-gray-900">
                                Spanish
                              </p>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            // to-do update to IT
                            onClick={() => props.selectLanguage("it")}
                            className="cursor-pointer"
                          >
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-gray-900">
                                Italian
                              </p>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            // to-do update to IT
                            onClick={() => props.selectLanguage("de")}
                            className="cursor-pointer"
                          >
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-medium text-gray-900">
                                German
                              </p>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default LanguageSelect;
