import React from "react";

const projects = [
  {
    name: "Health Sciences",
    url: "https://embedding-til-images.s3.eu-west-2.amazonaws.com/health.jpg",
  },
  {
    name: "Insurance",
    url: "https://embedding-til-images.s3.eu-west-2.amazonaws.com/insurance.jpg",
  },
  {
    name: "Legal",
    url: "https://embedding-til-images.s3.eu-west-2.amazonaws.com/legal.jpg",
  },
  {
    name: "Finance",
    url: "https://embedding-til-images.s3.eu-west-2.amazonaws.com/finance.jpg",
  },
  {
    name: "Consulting",
    url: "https://embedding-til-images.s3.eu-west-2.amazonaws.com/consulting.jpg",
  },
];

function ProfileImage({ projectName }) {
  // filter to the projectName that was assigned to the dashboard
  const currentProject = projects.filter(
    (project) => project.name === projectName
  );
  const { name, url } = currentProject[0];
  return <img className="h-12 w-12 rounded-full" src={url} alt={name} />;
}

export default ProfileImage;
