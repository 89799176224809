import React, { useEffect } from "react";
import LayoutPortal from "../components/Portal/LayoutPortal";
import DesktopEmbed from "../components/Tableau/DesktopEmbed";
import { GlobalContext } from "../context/GlobalState";
import { getTrustedTicket } from "../utils/trustedToken";

function AskData() {
  const { userAttributes } = React.useContext(GlobalContext);
  const [viz, setViz] = React.useState();
  const [error, setError] = React.useState();
  const [vizUrl, setVizUrl] = React.useState(null);

  async function getToken() {
    try {
      const centralTILMail = "andre.devries@theinformationlab.co.uk";
      const person =
        userAttributes.email === "tim.ngwena@theinformationlab.co.uk"
          ? "Tim Ngwena"
          : centralTILMail;
      console.log("These are the user attributes", userAttributes.email);
      const site = "WebEditEmbed";
      const token = await getTrustedTicket(person, site);
      const parsedToken = JSON.parse(token);
      console.log(parsedToken);
      const vizUrl =
        parsedToken.result === "Success"
          ? `https://clientreporting.theinformationlab.co.uk/trusted/${parsedToken.ticket}/t/WebEditEmbed/askData/WorldIndicators`
          : null;
      setVizUrl(vizUrl);
    } catch (err) {
      setError("We got an error loading the viz");
      console.log("We got an error", err);
    }
  }

  useEffect(() => {
    getToken();
  }, []);
  return (
    <LayoutPortal>
      {vizUrl && <DesktopEmbed url={vizUrl} viz={viz} setViz={setViz} />}
      {error && <p className="font-bold text-red-700 text-3xl">{error}</p>}
    </LayoutPortal>
  );
}

export default AskData;
