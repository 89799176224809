import React, { useContext, useState } from "react";
import Layout from "../components/Layout";
import VideoCard from "../components/VideoCard";
import { usePageTitle } from "../utils/usePageTitle";
import { GlobalContext } from "../context/GlobalState";

// load the videos
import { videos } from "../utils/videoLinks";
import VideoModal from "../components/Videos/VideoModal";

function Videos({ title }) {
  usePageTitle(title);
  const { language, Dictionary } = useContext(GlobalContext);
  const [isOpen, setIsopen] = useState(false);
  const [videoID, setvideoID] = useState(null);
  return (
    <Layout>
      <div className="max-w-screen-xl-til mx-auto py-6 sm:px-6 lg:px-8 antialiased">
        <div className="px-4 py-4 sm:px-0">
          <div className="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
              <div className="bg-white h-1/3 sm:h-2/3">
                <div className="rounded-lg h-auto">
                  <div className="relative bg-white">
                    <div className="relative max-w-screen-xl-til mx-auto px-4 py-8 sm:py-8 sm:px-6 lg:py-8">
                      <div>
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-til-blue text-white">
                          <svg
                            className="h-6 w-6"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"></path>
                          </svg>
                        </div>
                      </div>
                      <h3 className="mt-4 text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                        {Dictionary.learn[language].title}
                      </h3>
                      <p className="mt-4 text-base leading-6 text-gray-500">
                        {Dictionary.learn[language].desc}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-screen-xl-til">
            <div className="grid gap-5 max-w-lg mx-auto lg:mt-8 sm:mt-24 md:mt-24 sm:grid-cols-2 sm:max-w-none md:grid-cols-3 md:max-w-none lg:grid-cols-3 lg:max-w-none">
              {videos.map((video) => {
                return (
                  <VideoCard
                    video={video}
                    key={video.id}
                    isOpen={isOpen}
                    setIsopen={setIsopen}
                    setvideoID={setvideoID}
                    videoID={videoID}
                  />
                );
              })}
            </div>
            <VideoModal
              isOpen={isOpen}
              setIsopen={setIsopen}
              videoID={videoID}
              setvideoID={setvideoID}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Videos;
