import React, { useContext } from "react";

// bring in Framer motion for animation
import { motion, AnimatePresence } from "framer-motion";

import Header from "./Header";
import Footer from "./Footer";

// import global context
import { GlobalContext } from "../context/GlobalState";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function Layout({ children }) {
  // dark mode context
  const { mode } = useContext(GlobalContext);
  return (
    <AnimatePresence exitBeforeEnter>
      <div
        className={`flex flex-col min-h-screen ${
          mode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <Header />
        <motion.div
          initial="hidden"
          animate="visible"
          transition={{ duration: 1 }}
          variants={variants}
          className="flex-grow"
        >
          {children}
        </motion.div>
        <Footer />
      </div>
    </AnimatePresence>
  );
}

export default Layout;
