import React from "react";

// import Layout
import LayoutPortal from "../components/Portal/LayoutPortal";
// eslint-disable-next-line
import UserDashboards from "../components/Portal/UserDashboards";
// eslint-disable-next-line
import UserProjects from "../components/Portal/UserProjects";
import { isLoggedIn } from "../utils/auth";
import Portal from "./Portal";
// eslint-disable-next-line
import TabLayout from "../components/Portal/TabLayout";

function IndexPortal() {
  if (isLoggedIn()) {
    console.log("[Logging in..]");
    return (
      <LayoutPortal>
        {/* <UserProjects /> */}
        {/* <UserDashboards /> */}
        {/* <TabLayout /> */}
      </LayoutPortal>
    );
  } else {
    return <Portal />;
  }
}

export default IndexPortal;
