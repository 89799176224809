import React from "react";

// import Layout
import Layout from "../components/Layout";
// import main landing page content
import Landing from "../components/Landing";
// import feature page content
import Feature from "../components/Feature";
// import section page content
import Section from "../components/Section";
import { usePageTitle } from "../utils/usePageTitle";

function Main(props) {
  // setting the document title
  usePageTitle(props.title);
  return (
    <Layout>
      <Landing />
      <Feature />
      <Section />
    </Layout>
  );
}

export default Main;
