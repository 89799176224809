import React, { useEffect, useRef } from "react";
const { tableau } = window;

function WebEditEmbed({ url, viz, setViz, number }) {
  const ref = useRef(null);

  const initViz = () => {
    let options = {
      hideTabs: true,
      hideToolbar: true,
      device: "desktop",
      width: ref.offsetWidth,
      height: ref.offsetHeight,
      onFirstInteractive: () => console.log("Web edit Viz loaded"),
    };
    if (viz) {
      viz.dispose();
      setViz(new tableau.Viz(ref.current, url, options));
    } else {
      setViz(new tableau.Viz(ref.current, url, options));
    }
  };

  useEffect(() => {
    initViz();
    // return () => {
    //   console.log("Disposing viz!");
    //   if (viz) {
    //     viz.dispose();
    //   }
    // };
  }, [url, number]);

  return <div ref={ref} />;
}

export default WebEditEmbed;
