import React, { useContext } from "react";
import { Link } from "@reach/router";
import { GlobalContext } from "../../context/GlobalState";

function DashboardItem({ industryContent }) {
  const { language } = useContext(GlobalContext);
  const industryName = industryContent.name[language];
  return (
    <>
      {industryContent.dashboards.map(
        ({
          author,
          id,
          url,
          publicProfile,
          twitterProfile,
          image,
          dashboardName,
          dashboardDescription,
        }) => {
          const name = dashboardName[language]
            ? dashboardName[language]
            : dashboardName["en"];
          const link = dashboardName["en"].replace(/ /g, "").toLowerCase();
          return (
            <li key={id}>
              <Link to={link}>
                <div className="space-y-4 pb-4">
                  <div className="aspect-w-3 aspect-h-2 border rounded border-gray-300 overflow-hidden transition transform duration-150 ease-in-out">
                    <img
                      className="object-cover  h-full w-full shadow-lg rounded-lg hover:opacity-30 hover:object-full transition ease-in-out duration-150"
                      src={image}
                      alt={name}
                    />
                  </div>
                </div>
              </Link>
              <div className="text-lg leading-6 font-medium space-y-1 pb-2">
                <h4 className="pb-1 text-medium text-gray-600">{author}</h4>
                <Link to={link}>
                  <p className="text-til-blue hover:text-til-purple">{name}</p>
                </Link>
              </div>
              <div className="text-lg leading-7 pb-4">
                <p className="text-gray-500">
                  {dashboardDescription[language]
                    ? dashboardDescription[language]
                    : dashboardDescription["en"]}
                </p>
              </div>

              <ul className="flex space-x-5">
                <li>
                  <a
                    href={twitterProfile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                  >
                    <span className="sr-only">Twitter</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href={publicProfile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                  >
                    <span className="sr-only">Tableau Public</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 60.3 59.5"
                    >
                      <path d="M28.5 40.2h3.3v-9h8.3V28h-8.3v-9h-3.3v9h-8.2v3.2h8.2z" />
                      <path d="M13.2 53.2H16v-8h7.4v-2.5H16v-8.1h-2.8v8.1H5.8v2.5h7.4z" />
                      <path d="M44.3 24.3h2.8v-8h7.5v-2.4h-7.5V5.8h-2.8v8.1h-7.4v2.4h7.4z" />
                      <path d="M29 59.5h2.4v-5.7h5.1v-2.1h-5.1V46H29v5.7h-5v2.1h5z" />
                      <path d="M13.3 24.3h2.6v-8.1h7.5v-2.3h-7.5V5.8h-2.6v8.1H5.8v2.3h7.5z" />
                      <path d="M52.8 36.3h2.4v-5.6h5.1v-2.2h-5.1v-5.6h-2.4v5.6h-5v2.2h5z" />
                      <path
                        clipRule="evenodd"
                        d="M44.3 53.2h2.8v-8h7.5v-2.5h-7.5v-8.1h-2.8v8.1h-7.4v2.5h7.4z"
                        fillRule="evenodd"
                      />
                      <path
                        d="M36.1 7.2V5.5h-5V0h-1.8v5.5h-5v1.7h5v5.5h1.8V7.2zM5 35.9h1.8v-5.5h5v-1.7h-5v-5.4H5v5.4H0v1.8l5-.1z"
                        fill="#7199a6"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </li>
          );
        }
      )}
    </>
  );
}

export default DashboardItem;
