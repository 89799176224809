export const industries = [
  {
    id: 0,
    name: {
      en: "Insurance",
      fr: "Assurance",
      es: "Seguros",
      it: "Assicurazioni",
      de: "Versicherung",
    },
    shortDescription: {
      en: "Get a better understanding of how Tableau can be used in a risk management setting.",
      fr: "Comprenez comment Tableau peut être utilisé dans un cadre de management des risques.",
      es: "Obtén una mejor comprensión de cómo se puede utilizar Tableau en un entorno de gestión de riesgos.",
      it: "Comprendi meglio come Tableau può essere utilizzato in un contesto di gestione del rischio.",
      de: "Finden Sie mehr darüber heraus, wie Sie Tableau im Risikomanagement einsetzen können.",
    },
    longDescription: {
      en: "Tableau makes visual insurance analytics readily available. It enables users to quickly communicate and understand actionable insight from intricate data sources, irrespective of their technical expertise.",
      fr: "Tableau rend l'analyse visuelle de l'assurance facilement accessible. Il permet aux utilisateurs de communiquer et de comprendre rapidement des informations exploitables à partir de sources de données complexes, indépendamment de leurs compétences techniques.",
      es: "Tableau facilita la comprensión de la información en industrias complejas como es la de los seguros. Facilita a los usuarios descubrir, comunicar y comprender insights the múltiples fuentes de datos.",
      it: "Tableau rende l'analisi visiva delle assicurazioni facilmente disponibile. Consente agli utenti di comunicare rapidamente e comprendere approfondimenti utilizzabili da fonti di dati intricate, indipendentemente dalle loro competenze tecniche.",
      de: "Visuelle Versicherungsanalysen sind mit Tableau leicht verständlich. Es ermöglicht Nutzer:innen, unabhängig von ihren technischen Kenntnissen, schnell aussagekräftige Informationen aus komplexen Datenquellen zu kommunizieren und zu verstehen.",
    },
    slug: "/industry/insurance",
    dashboards: [
      {
        id: 0,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/DemoInsuranceDashboards-BrokerPortfolio/InsuranceDashboard-PortfolioView",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Broker Portfolio",
          fr: "Portfolio Courtier",
          es: "Portfolio Broker",
          it: "Portafoglio dei broker",
          de: "Markler Portfolio",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Insurance_Dashboard_-_Portfolio_View.png",
        dashboardDescription: {
          en: "Created with underwriters in mind, these dashboards allow users to see who the biggest players are, which brokers incur the biggest costs and greatest returns, and how brokers compare when internally benchmarked across a series of key KPIs.",
          fr: "Créés à l'intention des assureurs, ces tableaux de bord permettent aux utilisateurs de voir quels sont les principaux acteurs, quels courtiers supportent les coûts les plus importants et obtiennent les meilleurs rendements, et comment les courtiers sont comparés lorsqu'ils sont évalués en interne sur une série d'indicateurs de performances.",
          es: "Estos dashboards permiten al usuario analizar quienes son los brokers más destacados, el coste y el retorno de los mismos y la comparación entre los mismos en base a una serie de KPIs.",
          it: "Creati con i sottoscrittori in mente, questi cruscotti permettono agli utenti di vedere chi sono i più grandi operatori, quali broker sostengono i maggiori costi e i maggiori rendimenti, e come i broker si confrontano quando vengono valutati internamente attraverso una serie di KPI chiave.",
          de: "Diese Dashboards wurden speziell für Versicherer entwickelt und ermöglichen es den Nutzern, zu sehen, wer die größten Akteure sind, welche Makler die höchsten Kosten und die größten Erträge verursachen und wie die Makler im internen Benchmarking bei einer Reihe von Schlüsselkennzahlen abschneiden.",
        },
      },
      {
        id: 1,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/WhitespaceHealth/Whitespace",
        author: "Natalia Miteva",
        dashboardName: {
          en: "Whitespace & Health",
          fr: "Espace blanc et santé",
          es: "Análisis de oportunidades",
          it: "Analisi dei gap e salute",
          de: "Whitespace & Health",
        },
        publicProfile: "https://public.tableau.com/profile/natalia.miteva#!/",
        twitterProfile: "https://twitter.com/nataliatamiteva",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Whitespace.png",
        dashboardDescription: {
          en: "The first tab of this dashboard provides an example of whitespace analysis. It uncovers gaps and sales opportunities across clients and products. The second tab explores client health with performance parameters allowing the user to define priorities.",
          es: "El primer análisis es un buen ejemplo de análisis de oportunidades en clientes y productos actuales. El segundo explora la salud de clientes permitiendo al usuario determinar prioridades.",
          it: "La prima scheda di questa dashboard fornisce un esempio di analisi dei gap. Scopre le lacune e le opportunità di vendita tra i clienti e i prodotti. La seconda scheda esplora la salute del cliente con parametri di performance che permettono all'utente di definire le priorità.",
          de: "Die erste Übersicht dieses Dashboards ist ein Beispiel für eine Leerraumanalyse. Sie deckt Lücken und Verkaufschancen über Kunden und Produkte hinweg auf. Die zweite Übersicht zeigt den Gesundheitszustand der Kunden mit Leistungsparametern, die es dem Benutzer ermöglichen, Prioritäten zu setzen",
        },
      },
      {
        id: 2,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Public-Insurance-Operational/OperationalDashboard",
        author: "Ruth Amarteifio",
        dashboardName: {
          en: "Operational Reporting",
          fr: "Reporting opérationnel",
          es: "Informe de operaciones",
          it: "Rapporti operativi",
          de: "Operative Berichterstattung",
        },
        publicProfile: "https://www.thedataschool.co.uk/blog/ruth-amarteifio/",
        twitterProfile: "https://twitter.com/rrrruuuuth",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Operational_Dashboard.png",
        dashboardDescription: {
          en: "This dashboard functions as an example of how you could present operational reports. Click on the various visualisations and use the filters to drill to insights.",
          fr: "Ce tableau de bord fonctionne comme un exemple de la manière dont vous pourriez présenter des rapports opérationnels. Cliquez sur les différentes visualisations et utilisez les filtres pour accéder aux aperçus.",
          es: "Este dashboard es un buen ejemplo de cómo presentar información en áreas de operaciones. Haz click en las visualizaciones y usa los filtros para investigar los datos.",
          it: "Questo cruscotto rappresenta un esempio di come si potrebbero presentare i rapporti operativi. Clicca sulle varie visualizzazioni e usa i filtri per approfondire gli aspetti più importanti.",
          de: "Dieses Dashboard ist ein Beispiel dafür, wie Sie betriebliche Berichte darstellen können. Klicken Sie auf die verschiedenen Visualisierungen und verwenden Sie die Filter, um genauere Einblicke zu erhalten.",
        },
      },
      {
        id: 3,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/FinancialOverview/VolumeFunnel",
        author: "Jose Valero",
        dashboardName: {
          en: "Financial Overview",
          es: "Resumen Financiero",
          it: "Panoramica finanziaria",
          de: "Finanzielle Übersicht",
        },
        publicProfile: "https://public.tableau.com/profile/jose4803#!/",
        twitterProfile: "https://twitter.com/joseva451",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Volume_Funnel.png",
        dashboardDescription: {
          en: "These dashboards are helpful for finance departments to understand the performance of policies versus previous years, looking at price and volume metrics, as well as tracking the different stages since the policy was quoted.",
          es: "Dashboards útiles para comprender el rendimiento a lo largo de los años por precios, volumen y la situación en diferentes etapas.",
          it: "Questi cruscotti sono utili per i dipartimenti finanziari per capire le prestazioni delle polizze rispetto agli anni precedenti, guardando le metriche di prezzo e volume, così come il monitoraggio delle diverse fasi da quando la polizza è stata quotata",
          de: "Diese Dashboards sind für die Finanzabteilungen hilfreich, um die Leistung der Policen im Vergleich zu den Vorjahren zu verstehen, Preis- und Volumenkennzahlen zu betrachten und die verschiedenen Stadien seit dem Abschluss der Police zu verfolgen.",
        },
      },
      {
        id: 4,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demoinsurancedashboards-Renewalprogressanalysis/InsuranceDashboard-Renewalprogressanalysis",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Renewal Progress Analysis",
          es: "Análisis de renovaciones",
          it: "Analisi dello stato di avanzamento dei rinnovi",
          de: "Analyse des Erneuerungsprozesses",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Insurance_Dashboard_-_Renewal_progress_analysis.png",
        dashboardDescription: {
          en: "This dashboard aims to provide visibility on the current renewal pipeline. Through visualising the status of deals eligible for renewal, users are able to ascertain the progress and completion of their portfolio at a high level.",
          es: "El dashboard tiene como objetivo proporcionar una mayor visibilidad sobre la situación actual de procesos de renovación. Permitiendo al usuario analizar no sólo la situación actual sino planificar el progreso y la el rendimiento a nivel general.",
          it: "Questa dashboard mira a fornire visibilità sulla corrente pipeline di rinnovo. Attraverso la visualizzazione dello stato dei contratti idonei al rinnovo, gli utenti sono in grado di accertare il progresso e il completamento del loro portafoglio ad un livello alto.",
          de: "Dieses Dashboard soll einen Überblick über die aktuelle Verlängerungspipeline geben. Durch die Visualisierung des Status der für eine Erneuerung in Frage kommenden Geschäfte können die Nutzer den Fortschritt und den Abschluss ihres Portfolios auf hohem Niveau feststellen.",
        },
      },
      {
        id: 5,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/PolicyAnalysis/1_PolicybyAM",
        author: "Diego Parker",
        dashboardName: {
          en: "Policy Analysis",
          es: "Análisis de pólizas de seguros",
          it: "Analisi delle policy",
          de: "Analyse von Versicherungspolicen",
        },
        publicProfile: "https://public.tableau.com/profile/diego.parker#!/",
        twitterProfile: "https://twitter.com/DiegoTParker",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/1._Policy_by_AM.png",
        dashboardDescription: {
          en: "This insurance dashboard is a reporting tool for both managers and brokers. It provides an overview of key metrics across different dimensions through three different views: Account Manager, Product and Location.",
          es: "Informes de situación para managers  brokers. Proporciona una visión general de las métricas clave en diferentes vistas y niveles: Manager, Producto y Localización",
          it: "Questo cruscotto assicurativo è uno strumento di reporting sia per i manager che per i broker. Fornisce una panoramica delle metriche chiave attraverso diverse dimensioni tramite tre viste diverse: Account Manager, Prodotto e Posizione.",
          de: "Dieses Versicherungs-Dashboard ist ein Reporting-Tool sowohl für Manager als auch für Makler. Es bietet einen Überblick über die wichtigsten Kennzahlen in verschiedenen Dimensionen durch drei verschiedene Ansichten: Account Manager, Produkt und Standort.",
        },
      },
      {
        id: 6,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/InsurancePriceAnalysis/InsurancePriceOverview",
        author: "Molly Hatch",
        dashboardName: {
          en: "Insurance Price Analysis",
          es: "Análisis de precios de seguros",
          it: "Analisi dei prezzi delle assicurazioni",
          de: "Analyse der Versicherungspreise",
        },
        publicProfile: "https://public.tableau.com/profile/molly.hatch#!",
        twitterProfile: "https://twitter.com/MollyHatch8",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Insurance_Price_Overview_1.png",
        dashboardDescription: {
          en: "These dashboards aim to provide insight into which policies are profitable and which customers are making claims by looking at key ratios and metrics and splitting up policies by type, channel, customer age, customer location.",
          es: "Muestra información sobre qué pólizas son más rentables y qué clientes están realizando más reclamaciones, observando además índices y métricas clave por tipo de póliza, canal, edad del cliente, etc.",
          it: "Questi cruscotti mirano a fornire una visione di quali polizze sono redditizie e quali clienti stanno facendo reclami, guardando i rapporti e le metriche chiave e suddividendo le polizze per tipo, canale, età del cliente, ubicazione del cliente.",
          de: "Diese Dashboards sollen Aufschluss darüber geben, welche Policen rentabel sind und welche Kunden Ansprüche geltend machen, indem sie die wichtigsten Kennzahlen und Kennziffern betrachten und die Policen nach Art, Kanal, Kundenalter und Kundenstandort aufschlüsseln.",
        },
      },
      {
        id: 7,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/SalesAgentPolicies/AgentPolicyOverview/owen.barnes@theinformationlab.co.uk/3f65472b-6ae1-4fdc-9971-deb8902832b9",
        author: "Owen Barnes",
        hideTabs: true,
        dashboardName: {
          en: "Agent & Policy Analysis",
          es: "Agentes y rendimiento",
          it: "Analisi degli agenti e delle policy",
          de: "Markler & Policen Analyse",
        },
        publicProfile: "https://public.tableau.com/profile/owen.barnes#!/",
        twitterProfile: "https://twitter.com/OwenBData",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Agent_Policy_Overview.png",
        dashboardDescription: {
          en: "Explore the performance of sales agents and departments with drill-down abilities. Ideal for managers to investigate the performance of those reporting to them. KPIs explore most profitable clients, top performing agents, and regions with highest income",
          es: "Explora el rendimiento de agentes y vendedores de distintos departamentos. Ideal para managers que quieran investigar el rendimiento de sus equipos. Los KPIs muestran los clientes de mayor rentabilidad, agentes con mejor rendimiento o información sobre distintas regiones.",
          it: "Esplora le prestazioni degli agenti di vendita e dei reparti con possibilità di drill-down. Ideale per i manager per indagare le prestazioni di coloro che riportano a loro. I KPI esplorano i clienti più redditizi, gli agenti più performanti e le regioni con il reddito più alto.",
          de: "Ermitteln Sie die Leistung von Vertriebsmitarbeitern und Abteilungen mit Detailfunktionen. Ideal für Manager, um die Leistung der ihnen unterstellten Mitarbeiter zu überprüfen. KPIs zeigen die profitabelsten Kunden, die leistungsstärksten Vertreter und die Regionen mit den höchsten Einnahmen",
        },
      },
      {
        id: 8,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/InsuranceSalesKPIOverview/KPI",
        author: "Owen Barnes",
        hideTabs: true,
        dashboardName: {
          en: "KPI Overview",
          es: "Resumen de KPIs",
          it: "Panoramica dei KPI",
          de: "KPI Übersicht",
        },
        publicProfile: "https://public.tableau.com/profile/owen.barnes#!/",
        twitterProfile: "https://twitter.com/OwenBData",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/KPI.png",
        dashboardDescription: {
          en: "Intended for those looking to track the performance of sales, profit, and incurred costs of an insurance company. Light filtering options available to track performance on a month-on-month basis.",
          es: "Destinado a quienes buscarn realizar un seguimiento del desempeño de ventas, ganancias y costes de una empresa de seguros. Incluye sencillos filtros para realizar comparaciones mensuales.",
          it: "Destinata a coloro che cercano di tracciare le prestazioni di vendite, profitti e costi sostenuti da una compagnia di assicurazioni. Sono disponibili opzioni di filtraggio per tracciare le prestazioni su base mensile.",
          de: "Für diejenigen, die die Leistung von Umsatz, Gewinn und anfallenden Kosten eines Versicherungsunternehmens verfolgen möchten. Leichte Filteroptionen verfügbar, um die Leistung auf monatlicher Basis zu erfassen.",
        },
      },
      {
        id: 9,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/UnderwriterPerformance/InsuranceDashboard-UnderwriterPerformance",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Underwriter Performance",
          fr: "Performance du souscripteur",
          es: "Análisis de rendimiento",
          it: "Performance del sottoscrittore",
          de: "Leistung der Versicherer",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Insurance_Dashboard_-_Underwriter_Performance.png",
        dashboardDescription: {
          en: "Intended to provide insight into underwriter performance, this dashboard allows users to compare and benchmark underwriters across a series of customisable key metrics.",
          fr: "Destiné à fournir un aperçu des performances des souscripteurs, ce tableau de bord permet aux utilisateurs de comparer et de comparer les souscripteurs à travers une série de paramètres clés personnalisables.",
          es: "Este dashboard permite al usuario comparar y evaluar el rendimiento a través de una serie de métricas esenciales de análisis.",
          it: "Pensato per fornire informazioni sulle prestazioni degli assicuratori, questo cruscotto consente agli utenti di confrontare e valutare gli assicuratori attraverso una serie di parametri chiave personalizzabili",
          de: "Dieses Dashboard soll einen Einblick in die Leistung von Versicherungen geben und ermöglicht den Nutzern den Vergleich und das Benchmarking von Versicherungen anhand einer Reihe von anpassbaren Kennzahlen",
        },
      },
    ],
  },
  {
    id: 1,
    name: {
      en: "Higher Education",
      fr: "Education supérieure",
      es: "Educación Superior",
      it: "Educazione superiore",
      de: "Höhere Bildung",
    },
    shortDescription: {
      en: "Many universities and education institutions are leveraging Tableau for analytics purposes.",
      fr: "Beaucoup d'universités et d'organismes éducatifs utilisent Tableau à des fins analytiques.",
      es: "Muchas universidades y instituciones educativas están usando Tableau para sus necesidades de analítica.",
      it: "Molte università e istituzioni educative stanno sfruttando Tableau per scopi analitici.",
      de: "Viele Universitäten und Bildungseinrichtungen nutzen Tableau für analytische Zwecke.",
    },
    longDescription: {
      en: "Tableau is changing the way universities understand data in many areas. We have worked across all departments understanding student numbers, conducting forecasting and scenario modelling and analysing league tables.",
      fr: "Tableau transforme la perception des données dans de nombreux domaines. Nous avons collaboré avec tous les départements pour connaître le nombre d'étudiants, établir des prévisions, modéliser des scénarios et analyser des classements.",
      es: "Tableau está cambiando la forma en la que las Universidades sacan partido a sus datos. Hemos trabajado con múltiples departamentos para conocer mejor el volumen de estudiantes, predicciones y modelización de escensarios y análisis de rankings.",
      it: "Tableau sta cambiando il modo in cui le università comprendono i dati in molte aree. Abbiamo lavorato in tutti i dipartimenti per capire i numeri degli studenti, condurre previsioni e modelli di scenario e analizzare le classifiche.",
      de: "Tableau verändert die Datenerfassung an Universitäten in vielen Gebieten. Wir haben über alle Fachbereiche hinweg daran gearbeitet, Studentenzahlen zu verstehen, Prognosen und Szenariomodelle zu erstellen und Ranglisten zu analysieren.",
    },
    slug: "/industry/highereducation",
    dashboards: [
      {
        id: 0,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Studentnumbersforecasting/Studentnumbersforecasting/andre.devries@theinformationlab.co.uk/a0f3f8fa-59f4-4487-aca1-d398fb842977",
        author: "The Information Lab",
        dashboardName: {
          en: "Student Numbers Forecasting",
          fr: "Prévision du nombre d'élèves",
          es: "Predicción del número de estudiantes",
          it: "Previsione dei numeri degli studenti",
          de: "Vorausberechnung der Studentenzahlen",
        },
        publicProfile:
          "https://public.tableau.com/profile/theinformationlab#!/",
        twitterProfile: "https://twitter.com/infolabUK",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Student_numbers_forecasting.png",
        dashboardDescription: {
          en: "Visualising the results of forecasting models is super intuitive with Tableau. Use this dashboard and tweak the parameters to create your own forecasting models",
          fr: "La visualisation des résultats des modèles de prévision est très intuitive avec Tableau. Utilisez ce tableau de bord et modifiez les paramètres pour créer vos propres modèles de prévision.",
          es: "Visualización de los resultados de modelos predictivos. Modifica los parámetros del dashboard para crear tu propio modelo predictivo.",
          it: "La visualizzazione dei risultati dei modelli di previsione è super intuitiva con Tableau. Usa questa dashboard e modifica i parametri per creare i tuoi modelli di previsione",
          de: "Die Visualisierung der Ergebnisse von Prognosemodellen ist mit Tableau sehr intuitiv. Verwenden Sie dieses Dashboard und passen Sie die Parameter an, um Ihre eigenen Prognosemodelle zu erstellen",
        },
      },
      {
        id: 1,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/ProfitLossUniversity/LandingPage",
        author: "Nathan Leather",
        dashboardName: {
          en: "Profit & Loss by University",
          fr: "Bénéfices et pertes par université",
          es: "Pérdidas y beneficio por Universidad",
          it: "Profitti e perdite per Università",
          de: "Gewinn und Verlust nach Universität",
        },
        publicProfile: "https://public.tableau.com/profile/nathan.leather#!/",
        twitterProfile: "https://twitter.com/LeatherNathan",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Income_Expenditure_Charts.png",
        dashboardDescription: {
          en: "These dashboards show the Profit & Loss statements for various cost centres within a University. Use the landing page to navigate between the dashboards. For many dashboards you have both visuals in the form of charts and a table of data.",
          fr: "La visualisation des résultats des modèles de prévision est très intuitive avec Tableau. Utilisez ce tableau de bord et modifiez les paramètres pour créer vos propres modèles de prévision.",
          es: "Estos dashboards muestran las pérdidas y beneficio de varios centros dentro de una Universidad. Puedes usar la página de inicio para navegar por los distintos dashboards, muchos de los cuales incluyen datos de forma visual o en tablas.",
          it: "Questi cruscotti mostrano i conti economici per vari centri di costo all'interno di un'università. Usa la landing page per navigare tra le dashboard. Per molte dashboard hai sia immagini sotto forma di grafici che una tabella di dati.",
          de: "Diese Dashboards zeigen die Gewinn- und Verlustrechnungen für verschiedene Kostenstellen innerhalb einer Universität. Verwenden Sie die Landing Page, um zwischen den Dashboards zu navigieren. Für viele Dashboards stehen Ihnen sowohl visuelle Darstellungen in Form von Diagrammen als auch eine Tabelle mit Daten zur Verfügung",
        },
      },
      {
        id: 2,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/UniversityTransparencyReturn/TransparencyReportAOAR",
        author: "Tom Prowse",
        dashboardName: {
          en: "University Transparency Return",
          fr: "Retour de transparence universitaire",
          it: "University Transparency Return",
          es: "Transparencia en la Universidad",
          de: "Rückkehr der Universität zur Transparenz",
        },
        publicProfile: "https://public.tableau.com/profile/tom.prowse#!/",
        twitterProfile: "https://twitter.com/TomProwse1",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Transparency_Report_AOAR.png",
        dashboardDescription: {
          en: "Four dashboards illustrating how you could visualise transparency reporting. These interactive dashboards show how you can show ethnicity & gender of applications in a clear table structure. It also highlights how you can draw insights per applicant, region and per subject in a more visual representation.",
          fr: "La visualisation des résultats des modèles de prévision est très intuitive avec Tableau. Utilisez ce tableau de bord et modifiez les paramètres pour créer vos propres modèles de prévision.",
          it: "Quattro dashboard che illustrano come si potrebbe visualizzare il reporting sulla trasparenza. Queste dashboard interattive illustrano come è possibile mostrare l'etnia e il sesso delle applicazioni in una chiara struttura tabellare. Evidenzia anche come è possibile trarre approfondimenti per richiedente, regione e soggetto in una rappresentazione più visuale",
          es: "Cuatro dashboards que ilustran cómo se puede visualizar información sobre transparencia: análisis de solicitudes por género o lugar de origen, así como información por solicitud de adminisión, región o por titulación.",
          de: "Vier Dashboards, die veranschaulichen, wie Sie die Transparenzberichterstattung visualisieren können. Diese interaktiven Dashboards zeigen, wie Sie die ethnische Herkunft und das Geschlecht von Bewerbungen in einer übersichtlichen Tabellenstruktur darstellen können. Sie zeigen auch, wie Sie Einblicke pro Bewerber, Region und Fachgebiet in einer visuellen Darstellung gewinnen können.",
        },
      },
    ],
  },
  {
    id: 3,
    name: {
      en: "Healthcare",
      fr: "Santé",
      es: "Sanidad",
      it: "Sanità",
      de: "Gesundheitswesen",
    },
    shortDescription: {
      en: "Get a better understanding of how analytics is used for health related use cases.",
      fr: "Mieux comprendre comment l'analytique est utilisée pour les cas d'utilisation liés à la santé.",
      es: "Obtén una mejor comprensión de cómo se puede utilizar Tableau en el entorno de sanidad y farma.",
      it: "Comprendi meglio come l'analitica viene utilizzata per i casi d'uso relativi alla salute.",
      de: "Verstehen Sie besser, wie Analysen für gesundheitsbezogene Anwendungen eingesetzt werden.",
    },
    longDescription: {
      en: "Get a better understanding of how analytics is used for health related use cases.",
      fr: "Mieux comprendre comment l'analytique est utilisée pour les cas d'utilisation liés à la santé.",
      es: "Obtén una mejor comprensión de cómo se puede utilizar Tableau en el entorno de sanidad y farma.",
      it: "Comprendi meglio come l'analitica viene utilizzata per i casi d'uso relativi alla salute.",
      de: "Verschaffen Sie sich ein besseres Verständnis dafür, wie Sie Analysen für gesundheitsbezogene Anwendungen einsetzen können.",
    },
    slug: "/industry/healthcare",
    dashboards: [
      {
        id: 0,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/AEAttendanceandWaitingTimes-Original/AbsoluteValues",
        author: "Joe Beavan",
        dashboardName: {
          en: "A&E Attendance and Waiting Times",
          fr: "Présence et temps d'attente de l'accident et urgence",
          es: "Uso y tiempos de espera en servicios de urgencias",
          it: "Frequenza e tempi di attesa al pronto soccorso",
          de: "Anwesenheit in der Notaufnahme und Wartezeiten",
        },
        publicProfile: "https://public.tableau.com/profile/joe.beaven#!/",
        twitterProfile: "https://public.tableau.com/profile/joe.beaven#!/",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/ae-attendance.png",
        dashboardDescription: {
          en: "Joe shows us how long you have to wait before you're helped in the various A&E departments in England. Use the map to filter to your location.",
          fr: "Joe nous montre combien de temps il vous faudra attendre avant d'être aidé dans les différents services d'A&E en Angleterre. Utilisez la carte pour filtrer votre emplacement.",
          es: "Joe muestra el tiempo de espera en varios departamentos y servicios de urgencia en Inglaterra. Puedes usar el mapa para buscar una localización específica.",
          it: "Joe ci mostra quanto tempo devi aspettare prima di essere aiutato nei vari reparti del Pronto Soccorso in Inghilterra. Usa la mappa per filtrare la tua posizione.",
          de: "Joe zeigt uns, wie lange man in den verschiedenen Notaufnahmen in England warten muss, bis einem geholfen wird. Benutzen Sie die Karte, um nach Ihrem Standort zu filtern.",
        },
      },
      {
        id: 1,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Healthcare-PatientsOverview/PatientDemographicsOverview/owen.barnes@theinformationlab.co.uk/d499f37c-42ac-4dec-83f5-b0a148f7608e",
        author: "Jake Reilly",
        dashboardName: {
          en: "Patient Demographics Overview",
          fr: "Aperçu des données démographiques des patients",
          es: "Análisis demográfico de pacientes",
          de: "Überblick über die demografischen Daten der Patienten",
        },
        publicProfile: "https://public.tableau.com/profile/jake.reilly5338#!/",
        twitterProfile: "https://twitter.com/JF_Reilly",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Patient_Demographics_Overview.png",
        dashboardDescription: {
          en: "Learn more about the patients visiting your hospitals, with a breakdown of key demographics and insights ranging from distribution of the age of patients, to how many patients are admitted over a given period of time. Filter by region, specialty, or admission type.",
          fr: "Apprenez-en davantage sur les patients qui visitent vos hôpitaux, avec une ventilation des données démographiques clés et des informations allant de la distribution de l'âge des patients au nombre de patients admis sur une période donnée. Filtrez par région, spécialité ou type d'admission.",
          es: "Obtenga un mayor detalle sobre los pacientes que visitan una serie de hospitales analizando la información geográfica más importante: distribución por edad, pacientes admitidos en un periodo de tiempo, así como por región, especialidad o tipo de admisión.",
          de: "Erfahren Sie mehr über die Patienten, die Ihre Krankenhäuser besuchen, mit einer Aufschlüsselung der wichtigsten demografischen Daten und Einblicken, die von der Verteilung des Alters der Patienten bis zur Anzahl der Patienten in einem bestimmten Zeitraum reichen. Filtern Sie nach Region, Auffälligkeiten oder Aufnahmeart.",
        },
      },
      {
        id: 2,
        url: "https://clientreporting.theinformationlab.co.uk/t/Copperfield-Dev/views/NumberofCovid-19CasesinSpain/COVIDCasesinSpain",
        author: "Pablo Sáenz de Tejada",
        dashboardName: {
          en: "Covid Cases in Spain",
          fr: "Cas Covid en Espagne",
          es: "Casos de Covid en España",
          it: "Casi Covid in Spagna",
          de: "Covid-Fälle in Spanien",
        },
        publicProfile: "https://public.tableau.com/profile/pablosdt#!/",
        twitterProfile: "https://twitter.com/pablosdt",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/COVID_Cases_in_Spain.png",
        dashboardDescription: {
          en: "Dashboard that is updated daily with the latest Covid cases in Spain. It allows you to do a time series analysis for the whole country or per region.",
          fr: "Tableau de bord mis à jour quotidiennement avec les derniers cas de Covid en Espagne. Il permet de faire une analyse chronologiques pour l'ensemble du pays ou par région.",
          es: "Dashboard actualizado diariamente con los casos de Covid en España permitiendo el análisis a través del tiempo tanto para el país como por comunidad autónoma.",
          it: "Dashboard che viene aggiornata quotidianamente con gli ultimi casi di Covid in Spagna. Ti permette di fare un'analisi delle serie temporali per l'intero paese o per regione.",
          de: "Dashboard, das täglich mit den neuesten Covid-Fällen in Spanien aktualisiert wird. Es ermöglicht Ihnen eine Zeitreihenanalyse für das gesamte Land oder für einzelne Regionen.",
        },
      },
      {
        id: 3,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/MentalHealthReferralsandCOVID-19/Dashboard2",
        author: "Theo Isaac",
        dashboardName: {
          en: "Mental Health & Impact of Covid-19",
          es: "El impacto de la Covid-19 en la salud mental",
          it: "Salute mentale e impatto del Covid-19",
          de: "Psychische Gesundheit und Auswirkungen von Covid-19",
        },
        publicProfile: "https://public.tableau.com/profile/theodore.isaac#!/",
        twitterProfile: "https://twitter.com/Tisaac95",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Dashboard_2.png",
        dashboardDescription: {
          en: "The Coronavirus pandemic has impacted everyone. Many people struggle and suffer with mental health issues. This dashboard shows how the pandemic has effected the number of people that contacted the British NHS related to mental health.",
          es: "El coronavirus nos ha afectado a todos. Este dashboard muestra cómo la pandemia ha afectado al número de personas que se pusieron en contacto con el NHS británico en relación con la salud mental.",
          it: "La pandemia del Coronavirus ha avuto un impatto su tutti. Molte persone lottano e soffrono con problemi di salute mentale. Questo cruscotto mostra come la pandemia ha influenzato il numero di persone che hanno contattato il Sistema Sanitario Nazionale britannico in relazione alla salute mentale.",
          de: "Die Coronavirus-Pandemie hat sich auf alle ausgewirkt. Viele Menschen kämpfen und leiden unter psychischen Problemen. Dieses Dashboard zeigt, wie sich die Pandemie auf die Anzahl der Personen ausgewirkt hat, die den britischen NHS im Zusammenhang mit psychischen Problemen kontaktiert haben.",
        },
      },
    ],
  },
  {
    id: 4,
    name: {
      en: "Sports",
      fr: "Sports",
      es: "Deportes",
      it: "Sport",
      de: "Sport",
    },
    shortDescription: {
      en: "Going the extra mile is important in sports, Tableau will help facilitate that.",
      fr: "Dans le domaine du sport, il est important d'aller plus loin, Tableau vous aidera à y parvenir.",
      es: "La analítica es un aspecto clave en el rendimiento deportivo. Tableau puede ayudarte a optimizar estos procesos.",
      it: "Buttare il cuore oltre l'ostacolo è importante nello sport. Tableau aiuta a facilitarlo.",
      de: "Im Sport kommt es darauf an, die Extrameile zu gehen. Mit Tableau geht das leichter.",
    },
    longDescription: {
      en: "In an ever expanding world of sport science we need to ensure we have the tools that can help us not only look at our players from a holistic viewpoint but we also need to discover insights as quick as possible. Tableau makes this process accessible to everyone.",
      fr: "Dans un monde des sciences du sport en constante expansion, nous devons nous assurer que nous disposons des outils adaptés pour, non seulement regarder nos joueurs d'un point de vue holistique, mais aussi découvrir des informations le plus rapidement possible. Tableau rend ce processus accessible à tous.",
      es: "En el mundo en constante expansión de la ciencia deportiva, se necesita garantizar la disponibilidad de plataformas que ayuden a encontrar insights rápidamente. Tableau facilita este proceso a todo el mundo.",
      it: "In un mondo in continua espansione della scienza dello sport, dobbiamo assicurarci di avere gli strumenti che possono aiutarci non solo a guardare i nostri giocatori da un punto di vista olistico, ma dobbiamo anche scoprire intuizioni il più velocemente possibile. Tableau rende questo processo accessibile a tutti.",
      de: "In einer sich ständig weiterentwickelnden Welt der Sportwissenschaft müssen wir sicherstellen, dass wir unsere Spieler nicht nur aus einem ganzheitlichen Blickwinkel betrachten, sondern auch so schnell wie möglich Schlüsse ziehen können. Tableau macht dies für alle zugänglich.",
    },
    slug: "/industry/sports",
    dashboards: [
      {
        id: 0,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/ChampionsLeagueScoutReport/ScoutReport",
        author: "Joe Stokes",
        dashboardName: {
          en: "Bayern Munich v Chelsea Scout Report",
          fr: "Bayern Munich vs Chelsea Scout Report",
          es: "Scouting del Bayern Munich vs Chelsea",
          it: "Rapporto scout Bayern Monaco-Chelsea",
          de: "Bayern München gegen Chelsea Scoutbericht",
        },
        publicProfile: "https://public.tableau.com/profile/joe.stokes#!/",
        twitterProfile: "https://twitter.com/JoeStokesDS15",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Scout_Report-cropped.png",
        dashboardDescription: {
          en: "This dashboard demonstrates the capabilties of Tableau to perform post-match analysis on a team level including heatmaps, passing analysis for individual players and their shooting capabilities.",
          fr: "Ce tableau de bord démontre les capacités de Tableau à effectuer une analyse d'après-match au niveau de l'équipe, y compris les cartes thermiques, l'analyse des passes pour les joueurs individuels et leurs capacités de tir.",
          es: "Este dashboard muestra las capacidades de Tableau para realizar análisis post-partido a nivel de equipo y de jugadores con análisis de pases y disparos.",
          it: "Questa dashboard dimostra le capacità di Tableau di eseguire analisi post-partita a livello di squadra tra cui heatmap, analisi dei passaggi per i singoli giocatori e le loro capacità di tiro.",
          de: "Dieses Dashboard veranschaulicht die Möglichkeiten von Tableau zur Durchführung von Post-Match-Analysen auf Teamebene, einschließlich Heatmaps, Passanalysen für einzelne Spieler und deren Schussfähigkeiten",
        },
      },
      {
        id: 1,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/FootballTwitter/Twitter",
        author: "Andrew Pick",
        dashboardName: {
          en: "Football Twitter Analysis",
          fr: "Analyse Twitter sur le football",
          es: "Análisis de Twitter y el futbol",
          it: "Analisi del calcio su Twitter",
          de: "Fußball-Twitter-Analyse",
        },
        publicProfile: "https://public.tableau.com/profile/andrew.pick#!/",
        twitterProfile: "https://twitter.com/AndrewPick_TIL",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Twitter.png",
        dashboardDescription: {
          en: "Engaging with your loyal fanbase and knowing what they discuss could be beneficial for any team. With this Social Media Analysis dashboard you get a quick overview of what's going on the socials.",
          fr: "S'engager auprès de vos fidèles supporters et connaître leurs sujets de discussions pourrait être bénéfique pour n'importe quelle équipe. Grâce à ce tableau de bord d'analyse des médias sociaux, vous avez un aperçu rapide de ce qui se passe sur les réseaux sociaux.",
          es: "Estar conectado con los fans y conocer sobre qué hablan puede ser útil para cualquier equipo. Con este dashboard podrás obtener un rápido resumen sobre lo que está ocurriendo en redes sociales.",
          it: "Impegnarsi con la propria fedele fanbase e sapere di cosa discutono potrebbe essere vantaggioso per qualsiasi squadra. Con questa dashboard di analisi dei social media si ottiene una rapida panoramica di ciò che sta succedendo sui social.",
          de: "Sich mit seiner treuen Fangemeinde zu beschäftigen und zu wissen, worüber sie diskutiert, kann für jedes Team von Vorteil sein. Mit diesem Dashboard für die Social-Media-Analyse erhalten Sie einen schnellen Überblick darüber, was in den sozialen Netzwerken passiert.",
        },
      },
      {
        id: 2,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/BaseballStats/BaseballStats",
        author: "Gordon Hack",
        dashboardName: {
          en: "Baseball Stats Analysis",
          fr: "Analyse statistique pour le Baseball",
          es: "Análisis de estadísticas de baseball",
          it: "Analisi delle statistiche del baseball",
          de: "Analyse der Baseball-Statistiken",
        },
        publicProfile: "https://public.tableau.com/profile/gordon.hack#!/",
        twitterProfile: "https://twitter.com/Gordon_Hack",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Baseball_Stats.png",
        dashboardDescription: {
          en: "The foundation of sports analytics was in baseball. In this dashboard Gordon show us baseball stastics between 2015 and 2019. For each player you can see their strenghts and weaknesses in the spider chart.",
          fr: "La fondation de l'analyse du sport se trouve dans le baseball. Dans ce tableau de bord, Gordon nous montre les statistiques du baseball entre 2015 et 2019. Pour chaque joueur, vous pouvez voir ses points forts et ses points faibles.",
          es: "Los inicios de la analítica deportiva se produjeron en el baseball. En este dashboard, Gordon analiza estadísticas de baseball entre 2015 y 2019, permitiendo visualizar las fortalezas y debilidades de cada jugador.",
          it: "Il fondamento delle analisi sportive è stato nel baseball. In questa dashboard Gordon ci mostra le statistiche del baseball tra il 2015 e il 2019. Per ogni giocatore si possono vedere i loro punti di forza e di debolezza nel grafico a ragno.",
          de: "Der Ursprung der Sportanalytik liegt im Baseball. In diesem Dashboard zeigt uns Gordon die Baseball-Statistiken zwischen 2015 und 2019. Für jeden Spieler kann man seine Stärken und Schwächen im Spinnendiagramm sehen.",
        },
      },
      {
        id: 3,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/THERISEANDFALLOFARSENAL/THERISEANDFALLOFARSENAL",
        author: "Seffana Mohamed - Ajaz",
        dashboardName: {
          en: "The Rise and Fall of Arsenal",
          fr: "La montée et la chute d'Arsenal",
          it: "L'ascesa e la caduta dell'Arsenal",
          es: "El ascenso y caída del Arsenal",
        },
        publicProfile:
          "https://public.tableau.com/profile/seffana.mohamed.ajaz#!/",
        twitterProfile: "https://twitter.com/SeffanaAjaz",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/THE_RISE_AND_FALL_OF_ARSENAL.png",
        dashboardDescription: {
          en: "Visualisation showing how the best club of North London, Arsenal, has performed over time. The dashboard shows various metrics, including the number of games played, won or lost.",
          fr: "La fondation de l'analyse du sport se trouve dans le baseball. Dans ce tableau de bord, Gordon nous montre les statistiques du baseball entre 2015 et 2019. Pour chaque joueur, vous pouvez voir ses points forts et ses points faibles.",
          it: "Visualizzazione che mostra come il miglior club del nord di Londra, l'Arsenal, si è comportato nel tempo. Il cruscotto mostra varie metriche, tra cui il numero di partite giocate, vinte o perse.",
          es: "Visualización que muestra como el mejor equipo del norte de Londres, el Arsenal, ha rendido a lo largo del tiempo. Incluye varias métricas como el de partidos jugados y cuántos han ganado o perdido.",
          de: "Visualisierung, die zeigt, wie sich der beste Verein Nordlondons, Arsenal, im Laufe der Zeit entwickelt hat. Das Dashboard zeigt verschiedene Kennzahlen, darunter die Anzahl der gespielten, gewonnenen oder verlorenen Spiele.",
        },
      },
      {
        id: 4,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Whowasthebestmaletennisplayerin2020/FinalDash",
        author: "Theo Isaac",
        dashboardName: {
          en: "Who was the best male tennis player in 2020?",
          fr: "Qui était le meilleur joueur de tennis masculin en 2020?",
          it: "Chi è stato il miglior tennista maschile nel 2020?",
          es: "¿Quién fue el mejor tenista masculino de 2020?",
          de: "Wer war der beste männliche Tennisspieler im Jahr 2020?",
        },
        publicProfile: "https://public.tableau.com/profile/theodore.isaac#!/",
        twitterProfile: "https://twitter.com/Tisaac95",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/tennis.png",
        dashboardDescription: {
          en: "This dashboard looks at data from the men's 2020 tennis season, to try and assess who has been the year’s best player. It allows you to explore metrics between players and to analyse where players rank in certain aspects of their game.",
          fr: "La fondation de l'analyse du sport se trouve dans le baseball. Dans ce tableau de bord, Gordon nous montre les statistiques du baseball entre 2015 et 2019. Pour chaque joueur, vous pouvez voir ses points forts et ses points faibles.",
          it: "Questo dashboard esamina i dati della stagione tennistica maschile del 2020, per cercare di valutare chi è stato il miglior giocatore dell'anno. Permette di esplorare le metriche tra i giocatori e di analizzare la posizione dei giocatori in determinati aspetti del loro gioco.",
          es: "Este dashboard analiza datos de la temporada 2020 del tenis masculino para intentar descubrir quién fue el mejor jugador. Puedes analizar métricas de los distintos jugadores y ver su posición en el ranking en aspectos.",
          de: "Dieses Dashboard betrachtet die Daten der Tennissaison 2020 der Herren, um zu ermitteln, wer der beste Spieler des Jahres war. Es ermöglicht Ihnen, Kennzahlen zwischen den Spielern zu untersuchen und zu analysieren, wo die Spieler in bestimmten Aspekten ihres Spiels stehen.",
        },
      },
      {
        id: 5,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/CasodeusodeTILEspaaparalaPrimeraIberdrola/Inicio",
        author: "Alberto Oraa",
        dashboardName: {
          en: "Analysis of the Spanish La Liga for Women",
          fr: "Analyse de la première division de la Ligue de football féminine",
          it: "Analisi della prima divisione della Women's Soccer League",
          es: "Análisis de la Primera División de la Liga de Fútbol Femenino",
          de: "Analyse der spanischen La Liga der Frauen",
        },
        publicProfile:
          "https://public.tableau.com/profile/alberto.ora.l.pez#!/",
        twitterProfile: "https://twitter.com/_camilb",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Mapa_de_acciones_en_el_partido.png",
        dashboardDescription: {
          en: "Dashboard in Spanish that looks at the La Liga for women in Spain. Alberto analysis the competition, the various matches, how the teams performed and you can also drill down into the performances of the invididual players.",
          fr: "Tableau de bord en espagnol qui regarde la Liga pour les femmes en Espagne. Alberto analyse la compétition, les différents matchs, les performances des équipes et vous pouvez également approfondir les performances des joueurs individuels.",
          it: "Cruscotto in spagnolo che guarda alla Liga femminile in Spagna. Alberto analizza la competizione, le varie partite, come si sono comportate le squadre e puoi anche approfondire le prestazioni dei singoli giocatori.",
          es: "Tablero en español que mira a La Liga para Mujeres en España. Alberto analiza la competencia, los distintos partidos, cómo se desempeñaron los equipos y también puede profundizar en las actuaciones de los jugadores individuales.",
          de: "Dashboard auf Spanisch, das die La Liga der Frauen in Spanien betrachtet. Alberto analysiert den Wettbewerb, die verschiedenen Spiele, die Leistungen der Mannschaften und man kann auch die Leistungen der einzelnen Spielerinnen genauer unter die Lupe nehmen.",
        },
      },
    ],
  },
  {
    id: 5,
    name: {
      en: "eCommerce",
      fr: "eCommerce",
      es: "Comercio electronico",
      it: "eCommerce",
      de: "eCommerce",
    },
    shortDescription: {
      en: "Tableau is the best tool for analysing e-commerce data. It's fast, has lots of connections and reports are easy to share.",
      fr: "Tableau est le meilleur outil d'analyse des données de commerce électronique. C'est rapide, a beaucoup de connexions et les rapports sont faciles à partager.",
      it: "Tableau è lo strumento migliore per analizzare i dati dell'e-commerce. È veloce, ha molte connessioni e i report sono facili da condividere.",
      es: "Tableau es la mejor plataforma para analizar datos de eCommerce. Es rápido, con multitud de conexiones de datos y los informes son fáciles de compartir.",
      de: "Tableau ist das beste Tool um E-Commerce-Daten zu ananlysieren. Es ist schnell, hat viele Verbindungen und die Berichte sind einfach zu teilen.",
    },
    longDescription: {
      en: "Getting insights into your online shop performance and clear and rapid analysis is crucial for every E-Commerce business. Tableau is the best tool to deliver on this promise.",
      fr: "Obtenir des informations sur les performances de votre boutique en ligne et une analyse claire et rapide est essentiel pour chaque entreprise de commerce électronique. Tableau est le meilleur outil pour tenir cette promesse.",
      it: "Tableau è lo strumento migliore per analizzare i dati dell'e-commerce. È veloce, ha molte connessioni e i report sono facili da condividere.",
      es: "Tableau es la mejor plataforma para analizar datos de eCommerce. Es rápido, con multitud de conexiones de datos y los informes son fáciles de compartir.",
      de: "Einblicke in die Performance Ihres Online-Shops sowie klare und schnelle Analysen sind für jedes E-Commerce-Unternehmen entscheidend. Tableau ist das beste Tool, um dieses Versprechen einzulösen.",
    },
    slug: "/industry/ecommerce",
    dashboards: [
      {
        id: 0,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/E-CommerceUseCase/Overview",
        author: "Sylvie Imbert",
        dashboardName: {
          en: "Website Analysis",
          fr: "Analyse de website",
          es: "Análisis web",
          it: "Analisi del sito web",
          de: "Website-Analyse",
        },
        publicProfile: "https://public.tableau.com/profile/sylvie8326#!/",
        twitterProfile: "https://twitter.com/imbert_sylvie",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Overview.png",
        dashboardDescription: {
          en: "These dashboards aim to give visibility to a retailer’s website activity. The overview dashboard provides a broad picture of the website, showing where the traffic and orders are coming from, and when. You can then dive deeper to see the customer’s journey through the funnel and where people drop along that journey, as well as seeing which device is most popular across different metrics so you can adapt your website’s features accordingly.",
          fr: "Ces tableaux de bord visent à donner de la visibilité à l’activité du site Web d’un détaillant. Le tableau de bord de présentation fournit une vue d'ensemble du site Web, indiquant d'où proviennent le trafic et les commandes, et quand. Vous pouvez ensuite approfondir le parcours du client dans l'entonnoir et voir où les gens se rendent au cours de ce voyage, ainsi que voir quel appareil est le plus populaire dans différentes statistiques afin que vous puissiez adapter les fonctionnalités de votre site Web en conséquence.",
          es: "Estos dashboards pretenden generar una visión global de la web de una empresa minorista. Incluye una visión global de la web, dónde se están generando los pedidos y cuando y ver el recorrido de clientes a través del funnel.",
          it: "Questi cruscotti hanno lo scopo di dare visibilità all'attività del sito web di un rivenditore. Il pannello generale fornisce un quadro complessivo del sito web, mostrando da dove proviene il traffico e gli ordini, e quando. È possibile poi immergersi più in profondità per vedere il percorso del cliente attraverso il funnel e dove le persone cadono lungo il percorso, oltre a vedere quale dispositivo è più popolare attraverso diverse metriche in modo da poter adattare le caratteristiche del sito web di conseguenza.",
          de: "Diese Dashboards zeigen die Website-Aktivitäten eines Einzelhändlers. Das Overview-Dashboard vermittelt ein umfassendes Bild der Website und zeigt, woher der Traffic und die Bestellungen kommen und wann. Sie können dann tiefer eintauchen, um die Customer Journey durch den Funnel zu sehen. So können Sie erkennen, wo die Leute auf ihrer journey zurückbleiben oder welches Device bei verschiedenen Gruppen am beliebtesten ist. Damit können Sie die Funktionen Ihrer Website entsprechend anpassen.",
        },
      },
      {
        id: 1,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/RetailMarketing/KPIDashboard",
        author: "Priya Padham",
        dashboardName: {
          en: "Retail Marketing & Sales Performance",
          fr: "Marketing de détail et performance des ventes",
          es: "Rendimiento de ventas",
          it: "Performance di marketing e vendite al dettaglio",
          de: "Marketing- und Verkaufsleistung im Einzelhandel",
        },
        publicProfile: "https://public.tableau.com/profile/p.padham#!/",
        twitterProfile: "https://twitter.com/p_padham",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/KPI_Dashboard.png",
        dashboardDescription: {
          en: "This example shows two dashboards - including a KPI overview and a more in-depth look into how and where customers are interacting with the company. Are sales improving from the prior year, or are there some areas that need to be addressed?",
          fr: "Cet exemple montre deux tableaux de bord - y compris une vue d'ensemble des indicateurs clés de performance et un examen plus détaillé de la manière et de l'endroit où les clients interagissent avec l'entreprise. Les ventes s'améliorent-elles par rapport à l'année précédente ou y a-t-il des domaines qui doivent être traités?",
          es: "Este ejemplo muestra dos dashboards distintos: una descripción general de KPIs y una mirada más profunda sobre cómo y dónde los clientes interactúan con la empresa ¿Se está mejorando respecto al año pasado o hay margen de mejora en algunas áreas?",
          it: "Questo esempio mostra due dashboard - tra cui una panoramica dei KPI e uno sguardo più approfondito su come e dove i clienti stanno interagendo con l'azienda. Le vendite stanno migliorando rispetto all'anno precedente, o ci sono alcune aree che devono essere affrontate?",
          de: "Dieses Beispiel zeigt zwei Dashboards - einen Überblick über die Kennzahlen und einen tieferen Einblick in die Art und Weise, wie und wo die Kunden mit dem Unternehmen interagieren. Haben sich die Umsätze im Vergleich zum Vorjahr verbessert oder gibt es Bereiche, die verbessert werden müssen?",
        },
      },
    ],
  },
  {
    id: 6,
    name: {
      en: "Human Resources",
      fr: "Ressources humaines",
      it: "Risorse Umane",
      es: "Recursos Humanos",
      de: "Personalwesen",
    },
    shortDescription: {
      en: "Data is crucial for making decisions in HR. Tableau makes this a lot easier.",
      fr: "Les données sont essentielles pour prendre des décisions en RH. Tableau rend cela beaucoup plus facile.",
      it: "I dati sono cruciali per prendere decisioni nelle risorse umane. Tableau rende questo molto più facile",
      es: "Los datos son cruciales a la hora de tomar decisiones en RRHH. Tableau ayuda a tomar decisiones más rápido y fácilmente.",
      de: "Daten sind unerlässlich für Entscheidungen im Personalwesen. Tableau macht dies deutlich einfacher.",
    },
    longDescription: {
      en: "Human resource management demands a wealth of readily avaliable metrics to understand employee wellbeing. Use Tableau's user interactivity and functionality to explore all your tailor made research questions.",
      fr: "La gestion des ressources humaines exige une multitude de mesures facilement disponibles pour comprendre le bien-être des employés. Utilisez l'interactivité et les fonctionnalités utilisateur de Tableau pour explorer toutes vos questions de recherche personnalisées.",
      it: "I dati sono cruciali per prendere decisioni nelle risorse umane. Tableau rende questo molto più facile",
      es: "Los datos son cruciales a la hora de tomar decisiones en RRHH. Tableau ayuda a tomar decisiones más rápido y fácilmente.",
      de: "Das Personalmanagement benötigt eine Fülle von leicht zugänglichen Kennzahlen, um das Wohlbefinden der Mitarbeiter zu verstehen. Nutzen Sie die Interaktivität und Funktionalität von Tableau, um alle Ihre maßgeschneiderten Fragen zu untersuchen.",
    },
    slug: "/industry/humanresources",
    dashboards: [
      {
        id: 0,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/DiversityandInclusion-HRLocalGovernment/DI2",
        author: "Alisha Dhillion",
        dashboardName: {
          en: "Diversity and Inclusion Summary",
          fr: "Résumé de la diversité et de l'inclusion",
          it: "Sintesi della diversità e dell'inclusione",
          es: "Resumen de inclusión y diversidad",
          de: "Zusammenfassung Vielfalt und Integration",
        },
        publicProfile: "https://public.tableau.com/profile/alisha7755#!",
        twitterProfile: "https://twitter.com/alishadhillon__",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/D_I_2.png",
        dashboardDescription: {
          en: "As a focus on diversity and inclusion becomes more important for companies, use Tableau to enlighten diversity policies that will help to instil inclusion, respect and appreciation across the entire workforce.",
          fr: "Alors que l'accent sur la diversité et l'inclusion devient plus important pour les entreprises, utilisez Tableau pour éclairer les politiques de diversité qui aideront à inculquer l'inclusion, le respect et l'appréciation à l'ensemble de la main-d'œuvre.",
          it: "Poiché l'attenzione alla diversità e all'inclusione diventa più importante per le aziende, usa Tableau per illuminare le politiche di diversità che aiuteranno a infondere inclusione, rispetto e apprezzamento in tutta la forza lavoro.",
          es: "La incluisión y diversidad en los equipos va ganando importancia rápidamente. Puedes usar Tableau para mostrar las políticas de diversidad e inclusión de la empresa.",
          de: "Da der Fokus auf Diversität und Inklusion für Unternehmen immer wichtiger wird, sollten Sie Tableau nutzen, um Diversitätsrichtlinien zu erläutern, die dazu beitragen, Inklusion, Respekt und Wertschätzung in der gesamten Belegschaft zu vermitteln.",
        },
      },
      {
        id: 1,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/AbsenteeismFinal/Absenteeism",
        author: "Alisha Dhillion",
        dashboardName: {
          en: "Overview of Human Resources",
          fr: "Aperçu des ressources humaines",
          it: "Panoramica delle risorse umane",
          es: "Visión global de RRHH",
          de: "Überblick über die Personalabteilung",
        },
        publicProfile: "https://public.tableau.com/profile/alisha7755#!",
        twitterProfile: "https://twitter.com/alishadhillon__",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Absenteeism.png",
        dashboardDescription: {
          en: "Navigate the instrucies of Human Resource Managment with Tableau dashboards. Explore abseentism, employee satisfaction and all your companies demographics.",
          fr: "Parcourez les instructions de gestion des ressources humaines avec les tableaux de bord Tableau. Explorez le rappel, la satisfaction des employés et toutes les données démographiques de votre entreprise.",
          it: "Naviga le istruzioni della gestione delle risorse umane con i pannelli di Tableau. Esplora il fenomeno dell'assenteismo, la soddisfazione dei dipendenti e tutti i dati demografici della tua azienda.",
          es: "Obtén una visión general de la situación en RRHH: Explora los datos de absentismo, la satisfacción o el perfíl sociodemográfico de los empleados de la empresa.",
          de: "Navigieren Sie mit Tableau Dashboards durch die Aufgaben des Human Resource Managements. Untersuchen Sie Abwesenheiten, Mitarbeiterzufriedenheit und alle demografischen Daten Ihres Unternehmens.",
        },
      },
      {
        id: 2,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/HROverviewPayPerformanceSickPay/HRKPIs",
        author: "Andy Kriebel",
        dashboardName: {
          en: "HR Overview: Pay, Performance, Sick Pay",
          fr: "Présentation des RH : rémunération, performances, indemnités de maladie",
          it: "Panoramica delle HR: retribuzione, prestazioni, indennità di malattia",
          es: "Resumen de RR.HH.: sueldo, rendimiento, sueldo por enfermedad",
          de: "HR Überblick: Gehalt, Leistung, Krankengeld",
        },
        publicProfile: "https://public.tableau.com/app/profile/andy.kriebel",
        twitterProfile: "https://twitter.com/VizWizBI",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/HR_KPIs.png",
        dashboardDescription: {
          en: "This dashboard provides an overview of 9 HR KPIs, ranging from salary to sick days - on either a department or employee level. This dashboard is driven by interactivity, allowing drill-down to the employee level on click, or highlight their focus by hovering over a bar.",
          fr: "Ce tableau de bord fournit un aperçu de 9 KPI RH, allant du salaire aux jours de maladie - au niveau du service ou de l'employé. Ce tableau de bord est piloté par l'interactivité, permettant d'accéder au niveau de l'employé en un clic, ou de mettre en évidence son objectif en survolant une barre",
          it: "Questa dashboard fornisce una panoramica di 9 KPI delle HR, che vanno dallo stipendio ai giorni di malattia, a livello di reparto o di dipendente. Questa dashboard è guidata dall'interattività, consentendo il drill-down al livello dei dipendenti al clic o evidenziando il loro focus passando con il mouse su una barra",
          es: "Este panel proporciona una descripción general de 9 KPI de recursos humanos, que van desde el salario hasta los días de enfermedad, ya sea a nivel de departamento o de empleado. Este panel está impulsado por la interactividad, lo que permite profundizar hasta el nivel del empleado al hacer clic, o resaltar su enfoque colocando el cursor sobre una barra.",
          de: "Dieses Dashboard bietet einen Überblick über 9 HR-KPIs, vom Gehalt bis zu Krankheitstagen - entweder auf Abteilungs- oder Mitarbeiterebene. Dieses Dashboard ist interaktiv und ermöglicht es, per Klick auf die Mitarbeiterebene zu gelangen oder den Bereich zu selektieren, indem man mit dem Mauszeiger über einen Balken fährt.",
        },
      },
      {
        id: 3,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/ExpensesAuditDashboard/ExpensesAudit",
        author: "Luke Donovan",
        dashboardName: {
          en: "Expenses Audit Dashboard",
          fr: "Présentation d'audit des dépenses",
          it: "Pannello di controllo delle spese",
          es: "Panel de auditoría de gastos",
          de: "Ausgaben Audit Dashboard",
        },
        publicProfile: "https://public.tableau.com/app/profile/luke.donovan",
        twitterProfile: "https://twitter.com/Luke_Donovan03",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Expenses_Audit.png",
        dashboardDescription: {
          en: "This dashboard focuses primarily on employee expenses for key financial control audits. Dashboard has been built and deployed as a real business case.",
          fr: "Ce tableau de bord se concentre principalement sur les dépenses des employés pour les principaux audits de contrôle financier. Dashboard a été construit et déployé comme un véritable business case.",
          it: "Questa dashboard si concentra principalmente sulle spese dei dipendenti per gli audit di controllo finanziario chiave. Dashboard è stato creato e distribuito come un vero e proprio business case.",
          es: "Este tablero se enfoca principalmente en los gastos de los empleados para auditorías de control financiero clave. Dashboard se ha creado e implementado como un caso de negocio real.",
          de: "Dieses Dashboard konzentriert sich in erster Linie auf die Mitarbeiterausgaben für wichtige Finanzkontrollprüfungen. Das Dashboard wurde als echter Business Case erstellt und eingesetzt.",
        },
      },
      {
        id: 4,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/HRDashboardRWFD/Overview",
        author: "Alisha Dhillion",
        dashboardName: {
          en: "Human Resources Dashboard",
          fr: "Ressources humaines",
          it: "Risorse Umane",
          es: "Recursos Humanos",
          de: "Personalwesen",
        },
        publicProfile: "https://public.tableau.com/profile/alisha7755#!",
        twitterProfile: "https://twitter.com/alishadhillon__",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/alisha-overview.png",
        dashboardDescription: {
          en: "Navigate this human resources dashboard with an overview of all areas as well as a deep dive into diversity, hiring frequency, employee contracts and a downloadable directory.",
          fr: "Naviguez dans ce tableau de bord des ressources humaines avec un aperçu de tous les domaines ainsi qu'une plongée en profondeur dans la diversité, la fréquence d'embauche, les contrats des employés et un répertoire téléchargeable",
          it: "Naviga in questa dashboard delle risorse umane con una panoramica di tutte le aree e un'analisi approfondita della diversità, della frequenza delle assunzioni, dei contratti dei dipendenti e di una directory scaricabile.",
          es: "Navegue por este panel de recursos humanos con una descripción general de todas las áreas, así como una inmersión profunda en la diversidad, la frecuencia de contratación, los contratos de los empleados y un directorio descargable.",
          de: "Navigieren Sie in diesem Personal-Dashboard mit einem Überblick über alle Bereiche sowie einem tiefen Einblick in Vielfalt, Einstellungshäufigkeit, Mitarbeiterverträge und einem herunterladbaren Verzeichnis",
        },
      },
    ],
  },
  {
    id: 7,
    name: {
      en: "Banking",
      fr: "Bancaire",
      it: "Banche",
      es: "Banca",
      de: "Bankwesen",
    },
    shortDescription: {
      en: "Use Tableau to harvest data insights into all your banking and finance data.",
      fr: "Utilisez Tableau pour recueillir des informations sur toutes vos données bancaires et financières.",
      it: "Utilizza Tableau per raccogliere intuizioni su tutti i tuoi dati bancari e finanziari.",
      es: "Con Tableau puedes analizar tus datos financieros y de banca fácilmente.",
      de: "Gewinnen Sie mit Tableau Dateneinblicke in all Ihre Bank- und Finanzdaten.",
    },
    longDescription: {
      en: "Tableau delivers an endless array of options for visualising your banking data. See how using Tableau harvests data insights that impact securities, finance, and banking.",
      fr: "Tableau propose une gamme infinie d'options pour visualiser vos données bancaires. Découvrez comment l'utilisation de Tableau recueille des informations sur les données qui ont un impact sur les titres, les finances et les banques.",
      it: "Utilizza Tableau per raccogliere intuizioni su tutti i tuoi dati bancari e finanziari.",
      es: "Con Tableau puedes analizar tus datos financieros y de banca fácilmente.",
      de: "Tableau bietet eine endlose Reihe von Optionen zur Visualisierung Ihrer Bankdaten. Erfahren Sie, wie Sie mit Tableau Dateneinblicke gewinnen, die sich auf Wertpapiere, Finanzen und das Bankwesen auswirken.",
    },
    slug: "/industry/banking",
    dashboards: [
      // {
      //   id: 0,
      //   hideTabs: false,
      //   url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/CustomerFacingDashboardSet-Andre/KPIdashboard",
      //   author: "Joe Kernaghan",
      //   dashboardName: {
      //     en: "Client Facing KPI Dashboard",
      //     fr: "Tableau de bord KPI pour le client",
      //     es: "Dashboard de KPIs de clientes",
      //     it: "Dashboard sulla clientela",
      //     de: "Kundenorientiertes KPI-Dashboard",
      //   },
      //   publicProfile: "https://public.tableau.com/profile/joe.kernaghan#!/",
      //   twitterProfile: "https://twitter.com/JoeKernydata",
      //   image:
      //     "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/KPI_dashboard-2.png",
      //   dashboardDescription: {
      //     en: "This key performance indicator dashboard demonstrates all your banking clientele overviews in one place. Isolate time periods and regions to fine-tune to your analytical needs.",
      //     fr: "Ce tableau de bord d'indicateurs clés de performance présente tous les aperçus de votre clientèle bancaire en un seul endroit. Isolez les périodes et les régions pour vous adapter à vos besoins analytiques.",
      //     es: "Este dashboard muestra el detalle completo de los clientes de un banco. Puedes filtrar por periodos de tiempo regiones para focalizar tus análisis.",
      //     it: "Questa dashboard mostra tutte le panoramiche della vostra clientela bancaria in un unico posto. Isolate i periodi di tempo e le regioni per mettere a punto le vostre esigenze analitiche.",
      //     de: "Dieses Dashboard mit den wichtigsten Leistungsindikatoren zeigt Ihnen alle Übersichten über Ihre Bankkunden an einem Ort. Isolieren Sie Zeiträume und Regionen, um eine Feinabstimmung auf Ihre analytischen Bedürfnisse vorzunehmen.",
      //   },
      // },
      // {
      //   id: 1,
      //   hideTabs: false,
      //   url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/CustomerFacingDashboardSet/DeepDiveDashboard",
      //   author: "Joe Kernaghan",
      //   dashboardName: {
      //     en: "Customer Facing Deep-Dive Dashboard",
      //     fr: "Tableau de bord détaillé pour le client",
      //     es: "Dashboard orientado a cliente",
      //     it: "Cruscotto di approfondimento per i clienti",
      //     de: "Kundenorientiertes Deep-Dive Dashboard",
      //   },
      //   publicProfile: "https://public.tableau.com/profile/joe.kernaghan#!/",
      //   twitterProfile: "https://twitter.com/JoeKernydata",
      //   image:
      //     "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Deep_Dive_Dashboard.png",
      //   dashboardDescription: {
      //     en: "Use this dashboard to explore all your customer insights to better value your customers. Filter across customer demographics to tailor your analysis. Benchmark your performance relative to previous month figures, narrowing in on areas to improve.",
      //     fr: "Utilisez ce tableau de bord pour explorer toutes vos informations client afin de mieux valoriser vos clients. Filtrez les données démographiques des clients pour personnaliser votre analyse. Comparez vos performances par rapport aux chiffres du mois précédent, en vous concentrant sur les domaines à améliorer.",
      //     es: "Utiliza este dashboard para explorar la información de tus clientes desde distintos puntos de vista. Filtra por variables demográficas, compara rendimientos respecto al mes anterior y revisa posibles áreas de mejora.",
      //     it: "Usa questa dashboard per esplorare tutte le informazioni sui tuoi clienti per valorizzarli meglio. Filtra attraverso i dati demografici dei clienti per personalizzare la tua analisi. Valuta le tue prestazioni rispetto ai dati del mese precedente, restringendo il campo delle aree da migliorare",
      //     de: "Verwenden Sie dieses Dashboard, um alle Ihre Kundeneinblicke zu untersuchen und Ihre Kunden besser zu bewerten. Filtern Sie nach demografischen Merkmalen Ihrer Kunden, um Ihre Analyse anzupassen. Vergleichen Sie Ihre Leistung mit den Zahlen des Vormonats, um die Bereiche einzugrenzen, in denen Sie sich verbessern müssen.",
      //   },
      // },
      {
        id: 2,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demowealthandbanking-Managerview/BankingDemo-Managerview3",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Wealth & Banking: Advisor Performance",
          fr: "Wealth & Banking: Advisor Performance",
          es: "Wealth & Banking: Advisor Performance",
          it: "Wealth & Banking: Advisor Performance",
          de: "Wealth & Banking: Advisor Performance",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Banking_Demo_-_Manager_view_3.png",
        dashboardDescription: {
          en: "Created with managers in mind, this dashboard visualises the performance of advisors across several key metrics and flags pertinent issues regarding the client pipeline.",
          fr: "Créé en pensant aux gestionnaires, ce tableau de bord visualise la performance des conseillers sur plusieurs indicateurs clés et signale les problèmes pertinents concernant le pipeline de clients.",
          es: "Creado con los gerentes en mente, este panel visualiza el desempeño de los asesores en varias métricas clave y señala los problemas pertinentes relacionados con la cartera de clientes.",
          it: "Creato pensando ai manager, questo dashboard visualizza le prestazioni dei consulenti attraverso diverse metriche chiave e contrassegna i problemi pertinenti relativi alla pipeline del cliente.",
          de: "Dieses Dashboard, das speziell für Manager entwickelt wurde, visualisiert die Leistung von Beratern anhand verschiedener Kennzahlen und zeigt relevante Probleme in der Kundenpipeline auf",
        },
      },
      {
        id: 3,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demowealthandbanking-Advisorview/Bankingdemo-Advisorview",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Wealth & Banking: Advisor View",
          fr: "Wealth & Banking: Advisor View",
          es: "Wealth & Banking: Advisor View",
          it: "Wealth & Banking: Advisor View",
          de: "Wealth & Banking: Advisor View",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Banking_demo_-_Advisor_view.png",
        dashboardDescription: {
          en: "This view grants advisors a snapshot of their current client portfolio, with a particular focus on clients who are currently moving through the onboarding process – drawing attention to high-value clients and potential onboarding hurdles.",
          fr: "Ce point de vue donne aux conseillers un aperçu de leur portefeuille de clients actuel, avec un accent particulier sur les clients qui traversent actuellement le processus d'intégration - attirant l'attention sur les clients de grande valeur et les obstacles potentiels à l'intégration.",
          es: "Esta vista les otorga a los asesores una instantánea de su cartera de clientes actual, con un enfoque particular en los clientes que actualmente se están moviendo por el proceso de incorporación, llamando la atención sobre los clientes de alto valor y los posibles obstáculos de incorporación.",
          it: "Questa visione garantisce ai consulenti un'istantanea del loro attuale portafoglio di clienti, con particolare attenzione ai clienti che stanno attualmente attraversando il processo di onboarding, attirando l'attenzione sui clienti di alto valore e sui potenziali ostacoli all'onboarding.",
          de: "Diese Ansicht gibt den Beratern einen Überblick über ihr aktuelles Kundenportfolio, mit besonderem Augenmerk auf Kunden, die sich derzeit im Onboarding-Prozess befinden, und lenkt die Aufmerksamkeit auf hochwertige Kunden und potenzielle Onboarding-Hürden",
        },
      },
      {
        id: 4,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demowealthandbanking-Profitability/Bankingdemo-Profitability",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Wealth & Banking: Profitability",
          fr: "Wealth & Banking: Profitability",
          es: "Wealth & Banking: Profitability",
          it: "Wealth & Banking: Profitability",
          de: "Wealth & Banking: Profitability",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Banking_demo_-_Profitability.png",
        dashboardDescription: {
          en: "This dashboard allows advisors to gain insight into the assets under their management - identifying high value clients, showing how assets have fared over time, and gauging asset performance.",
          fr: "Ce tableau de bord permet aux conseillers d'avoir un aperçu des actifs sous leur gestion - en identifiant les clients de grande valeur, en montrant comment les actifs ont évolué au fil du temps et en évaluant la performance des actifs.",
          es: "Este panel permite a los asesores obtener información sobre los activos bajo su gestión, identificando clientes de alto valor, mostrando cómo les ha ido a los activos a lo largo del tiempo y midiendo el rendimiento de los activos.",
          it: "Questa dashboard consente ai consulenti di ottenere informazioni dettagliate sulle risorse sotto la loro gestione, identificando i clienti di alto valore, mostrando come le risorse sono andate nel tempo e misurando le prestazioni delle risorse.",
          de: "Dieses Dashboard ermöglicht es den Beratern, einen Einblick in das von ihnen verwaltete Vermögen zu erhalten, indem es hochwertige Kunden identifiziert, die Entwicklung des Vermögens im Laufe der Zeit aufzeigt und die Vermögensperformance misst.",
        },
      },
      {
        id: 5,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/ProfitLossStatement-Overview/PROFITLOSSSTATEMENT",
        author: "Andy Kriebel",
        dashboardName: {
          en: "Profit & Loss Statement",
          fr: "Profit & Loss Statement",
          es: "Profit & Loss Statement",
          it: "Profit & Loss Statement",
          de: "Profit & Loss Statement",
        },
        publicProfile: "https://public.tableau.com/app/profile/andy.kriebel",
        twitterProfile: "https://twitter.com/VizWizBI",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/PROFIT_LOSS_STATEMENT.png",
        dashboardDescription: {
          en: "Visualise the current year's profit & loss metrics while comparing to the prior year, and analyse performance of the current month against the previous. This statement tracks metrics such as Gross & Net Profit Margins, and Quick/Current Ratios.",
          fr: "Visualisez les mesures de profits et pertes de l'année en cours tout en les comparant à l'année précédente et analysez les performances du mois en cours par rapport au précédent. Cette déclaration suit des mesures telles que les marges bénéficiaires brutes et nettes et les ratios rapides/actuels.",
          es: "Visualice las métricas de pérdidas y ganancias del año actual comparándolas con el año anterior y analice el rendimiento del mes actual frente al anterior. Esta declaración rastrea métricas como los márgenes de ganancia bruta y neta y las proporciones rápida / actual.",
          it: "Visualizza le metriche di profitti e perdite dell'anno in corso confrontandole con l'anno precedente e analizza le prestazioni del mese corrente rispetto a quelle precedenti. Questa dichiarazione tiene traccia di metriche come Margini di profitto lordo e netto e Rapporti rapidi/correnti.",
          de: "Visualisieren Sie die Kennzahlen des aktuellen Jahres im Vergleich zum Vorjahr und analysieren Sie die Leistung des aktuellen Monats im Vergleich zum vorherigen. Diese Aufstellung verfolgt Kennzahlen wie Brutto- und Nettogewinnspannen und Quick/Current Ratios.",
        },
      },
    ],
  },
  {
    id: 8,
    name: {
      en: "Retail",
      fr: "La vente au détail",
      it: "Vendita al dettaglio",
      es: "Ventas",
      de: "Einzelhandel",
    },
    shortDescription: {
      en: "Take your retail business online with these logistics dashboard formats.",
      fr: "Mettez votre entreprise de vente au détail en ligne avec ces formats de tableau de bord logistique.",
      it: "Porta online il tuo business al dettaglio con questi modelli di dashboard per la logistica.",
      es: "Lleva tus informes de ventas al siguiente nivel con estos dashboards de logística.",
      de: "Bringen Sie Ihr Einzelhandelsgeschäft mit diesen Logistik-Dashboard-Formaten online.",
    },
    longDescription: {
      en: "The physical retail industry requires a fluid process between the seller and supplier. Allow tableau to shoulder this responsibilty, leaning on its use of live data sources and quick-to-digest chart types.",
      fr: "Le secteur de la vente au détail physique nécessite un processus fluide entre le vendeur et le fournisseur. Permettez à tableau d'assumer cette responsabilité, en s'appuyant sur son utilisation de sources de données en direct et de types de graphiques rapides à digérer.",
      it: "Porta online il tuo business al dettaglio con questi modelli di dashboard per la logistica.",
      es: "Lleva tus informes de ventas al siguiente nivel con estos dashboards de logística.",
      de: "Der physische Einzelhandel erfordert einen fließenden Prozess zwischen Verkäufer und Lieferant. Erlauben Sie Tableau, diese Verantwortung zu übernehmen, indem Sie sich auf die Verwendung von Live-Datenquellen und schnell zu erfassenden Diagrammtypen stützen.",
    },
    slug: "/industry/retail",
    dashboards: [
      {
        id: 0,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/PhysicalRetailLogistics/Homepage",
        author: "Lukas Jennings",
        dashboardName: {
          en: "Logistics of Retail Analytical Suite",
          fr: "Tableau de bord KPI pour le client",
          it: "Strumentazione analitica sulla logistica nella vendita al dettaglio",
          es: "Analítica para logística y comercio minorista",
          de: "Analytische Logistik für den Einzelhandel",
        },
        publicProfile: "https://public.tableau.com/profile/lukas.jennings#!/",
        twitterProfile: "https://twitter.com/LetsGetVizical",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/KPI-2.png",
        dashboardDescription: {
          en: "Shuffle through a pack of dashboards exploring the logistics of the retail goods industry. Use the KPI dashboard for an executive summary, the top-down for warehouse insight, and the exploratory to answer your most pressing research questions.",
          fr: "Parcourez un pack de tableaux de bord explorant la logistique de l'industrie des produits de détail. Utilisez le tableau de bord KPI pour un résumé, le top-down pour un aperçu de l'entrepôt et l'exploratoire pour répondre à vos questions de recherche les plus urgentes.",
          it: "Scorri un pacchetto di cruscotti che esplorano la logistica dell'industria dei beni al dettaglio. Usa il quadro KPI per un riassunto esecutivo, il top-down per una visione del magazzino e l'esplorativo per rispondere alle tue domande di ricerca più pressanti",
          es: "Navega por esta serie de dashboards sobre logística y comercio al por menos. Puedes usar el dashboard de KPIs para ver un resumen más ejecutivo, el análisis top-down para obtener insights sobre almacenes o el dashboard exploratorio para responder a preguntas de mayor detalle.",
          de: "Wählen Sie aus einer Reihe von Dashboards aus, die die Logistik der Einzelhandelsbranche untersuchen. Verwenden Sie das KPI-Dashboard für eine Zusammenfassung, das Top-Down-Dashboard für Einblicke in die Lagerhaltung und das Exploratory-Dashboard für die Beantwortung Ihrer dringlichsten Fragen.",
        },
      },
      {
        id: 1,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/IncomeStatement/IncomeStatement",
        author: "Andy Kriebel",
        dashboardName: {
          en: "Income Statement",
          fr: "Releve de revenue",
          it: "Conto economico",
          es: "Estado de resultados",
          de: "Gewinn- und Verlustrechnung",
        },
        publicProfile: "https://public.tableau.com/app/profile/andy.kriebel",
        twitterProfile: "https://twitter.com/VizWizBI",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Income_Statement.png",
        dashboardDescription: {
          en: "This dashboard visualises an income statement on a year-to-date basis, against prior YTD values, and on monthly comparison to budget. Trace gross sales all the way to net profit, across 9 different KPIs.",
          fr: "Ce tableau de bord visualise un compte de résultat sur une base annuelle, par rapport aux valeurs YTD précédentes et en comparaison mensuelle avec le budget. Suivez les ventes brutes jusqu'au bénéfice net, à travers 9 KPI différents.",
          it: "Questa dashboard visualizza un conto economico su base annua, rispetto ai valori YTD precedenti e rispetto al budget mensile. Traccia le vendite lorde fino all'utile netto, attraverso 9 diversi KPI.",
          es: "Este panel visualiza un estado de resultados hasta la fecha, contra los valores anteriores del año hasta la fecha y en comparación mensual con el presupuesto. Realice un seguimiento de las ventas brutas hasta el beneficio neto, a través de 9 KPI diferentes.",
          de: "Dieses Dashboard visualisiert eine Gewinn- und Verlustrechnung für das laufende Jahr, im Vergleich zu früheren Jahreswerten und im monatlichen Vergleich zum Budget. Verfolgen Sie den Bruttoumsatz bis hin zum Nettogewinn über 9 verschiedene KPIs",
        },
      },
      {
        id: 2,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demomanufacturingdashboards/Manufacturingdemo1-Inventory",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Manufacturing",
          fr: "Manufacturing",
          es: "Manufacturing",
          it: "Manifattura",
          de: "Herstellung",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Manufacturing_demo_1_-_Inventory.png",
        dashboardDescription: {
          en: "These three dashboards aim to provide users with a comprehensive overview of the supply chain, ranging from the performance of inventory, the maintenance of production facilities, and the status of suppliers.",
          fr: "Ces trois tableaux de bord visent à fournir aux utilisateurs un aperçu complet de la chaîne d'approvisionnement, allant de la performance des stocks, la maintenance des installations de production et le statut des fournisseurs.",
          it: "Questi tre dashboard mirano a fornire agli utenti una panoramica completa della catena di approvvigionamento, che va dalle prestazioni dell'inventario, alla manutenzione degli impianti di produzione e allo stato dei fornitori.",
          es: "Estos tres paneles tienen como objetivo proporcionar a los usuarios una descripción general completa de la cadena de suministro, que van desde el rendimiento del inventario, el mantenimiento de las instalaciones de producción y el estado de los proveedores.",
          de: "Diese drei Dashboards sollen den Nutzern einen umfassenden Überblick über die Lieferkette verschaffen, angefangen von der Leistung der Lagerbestände über die Wartung der Produktionsanlagen bis hin zum Status der Zulieferer",
        },
      },
    ],
  },
  {
    id: 9,
    name: {
      en: "Real Estate",
      fr: "le immobiliers",
      es: "Sector inmobiliario",
      it: "Immobiliare",
      de: "Immobilien",
    },
    shortDescription: {
      en: "Enhance your real estate portfolio management with quick, easy-to-digest Tableau analytics.",
      fr: "Améliorez la gestion de votre portefeuille immobilier avec des analyses Tableau rapides et faciles à comprendre.",
      it: "Migliora la gestione del tuo portafoglio immobiliare con analisi Tableau veloci e facili da digerire.",
      es: "Sácale partido a tu porfolio con la capacidad analítica y de visualización de Tableau.",
      de: "Verbessern Sie das Management Ihres Immobilienportfolios mit schnellen, leicht verständlichen Tableau-Analysen.",
    },
    longDescription: {
      en: "Enable users to track their real estate portfolio in real-time to increase efficiency, reduce backlog, and enhance analysis.",
      fr: "Permettez aux utilisateurs de suivre leur portefeuille immobilier en temps réel pour augmenter l'efficacité, réduire le carnet de commandes et améliorer l'analyse.",
      it: "Migliora la gestione del tuo portafoglio immobiliare con analisi Tableau veloci e facili da digerire.",
      es: "Sácale partido a tu porfolio con la capacidad analítica y de visualización de Tableau.",
      de: "Ermöglicht es den Nutzern, ihr Immobilienportfolio in Echtzeit zu verfolgen, um die Effizienz zu steigern, Rückstände zu verringern und die Analyse zu verbessern.",
    },
    slug: "/industry/realestate",
    dashboards: [
      {
        id: 0,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/RealEstateDashboards/ExecutiveView",
        author: "Valerija Kirjackaja",
        dashboardName: {
          en: "Real Estate Performance Analysis",
          fr: "Analyse de la performance immobilière",
          it: "Analisi della performance immobiliare",
          es: "Análisis de rendimiento en el sector inmobiliario",
          de: "Analyse der Immobilienleistung",
        },
        publicProfile:
          "https://public.tableau.com/profile/valerija.kirjackaja#!/",
        twitterProfile: "https://twitter.com/whereismytrain",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Executive_View.png",
        dashboardDescription: {
          en: "Explore a broad analytical suite looking into rent collection, expiring leases and vacancy. Play with filtering and interactivity to answer all the research questions you have in mind.",
          fr: "Explorez une vaste suite analytique portant sur la perception des loyers, les baux expirés et la vacance. Jouez avec le filtrage et l'interactivité pour répondre à toutes les questions de recherche que vous avez en tête.",
          es: "Explore una amplia suite analítica que analiza el cobro de alquileres, los arrendamientos que vencen y las vacantes. Juega con el filtrado y la interactividad para responder todas las preguntas de investigación que tengas en mente.",
          it: "Esplora un'ampia suite analitica che esamina la riscossione degli affitti, le locazioni in scadenza e i posti liberi. Gioca con i filtri e l'interattività per rispondere a tutte le domande di ricerca che hai in mente.",
          de: "Entdecken Sie eine breite analytische Auswahl, die sich mit der Erhebung von Mieten, auslaufenden Mietverträgen und Leerstand befasst. Spielen Sie mit Filterung und Interaktivität, um alle Fragen zu beantworten, die Ihnen vorschweben",
        },
      },
    ],
  },
  {
    id: 10,
    name: {
      en: "Information Technology",
      fr: "Informatique",
      es: "Tecnologías de la información",
      it: "Tecnologie dell'informazione",
      de: "Informationstechnologie",
    },
    shortDescription: {
      en: "IT departments typically have lots of data. And they also know what to do with that data. Tableau will be able to help them realise the full potential of what they collect.",
      fr: "Les services informatiques ont généralement beaucoup de données. Et ils savent également quoi faire avec ces données. Tableau sera en mesure de les aider à réaliser le plein potentiel de ce qu'ils collectent.",
      it: "I reparti IT in genere dispongono di molti dati. E sanno anche cosa fare con quei dati. Tableau sarà in grado di aiutarli a realizzare il pieno potenziale di ciò che raccolgono.",
      es: "Los departamentos de TI suelen tener una gran cantidad de datos. Y también saben qué hacer con esos datos. Tableau podrá ayudarlos a aprovechar todo el potencial de lo que recopilan.",
      de: "IT-Abteilungen verfügen meistens über große Datenmengen - und wissen, wie sie sie am besten einsetzen. Tableau kann ihnen dabei helfen, das volle Potenzial der gesammelten Daten auszuschöpfen.",
    },
    longDescription: {
      en: "Typically IT systems gather a lot of data. These systems are in place to support business users in their every day tasks. Having a good understanding of what's going on in these systems is important. Tableau's visual analytics and dashboarding capability can make this a whole lot easier.",
      fr: "En règle générale, les systèmes informatiques rassemblent beaucoup de données. Ces systèmes sont en place pour aider les utilisateurs professionnels dans leurs tâches quotidiennes. Il est important de bien comprendre ce qui se passe dans ces systèmes. L'analyse visuelle et la capacité de tableau de bord de Tableau peuvent rendre cela beaucoup plus facile.",
      it: "In genere i sistemi IT raccolgono molti dati. Questi sistemi sono in atto per supportare gli utenti aziendali nelle loro attività quotidiane. Avere una buona comprensione di cosa sta succedendo in questi sistemi è importante. L'analisi visiva e la capacità di dashboarding di Tableau possono rendere tutto questo molto più semplice.",
      es: "Normalmente, los sistemas de TI recopilan una gran cantidad de datos. Estos sistemas están implementados para ayudar a los usuarios comerciales en sus tareas diarias. Es importante tener una buena comprensión de lo que sucede en estos sistemas. El análisis visual y la capacidad de creación de paneles de Tableau pueden hacer que esto sea mucho más fácil.",
      de: "Typischerweise sammeln IT-Systeme eine Menge Daten. Diese Systeme sind dazu da, die Benutzer bei ihren alltäglichen Aufgaben zu unterstützen. Es ist wichtig, ein gutes Verständnis dafür zu haben, was in diesen Systemen vor sich geht. Die visuellen Analyse- und Dashboarding-Funktionen von Tableau können dies erheblich vereinfachen.",
    },
    slug: "/industry/informationtechnology",
    dashboards: [
      {
        id: 0,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/ITTicketsTracker-SampleDashboard/ITTicketsTracker",
        author: "Natalia Miteva",
        dashboardName: {
          en: "IT Tickets Tracker",
          fr: "Traqueur de billets IT",
          it: "Tracker di biglietti IT",
          es: "Rastreador de tickets de TI",
          de: "IT Ticket Tracker",
        },
        publicProfile: "https://public.tableau.com/profile/natalia.miteva#!/",
        twitterProfile: "https://twitter.com/nataliatamiteva",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/IT_Tickets_Tracker.png",
        dashboardDescription: {
          en: "This dashboard aims to help IT professionals keep track of their tickets pipeline. It can serve as a monitoring tool to manage and prioritise resources. Click on the charts and use the filters to uncover deeper insights.",
          fr: "Explorez une vaste suite analytique portant sur la perception des loyers, les baux expirés et la vacance. Jouez avec le filtrage et l'interactivité pour répondre à toutes les questions de recherche que vous avez en tête.",
          es: "Explore una amplia suite analítica que analiza el cobro de alquileres, los arrendamientos que vencen y las vacantes. Juega con el filtrado y la interactividad para responder todas las preguntas de investigación que tengas en mente.",
          it: "Esplora un'ampia suite analitica che esamina la riscossione degli affitti, le locazioni in scadenza e i posti liberi. Gioca con i filtri e l'interattività per rispondere a tutte le domande di ricerca che hai in mente.",
          de: "Dieses Dashboard soll IT-Fachleuten helfen, den Überblick über ihre Ticket-Pipeline zu behalten. Es kann als Überwachungsinstrument dienen, um Ressourcen zu verwalten und zu priorisieren. Klicken Sie auf die Diagramme und verwenden Sie die Filter, um tiefere Einblicke zu erhalten.",
        },
      },
    ],
  },
  {
    id: 11,
    name: {
      en: "Customer Success",
      fr: "Responsable du succès client",
      es: "Éxito del cliente",
      it: "Successo del cliente",
      de: "Kundenerfolg",
    },
    shortDescription: {
      en: "Explore ways that Tableau can help you make sense of customer-orientated data.",
      fr: "Découvrez comment Tableau peut vous aider à donner un sens aux données orientées client.",
      it: "Esplora i modi in cui Tableau può aiutarti a dare un senso ai dati orientati al cliente.",
      es: "Explore las formas en que Tableau puede ayudarlo a comprender los datos orientados al cliente.",
      de: "Entdecken Sie, wie Sie mit Tableau kundenorientierte Daten sinnvoll nutzen.",
    },
    longDescription: {
      en: "From tracking renewing contracts, analysing case data, to sentiment analysis of customer feedback; Tableau can help you understand the journey of customers. This can be seen at many different levels, be it high level, tracking performance of CSMs, or on an individual contract level.",
      fr: "Du suivi du renouvellement des contrats, de l'analyse des données de cas à l'analyse des sentiments des commentaires des clients ; Tableau peut vous aider à comprendre le parcours des clients. Cela peut être vu à de nombreux niveaux différents, qu'il s'agisse de haut niveau, de suivi des performances des CSM, ou au niveau d'un contrat individuel.",
      it: "Dal monitoraggio del rinnovo dei contratti, all'analisi dei dati del caso, all'analisi del sentiment del feedback dei clienti; Tableau può aiutarti a comprendere il percorso dei clienti. Questo può essere visto a molti livelli diversi, che si tratti di alto livello, monitoraggio delle prestazioni dei CSM o a livello di contratto individuale.",
      es: "Desde el seguimiento de la renovación de contratos, el análisis de datos de casos, hasta el análisis de opiniones de los comentarios de los clientes; Tableau puede ayudarlo a comprender el recorrido de los clientes. Esto se puede ver en muchos niveles diferentes, ya sea de alto nivel, rastreando el desempeño de los CSM, o en un nivel de contrato individual.",
      de: "Von der Verfolgung von Vertragsverlängerungen über die Analyse von Falldaten bis hin zur Stimmungsanalyse von Kundenfeedback: Tableau hilft Ihnen, die Geschichte der Kunden zu verstehen. Dies kann auf vielen verschiedenen Ebenen erfolgen, sei es auf hoher Ebene, bei der Verfolgung der Leistung von CSMs oder auf individueller Vertragsebene",
    },
    slug: "/industry/customersuccess",
    dashboards: [
      {
        id: 0,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/CustomerSuccess-ContractManagementOverview/ContractManagementOverview",
        author: "Owen Barnes",
        dashboardName: {
          en: "Contract Management Overview - CSM Level",
          fr: "Présentation de la gestion des contrats - Niveau CSM",
          it: "Panoramica sulla gestione dei contratti - Livello CSM",
          es: "Descripción general de la gestión de contratos: nivel CSM",
          de: "Überblick über das Vertragsmanagement - CSM-Ebene",
        },
        publicProfile: "https://public.tableau.com/app/profile/owen.barnes",
        twitterProfile: "https://twitter.com/OwenBData",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Contract_Management_Overview.png",
        dashboardDescription: {
          en: "Track a CSMs performance over time, by viewing the progress of their managed contracts and their recent engagements with customers. Track the contract value over time, and see any contracts that are either set for renewal, being cancelled, or may require some attention.",
          fr: "Suivez les performances d'un CSM au fil du temps, en affichant la progression de leurs contrats gérés et leurs engagements récents avec les clients. Suivez la valeur du contrat au fil du temps et voyez tous les contrats qui sont soit renouvelés, soit annulés, ou qui peuvent nécessiter une certaine attention.",
          es: "Realice un seguimiento del desempeño de un CSM a lo largo del tiempo, viendo el progreso de sus contratos administrados y sus compromisos recientes con los clientes. Realice un seguimiento del valor del contrato a lo largo del tiempo y vea los contratos que están programados para renovación, que se cancelan o que pueden requerir atención.",
          it: "Tieni traccia delle prestazioni di un CSM nel tempo, visualizzando lo stato di avanzamento dei loro contratti gestiti e i loro recenti impegni con i clienti. Tieni traccia del valore del contratto nel tempo e vedi tutti i contratti che sono impostati per il rinnovo, in fase di annullamento o che potrebbero richiedere un po' di attenzione.",
          de: "Verfolgen Sie die Leistung eines CSM im Laufe der Zeit, indem Sie den Fortschritt der von ihm verwalteten Verträge und seine jüngsten Engagements bei Kunden anzeigen. Verfolgen Sie den Vertragswert im Laufe der Zeit und sehen Sie alle Verträge, die entweder zur Erneuerung anstehen, gekündigt werden oder etwas Aufmerksamkeit erfordern.",
        },
      },
      {
        id: 2,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/CustomerSupportPackageTrends/ContractView",
        author: "Valerija Kirjackaja",
        dashboardName: {
          en: "Customer Support Package Trends",
          fr: "Tendances des packages de support client",
          it: "Tendenze del pacchetto di assistenza clienti",
          es: "Tendencias de paquetes de soporte al cliente",
          de: "Trends bei Kunden-Support-Paketen",
        },
        publicProfile:
          "https://public.tableau.com/app/profile/valerija.kirjackaja",
        twitterProfile: "https://twitter.com/whereismytrain",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Contract_View.png",
        dashboardDescription: {
          en: "Track the trends in Support Package distribution amongst your clients on a Contract, Product, or Account Manager level, and make an informed decision on who to target next. Switch between views using the dashboard tabs.",
          fr: "Suivez les tendances de la distribution du Support Package parmi vos clients au niveau du contrat, du produit ou du gestionnaire de compte, et prenez une décision éclairée sur qui cibler ensuite. Basculez entre les vues à l'aide des onglets du tableau de bord.",
          es: "Realice un seguimiento de las tendencias en la distribución de paquetes de soporte entre sus clientes a nivel de contrato, producto o administrador de cuentas, y tome una decisión informada sobre a quién dirigirse a continuación. Cambie entre vistas usando las pestañas del tablero.",
          it: "Tieni traccia delle tendenze nella distribuzione del pacchetto di supporto tra i tuoi clienti a livello di contratto, prodotto o account manager e prendi una decisione informata su chi sarà il prossimo obiettivo. Passa da una visualizzazione all'altra utilizzando le schede della dashboard.",
          de: "Verfolgen Sie die Trends bei der Verteilung von Support-Paketen unter Ihren Kunden auf Vertrags-, Produkt- oder Account-Manager-Ebene, und treffen Sie eine fundierte Entscheidung darüber, wen Sie als Nächstes ansprechen möchten. Wechseln Sie über die Dashboard-Registerkarten zwischen den Ansichten.",
        },
      },
      {
        id: 1,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/CaseAnalysisBacklog/CaseBacklog",
        author: "Owen Barnes",
        dashboardName: {
          en: "Case Analysis - Current & Backlog",
          fr: "Analyse de cas - Actuel et arriéré",
          it: "Analisi del caso - Attuale e arretrato",
          es: "Análisis de casos: actual y atrasado",
          de: "Fallanalyse - Aktuell & Rückstand",
        },
        publicProfile: "https://public.tableau.com/app/profile/owen.barnes",
        twitterProfile: "https://twitter.com/OwenBData",
        image:
          "https://embedding-til-images.s3.eu-west-2.amazonaws.com/Project+Copperfield/industrydashboards/Case_Backlog.png",
        dashboardDescription: {
          en: "Use this dashboard to explore the progression of ongoing cases, either at the current month, or historically. This dashboard will provide indicators for the amount of open, closed, resolved, and on hold cases, as well as provide a case-level table that shows.",
          fr: "Utilisez ce tableau de bord pour explorer la progression des cas en cours, soit au cours du mois en cours, soit historiquement. Ce tableau de bord fournira des indicateurs pour le nombre de cas ouverts, fermés, résolus et en attente, ainsi qu'un tableau au niveau du cas qui montre.",
          es: "Utilice este panel para explorar la progresión de los casos en curso, ya sea en el mes actual o históricamente. Este tablero proporcionará indicadores para la cantidad de casos abiertos, cerrados, resueltos y en espera, así como una tabla a nivel de caso que muestra.",
          it: "Utilizza questa dashboard per esplorare la progressione dei casi in corso, nel mese corrente o in cronologia. Questa dashboard fornirà indicatori per la quantità di casi aperti, chiusi, risolti e in attesa, oltre a fornire una tabella a livello di caso che mostra.",
          de: "Verwenden Sie dieses Dashboard, um die Entwicklung laufender Fälle zu untersuchen, entweder im aktuellen Monat oder in der Vergangenheit. Dieses Dashboard bietet Indikatoren für die Anzahl offener, abgeschlossener, gelöster und zurückgestellter Fälle sowie eine Tabelle auf Fallebene, die dies zeigt.",
        },
      },
    ],
  },
  {
    id: 12,
    name: {
      en: "Private Wealth Management",
      fr: "Private Wealth Management",
      es: "Private Wealth Management",
      it: "Private Wealth Management",
      de: "Private Wealth Management",
    },
    shortDescription: {
      en: "Track investment fund state & performance with clear Tableau visuals.",
      fr: "Track investment fund state & performance with clear Tableau visuals.",
      it: "Track investment fund state & performance with clear Tableau visuals.",
      es: "Track investment fund state & performance with clear Tableau visuals.",
      de: "Track investment fund state & performance with clear Tableau visuals.",
    },
    longDescription: {
      en: "Track investment fund state & performance with clear Tableau visuals.",
      fr: "Track investment fund state & performance with clear Tableau visuals.",
      it: "Track investment fund state & performance with clear Tableau visuals.",
      es: "Track investment fund state & performance with clear Tableau visuals.",
      de: "Track investment fund state & performance with clear Tableau visuals.",
    },
    slug: "/industry/privatewealthmanagement",
    dashboards: [
      {
        id: 0,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/DemoPrivateWealthManagement/ContributionsWithdrawals",
        author: "Lemis Tufail",
        dashboardName: {
          en: "Contributions and Withdrawals",
          fr: "Contributions and Withdrawals",
          it: "Contributions and Withdrawals",
          es: "Contributions and Withdrawals",
          de: "Contributions and Withdrawals",
        },
        publicProfile: "https://public.tableau.com/app/profile/lemis.tufail",
        twitterProfile: "https://twitter.com/LemisTufail",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1665397731/TIL%20Embedding%20Site/Contributions_Withdrawals_qoehf9.png",
        dashboardDescription: {
          en: "Tracking contributions and withdrawals is vital when understanding where a private wealth manager is heading for funds under management (FUM). This view allows management to see where they are trending and whether they are on course to increase or decrease FUM, taking into account their clients' ages.",
          fr: "Tracking contributions and withdrawals is vital when understanding where a private wealth manager is heading for funds under management (FUM). This view allows management to see where they are trending and whether they are on course to increase or decrease FUM, taking into account their clients' ages.",
          es: "Tracking contributions and withdrawals is vital when understanding where a private wealth manager is heading for funds under management (FUM). This view allows management to see where they are trending and whether they are on course to increase or decrease FUM, taking into account their clients' ages.",
          it: "Tracking contributions and withdrawals is vital when understanding where a private wealth manager is heading for funds under management (FUM). This view allows management to see where they are trending and whether they are on course to increase or decrease FUM, taking into account their clients' ages.",
          de: "Tracking contributions and withdrawals is vital when understanding where a private wealth manager is heading for funds under management (FUM). This view allows management to see where they are trending and whether they are on course to increase or decrease FUM, taking into account their clients' ages.",
        },
      },
      {
        id: 1,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/DemoPrivateWealthManagement/ShareofWalletOpportunities",
        author: "Lemis Tufail",
        dashboardName: {
          en: "Share of Wallet or Opportunities",
          fr: "Share of Wallet or Opportunities",
          it: "Share of Wallet or Opportunities",
          es: "Share of Wallet or Opportunities",
          de: "Share of Wallet or Opportunities",
        },
        publicProfile: "https://public.tableau.com/app/profile/lemis.tufail",
        twitterProfile: "https://twitter.com/LemisTufail",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1665398190/TIL%20Embedding%20Site/Share_of_WalletOpportunities_uz0gyo.png",
        dashboardDescription: {
          en: "This view allows you to observe clients wallet share on an hourly basis, adding this data with further context around which products they have and how often they are serviced is key in understanding future growth amongst an existing customer base",
          fr: "This view allows you to observe clients wallet share on an hourly basis, adding this data with further context around which products they have and how often they are serviced is key in understanding future growth amongst an existing customer base",
          es: "This view allows you to observe clients wallet share on an hourly basis, adding this data with further context around which products they have and how often they are serviced is key in understanding future growth amongst an existing customer base",
          it: "This view allows you to observe clients wallet share on an hourly basis, adding this data with further context around which products they have and how often they are serviced is key in understanding future growth amongst an existing customer base",
          de: "This view allows you to observe clients wallet share on an hourly basis, adding this data with further context around which products they have and how often they are serviced is key in understanding future growth amongst an existing customer base",
        },
      },
      {
        id: 2,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demowealthandbanking-Managerview/BankingDemo-Managerview3",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Wealth & Banking: Advisor Performance",
          fr: "Wealth & Banking: Advisor Performance",
          es: "Wealth & Banking: Advisor Performance",
          it: "Wealth & Banking: Advisor Performance",
          de: "Wealth & Banking: Advisor Performance",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://res.cloudinary.com/dmim37dbf/image/upload/v1620381019/Project%20Copperfield/industrydashboards/Banking_Demo_-_Manager_view_3.png",
        dashboardDescription: {
          en: "Created with managers in mind, this dashboard visualises the performance of advisors across several key metrics and flags pertinent issues regarding the client pipeline.",
          fr: "Créé en pensant aux gestionnaires, ce tableau de bord visualise la performance des conseillers sur plusieurs indicateurs clés et signale les problèmes pertinents concernant le pipeline de clients.",
          es: "Creado con los gerentes en mente, este panel visualiza el desempeño de los asesores en varias métricas clave y señala los problemas pertinentes relacionados con la cartera de clientes.",
          it: "Creato pensando ai manager, questo dashboard visualizza le prestazioni dei consulenti attraverso diverse metriche chiave e contrassegna i problemi pertinenti relativi alla pipeline del cliente.",
          de: "Dieses Dashboard, das speziell für Manager entwickelt wurde, visualisiert die Leistung von Beratern anhand verschiedener Kennzahlen und zeigt relevante Probleme in der Kundenpipeline auf",
        },
      },
      {
        id: 3,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demowealthandbanking-Advisorview/Bankingdemo-Advisorview",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Wealth & Banking: Advisor View",
          fr: "Wealth & Banking: Advisor View",
          es: "Wealth & Banking: Advisor View",
          it: "Wealth & Banking: Advisor View",
          de: "Wealth & Banking: Advisor View",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://res.cloudinary.com/dmim37dbf/image/upload/v1620381454/Project%20Copperfield/industrydashboards/Banking_demo_-_Advisor_view.png",
        dashboardDescription: {
          en: "This view grants advisors a snapshot of their current client portfolio, with a particular focus on clients who are currently moving through the onboarding process – drawing attention to high-value clients and potential onboarding hurdles.",
          fr: "Ce point de vue donne aux conseillers un aperçu de leur portefeuille de clients actuel, avec un accent particulier sur les clients qui traversent actuellement le processus d'intégration - attirant l'attention sur les clients de grande valeur et les obstacles potentiels à l'intégration.",
          es: "Esta vista les otorga a los asesores una instantánea de su cartera de clientes actual, con un enfoque particular en los clientes que actualmente se están moviendo por el proceso de incorporación, llamando la atención sobre los clientes de alto valor y los posibles obstáculos de incorporación.",
          it: "Questa visione garantisce ai consulenti un'istantanea del loro attuale portafoglio di clienti, con particolare attenzione ai clienti che stanno attualmente attraversando il processo di onboarding, attirando l'attenzione sui clienti di alto valore e sui potenziali ostacoli all'onboarding.",
          de: "Diese Ansicht gibt den Beratern einen Überblick über ihr aktuelles Kundenportfolio, mit besonderem Augenmerk auf Kunden, die sich derzeit im Onboarding-Prozess befinden, und lenkt die Aufmerksamkeit auf hochwertige Kunden und potenzielle Onboarding-Hürden",
        },
      },
      {
        id: 4,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/Demowealthandbanking-Profitability/Bankingdemo-Profitability",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Wealth & Banking: Profitability",
          fr: "Wealth & Banking: Profitability",
          es: "Wealth & Banking: Profitability",
          it: "Wealth & Banking: Profitability",
          de: "Wealth & Banking: Profitability",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://res.cloudinary.com/dmim37dbf/image/upload/v1620382146/Project%20Copperfield/industrydashboards/Banking_demo_-_Profitability.png",
        dashboardDescription: {
          en: "This dashboard allows advisors to gain insight into the assets under their management - identifying high value clients, showing how assets have fared over time, and gauging asset performance.",
          fr: "Ce tableau de bord permet aux conseillers d'avoir un aperçu des actifs sous leur gestion - en identifiant les clients de grande valeur, en montrant comment les actifs ont évolué au fil du temps et en évaluant la performance des actifs.",
          es: "Este panel permite a los asesores obtener información sobre los activos bajo su gestión, identificando clientes de alto valor, mostrando cómo les ha ido a los activos a lo largo del tiempo y midiendo el rendimiento de los activos.",
          it: "Questa dashboard consente ai consulenti di ottenere informazioni dettagliate sulle risorse sotto la loro gestione, identificando i clienti di alto valore, mostrando come le risorse sono andate nel tempo e misurando le prestazioni delle risorse.",
          de: "Dieses Dashboard ermöglicht es den Beratern, einen Einblick in das von ihnen verwaltete Vermögen zu erhalten, indem es hochwertige Kunden identifiziert, die Entwicklung des Vermögens im Laufe der Zeit aufzeigt und die Vermögensperformance misst.",
        },
      },
    ],
  },{
    id: 13,
    name: {
      en: "Consumer Duty",
      fr: "Consumer Duty",
      es: "Consumer Duty",
      it: "Consumer Duty",
      de: "Consumer Duty",
    },
    shortDescription: {
      en: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      fr: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      it: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      es: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      de: "Helping companies to meet the demands of the FCA's consumer duty legislation",
    },
    longDescription: {
      en: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      fr: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      it: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      es: "Helping companies to meet the demands of the FCA's consumer duty legislation",
      de: "Helping companies to meet the demands of the FCA's consumer duty legislation",
    },
    slug: "/industry/consumerduty",
    dashboards: [
      {
        id: 0,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/Embedding/views/ProductApprovalProcess/ProductApprovalStatus",
        author: "Chris Meardon",
        dashboardName: {
          en: "Product Approval Process",
          fr: "Product Approval Process",
          it: "Product Approval Process",
          es: "Product Approval Process",
          de: "Product Approval Process",
        },
        publicProfile: "https://public.tableau.com/app/profile/chris.meardon",
        twitterProfile: "https://twitter.com/chris_vizes",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1675697501/TIL%20Embedding%20Site/Product_Approval_Status_mhr9or.png",
        dashboardDescription: {
          en: "To ensure we follow legislation, our new products are put through a quality assurance process. This dashboard allows the monitoring of product statuses throughout the QA process and the identification of any issues.",
          fr: "To ensure we follow legislation, our new products are put through a quality assurance process. This dashboard allows the monitoring of product statuses throughout the QA process and the identification of any issues.",
          es: "To ensure we follow legislation, our new products are put through a quality assurance process. This dashboard allows the monitoring of product statuses throughout the QA process and the identification of any issues.",
          it: "To ensure we follow legislation, our new products are put through a quality assurance process. This dashboard allows the monitoring of product statuses throughout the QA process and the identification of any issues.",
          de: "To ensure we follow legislation, our new products are put through a quality assurance process. This dashboard allows the monitoring of product statuses throughout the QA process and the identification of any issues.",
        },
      },
      {
        id: 1,
        hideTabs: true,
        url: "https://clientreporting.theinformationlab.co.uk/t/Embedding/views/ConsumerDutyScorecard/Scorecard",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Consumer Duty Scorecard",
          fr: "Consumer Duty Scorecard",
          it: "Consumer Duty Scorecard",
          es: "Consumer Duty Scorecard",
          de: "Consumer Duty Scorecard",
        },
        publicProfile: "https://public.tableau.com/app/profile/ellen4268",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1675697652/TIL%20Embedding%20Site/Scorecard_brktmb.png",
        dashboardDescription: {
          en: "This dashboard shows a high-level performance overview regarding four key categories of consumer duty, aiming to provide insight into the extent to which consumers are sufficiently protected. ",
          fr: "This dashboard shows a high-level performance overview regarding four key categories of consumer duty, aiming to provide insight into the extent to which consumers are sufficiently protected. ",
          es: "This dashboard shows a high-level performance overview regarding four key categories of consumer duty, aiming to provide insight into the extent to which consumers are sufficiently protected. ",
          it: "This dashboard shows a high-level performance overview regarding four key categories of consumer duty, aiming to provide insight into the extent to which consumers are sufficiently protected. ",
          de: "This dashboard shows a high-level performance overview regarding four key categories of consumer duty, aiming to provide insight into the extent to which consumers are sufficiently protected. ",
        },
      },
      {
        id: 2,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/Embedding/views/ConsumerDutyDemo/1Overview",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Consumer Duty Demo",
          fr: "Consumer Duty Demo",
          es: "Consumer Duty Demo",
          it: "Consumer Duty Demo",
          de: "Consumer Duty Demo",
        },
        publicProfile: "https://public.tableau.com/profile/ellen4268#!/",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1675697770/TIL%20Embedding%20Site/1_Overview_t0lqgh.png",
        dashboardDescription: {
          en: "With a focus on vulnerable consumers, this dashboard aims to provide insight through several levels of analysis; from the type and extent of vulnerability within a consumer base, to an overview of customer service performance, and finally, a look into each individual consumer journey. ",
          fr: "With a focus on vulnerable consumers, this dashboard aims to provide insight through several levels of analysis; from the type and extent of vulnerability within a consumer base, to an overview of customer service performance, and finally, a look into each individual consumer journey. .",
          es: "With a focus on vulnerable consumers, this dashboard aims to provide insight through several levels of analysis; from the type and extent of vulnerability within a consumer base, to an overview of customer service performance, and finally, a look into each individual consumer journey. ",
          it: "With a focus on vulnerable consumers, this dashboard aims to provide insight through several levels of analysis; from the type and extent of vulnerability within a consumer base, to an overview of customer service performance, and finally, a look into each individual consumer journey. ",
          de: "With a focus on vulnerable consumers, this dashboard aims to provide insight through several levels of analysis; from the type and extent of vulnerability within a consumer base, to an overview of customer service performance, and finally, a look into each individual consumer journey. ",
        },
      },
      {
        id: 3,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/Embedding/views/ComplaintsAnalysis/ComplaintsDashboard",
        author: "Jake Reilly",
        dashboardName: {
          en: "Complaints Analysis",
          fr: "Complaints Analysis",
          es: "Complaints Analysis",
          it: "Complaints Analysis",
          de: "Complaints Analysis",
        },
        publicProfile: "https://public.tableau.com/app/profile/jake.reilly5338",
        twitterProfile: "https://twitter.com/@JF_Reilly",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1675697977/TIL%20Embedding%20Site/Complaints_Dashboard_m77yoq.png",
        dashboardDescription: {
          en: "Customer Understanding is one of the four core categories for tracking how a company is performing around Consumer Duty. This dashboard takes a look at this category by exploring complaints data, with a special focus on complaints regarding poor communication and vulnerable customers. ",
          fr: "Customer Understanding is one of the four core categories for tracking how a company is performing around Consumer Duty. This dashboard takes a look at this category by exploring complaints data, with a special focus on complaints regarding poor communication and vulnerable customers. ",
          es: "Customer Understanding is one of the four core categories for tracking how a company is performing around Consumer Duty. This dashboard takes a look at this category by exploring complaints data, with a special focus on complaints regarding poor communication and vulnerable customers. ",
          it: "Customer Understanding is one of the four core categories for tracking how a company is performing around Consumer Duty. This dashboard takes a look at this category by exploring complaints data, with a special focus on complaints regarding poor communication and vulnerable customers. ",
          de: "Customer Understanding is one of the four core categories for tracking how a company is performing around Consumer Duty. This dashboard takes a look at this category by exploring complaints data, with a special focus on complaints regarding poor communication and vulnerable customers. ",
        },
      },
    ],
  },{
    id: 13,
    name: {
      en: "Sales Reporting",
      fr: "Sales Reporting",
      es: "Sales Reporting",
      it: "Sales Reporting",
      de: "Sales Reporting",
    },
    shortDescription: {
      en: "Explore how tableau can make sense of your sales and operations data. ",
      fr: "Explore how tableau can make sense of your sales and operations data. ",
      it: "Explore how tableau can make sense of your sales and operations data. ",
      es: "Explore how tableau can make sense of your sales and operations data. ",
      de: "Explore how tableau can make sense of your sales and operations data. ",
    },
    longDescription: {
      en: "Tableau makes sales reporting readily available, enabling users to quickly gain and communicate insight over the sales processes and operations carried out by your business.",
      fr: "Tableau makes sales reporting readily available, enabling users to quickly gain and communicate insight over the sales processes and operations carried out by your business.",
      it: "Tableau makes sales reporting readily available, enabling users to quickly gain and communicate insight over the sales processes and operations carried out by your business.",
      es: "Tableau makes sales reporting readily available, enabling users to quickly gain and communicate insight over the sales processes and operations carried out by your business.",
      de: "Tableau makes sales reporting readily available, enabling users to quickly gain and communicate insight over the sales processes and operations carried out by your business.",
    },
    slug: "/industry/salesreporting",
    dashboards: [
      {
        id: 0,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/Embedding/views/ARROverviewdemo/1CustomerARRoverview",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "ARR Overview",
          fr: "ARR Overview",
          it: "ARR Overview",
          es: "ARR Overview",
          de: "ARR Overview",
        },
        publicProfile: "https://public.tableau.com/app/profile/ellen4268",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1676557954/TIL%20Embedding%20Site/1_Customer_ARR_overview_nbnmuu.png",
        dashboardDescription: {
          en: "This dashboard presents a regional breakdown of annual recurring revenue (ARR), to shed insight into how revenue, accounts, and contracts are distributed across various product categories, and how these metrics change over time.",
          fr: "This dashboard presents a regional breakdown of annual recurring revenue (ARR), to shed insight into how revenue, accounts, and contracts are distributed across various product categories, and how these metrics change over time.",
          es: "This dashboard presents a regional breakdown of annual recurring revenue (ARR), to shed insight into how revenue, accounts, and contracts are distributed across various product categories, and how these metrics change over time.",
          it: "This dashboard presents a regional breakdown of annual recurring revenue (ARR), to shed insight into how revenue, accounts, and contracts are distributed across various product categories, and how these metrics change over time.",
          de: "This dashboard presents a regional breakdown of annual recurring revenue (ARR), to shed insight into how revenue, accounts, and contracts are distributed across various product categories, and how these metrics change over time.",
        },
      },
      {
        id: 1,
        hideTabs: false,
        url: "https://clientreporting.theinformationlab.co.uk/t/Embedding/views/Pipelinesummarydemo/Pipelinesummary",
        author: "Ellen Blackburn",
        dashboardName: {
          en: "Global Pipeline",
          fr: "Global Pipeline",
          it: "Global Pipeline",
          es: "Global Pipeline",
          de: "Global Pipeline",
        },
        publicProfile: "https://public.tableau.com/app/profile/ellen4268",
        twitterProfile: "https://twitter.com/ellenblackburnn",
        image:
          "https://res.cloudinary.com/dk4d9rvsl/image/upload/v1676558107/TIL%20Embedding%20Site/Pipeline_summary_rrcc49.png",
        dashboardDescription: {
          en: "These dashboards aim to provide greater context to pipeline views by showing performance in relation to budget, revenue breakdown across product categories, the distribution of opportunity age, and helping to identify stagnant opportunities.",
          fr: "These dashboards aim to provide greater context to pipeline views by showing performance in relation to budget, revenue breakdown across product categories, the distribution of opportunity age, and helping to identify stagnant opportunities.",
          es: "These dashboards aim to provide greater context to pipeline views by showing performance in relation to budget, revenue breakdown across product categories, the distribution of opportunity age, and helping to identify stagnant opportunities.",
          it: "These dashboards aim to provide greater context to pipeline views by showing performance in relation to budget, revenue breakdown across product categories, the distribution of opportunity age, and helping to identify stagnant opportunities.",
          de: "These dashboards aim to provide greater context to pipeline views by showing performance in relation to budget, revenue breakdown across product categories, the distribution of opportunity age, and helping to identify stagnant opportunities.",
        },
      },
    ],
  },
];
