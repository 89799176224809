import React, { useContext } from "react";
import { motion } from "framer-motion";
import { GlobalContext } from "../context/GlobalState";

let easing = [0.175, 0.85, 0.42, 0.96];

const imageVariants = {
  exit: { y: 100, opacity: 0, transition: { duration: 0.5, ease: easing } },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      ease: easing,
    },
  },
};

function Card({ example }) {
  const { language } = useContext(GlobalContext);
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={imageVariants}
      whileHover={{ scale: 1.03 }}
      className="shadow-md p-4 rounded-lg hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
    >
      <a
        href={example.displayURL}
        id={example.id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          key={example.id}
          className="flex items-center  justify-center h-12 w-12 rounded-md bg-til-blue text-white"
        >
          <svg
            className="h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
            />
          </svg>
        </div>
        <div className="mt-5">
          <h5 className="text-lg leading-6 font-medium text-gray-900">
            {example.title[language]
              ? example.title[language]
              : example.title["en"]}
          </h5>
          <p className="mt-2 text-base leading-6 text-gray-500">
            {example.description[language]
              ? example.description[language]
              : example.description["en"]}
          </p>
        </div>
      </a>
    </motion.div>
  );
}

export default Card;
