import React from "react";

function Toggle({ value, setValue, toggle }) {
  const colour = value ? "bg-red-600" : "bg-til-teal";
  return (
    <span
      onClick={toggle}
      className={`${colour} "relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"`}
      role="checkbox"
      tabIndex="0"
      aria-checked={value}
    >
      <span
        aria-hidden="true"
        className={
          value
            ? "translate-x-5 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
            : "translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
        }
      ></span>
    </span>
  );
}

export default Toggle;
