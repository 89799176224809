import React, { useEffect, useRef } from "react";
import { useOnClickOutside } from "../../utils/useOnClickOutside";

const YouTubeEmbed = ({ youtubeId }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 0,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        allowFullScreen
        frameBorder="0"
        title={youtubeId}
      />
    </div>
  );
};

function VideoModal({ isOpen, setIsopen, videoID, setvideoID }) {
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setIsopen(!isOpen);
    setvideoID(null);
  });

  useEffect(() => {
    console.log(videoID);
    return () => {
      setvideoID(null);
    };
  }, []);

  return (
    <div className={`${isOpen ? "block z-50" : "hidden"}`}>
      {videoID && (
        <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div
            ref={ref}
            className="bg-white rounded-lg px-4 pt-2 pb-2  overflow-hidden shadow-xl transform transition-all sm:max-w-4xl sm:w-full sm:p-6"
          >
            <div>
              <div className="text-center">
                <div>
                  <p className="text-sm leading-5 text-gray-500">
                    <YouTubeEmbed youtubeId={videoID} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoModal;
