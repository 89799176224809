import React from "react";
import Certified from "./Certified";
import ProfileImage from "./ProfileImage";

function DashboardCard({ toggleModal, dashboard, setDashboard }) {
  return (
    <div>
      {dashboard && (
        <li
          className="border-t border-gray-200"
          onClick={() => {
            toggleModal();
            setDashboard(dashboard);
          }}
        >
          <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                <div className="flex-shrink-0">
                  <ProfileImage projectName={dashboard.workbook.projectName} />
                </div>
                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                      {dashboard.name}
                    </div>
                    <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                      </svg>
                      <span className="truncate">
                        {dashboard.workbook.owner.name} <span>&#183;</span>{" "}
                        {dashboard.workbook.projectName}
                      </span>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div>
                      <div className="text-sm leading-5 text-gray-900">
                        Created at{" "}
                        <time dateTime="2020-01-07">
                          {new Date(dashboard.workbook.createdAt).toUTCString()}
                        </time>
                      </div>
                      {/* <svg
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Certified Datasource */}
                      <Certified certified={dashboard.workbook} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <svg
                  className="h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </li>
      )}
    </div>
  );
}

export default DashboardCard;
