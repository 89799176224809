import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

function AnimatedHeader({ value, setValue }) {
  const { language, Dictionary } = useContext(GlobalContext);
  const terms = {
    en: ["business", "company", "blog", "website", "portal", "wiki"],
    fr: ["entreprise", "site web", "portail", "wiki"],
    es: ["entreprise", "página web", "wiki", "portal"],
    it: ["business", "sito web", "wiki", "portale", "blog"],
    de: ["Ihrer Website", "Ihrem Intranet", "Ihrem Extranet"],
  };
  function getRandomWord() {
    const arrayLength = terms[language].length;
    const randomNumber = Math.floor(Math.random() * arrayLength);
    return randomNumber;
  }

  return (
    <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
      {Dictionary.home[language].title} <br className="xl:hidden" />
      <span className="text-til-blue">{terms[language][getRandomWord()]}</span>
    </h2>
  );
}

export default AnimatedHeader;
