/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "embeddingAPI",
            "endpoint": "https://dbff82p2g0.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "contactForm",
            "endpoint": "https://blb7y05t7l.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:ef2efbf2-1fd8-4ba8-9aae-cb5e1a84339b",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_0sy3DCVlI",
    "aws_user_pools_web_client_id": "1ev6t6qptov8dcidac8ehbbsr7",
    "oauth": {
        "domain": "embeddingtableau5e504912-5e504912-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/portal/dashboards/,https://embedding.theinformationlab.co.uk/portal/dashboards/",
        "redirectSignOut": "https://embedding.theinformationlab.co.uk/login/,http://localhost:3000/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
