import React from "react";
import { Router } from "@reach/router";

// import Google Analytics
import ReactGA from "react-ga";

// import Context
import { GlobalProvider } from "../src/context/GlobalState";

// bring in the pages
import Main from "./pages/Main";
import Basic from "./pages/Basic";
import Portal from "./pages/Portal";
import IndexPortal from "./pages/IndexPortal.js";
import Examples from "./pages/Examples";
import SignUpComponent from "./pages/SignUp";
import UserSettings from "./components/Portal/UserSettings";
import UserViews from "./components/Portal/UserViews";
import UserDashboards from "./components/Portal/UserDashboards";
import UserAccount from "./components/Portal/UserAccount";
import VizVocab from "./pages/VizVocab";
import Videos from "./pages/Videos";
import Contact from "./pages/Contact";
import Report from "./pages/Report";
import Notfound from "./pages/Notfound";
import AllIndustries from "./pages/AllIndustries";
import IndustryPage from "./pages/IndustryPage";
import IndustryDashboardPage from "./components/Industry/IndustryDashboardPage";
import TestComponent from "./components/Portal/TestComponent";
import AskData from "./pages/AskData";

// Find the dev environment, and only do GA in Prod
const { NODE_ENV } = process.env;
const GA_TRACKING_ID = NODE_ENV !== "development" ? "UA-141023410-3" : null; // This is your GA Tracking ID

ReactGA.initialize(GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <GlobalProvider>
      <Router>
        <TestComponent path="/testing" />
        <Main path="/" title={"Embedding Tableau | The Information Lab UK"} />
        <AllIndustries path="/industries" title={"All Industries"} />
        <IndustryPage path="/industry/:selectedIndustry" />
        <IndustryDashboardPage path="/industry/:selectedIndustry/:dashboard" />
        <Basic path="/basic" title={"Explore"} />
        <Contact path="/contact" title={"Contact Us"} />
        <Portal path="/login" title={"Portal"} />
        <IndexPortal path="/portal" title={"Portal Login"} />
        <UserSettings path="/portal/settings" />
        <UserDashboards path="/portal/dashboards" />
        <UserAccount path="/portal/account" />
        <UserViews path="/portal/views" />
        <Examples path="/examples" title={"Inspire"} />
        <Videos path="/videos" title={"Learn"} />
        <SignUpComponent path="/signup" />
        <VizVocab path="/vizvocab" />
        <Report path="/report" />
        <AskData path="/askdata" />
        <Notfound default title={"Oeps, 404!"} />
      </Router>
    </GlobalProvider>
  );
}

export default App;
