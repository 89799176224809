import React, { useEffect, useRef } from "react";
const { tableau } = window;

function DesktopEmbed({ url, viz, setViz, number, hideTabs }) {
  const ref = useRef(null);
  console.log(hideTabs);

  const initViz = () => {
    let options = {
      hideTabs: hideTabs,
      hideToolbar: true,
      device: "desktop",
      width: ref.offsetWidth,
      height: ref.offsetHeight,
      // onFirstInteractive: () => console.log("Desktop Viz loaded"),
    };
    setViz(new tableau.Viz(ref.current, url, options));
  };

  useEffect(() => {
    initViz();
    return () => {
      // console.log("Disposing viz!");
      if (viz) {
        viz.dispose();
      }
    };
  }, [url, number]);

  return <div ref={ref} />;
}

export default DesktopEmbed;
