export const content = [
  {
    displayURL: "https://www.eif4smes.com/",
    dateAdded: "2019-05-21",
    description: {
      en: "Embed project for EIF. Small and medium-sized enterprises supported by the European Union.",
      fr: "Projet intégré pour le FEI. Petites et moyennes entreprises soutenues par l'Union européenne.",
      es: "Proyecto de analítica embebida para EIF4SMEs. Pequeñas y medianas empresas apoyadas por la Unión Europea.",
      it: "Progetto Embed per il FEI. Piccole e medie imprese sostenute dall'Unione Europea.",
      de: "Projekt für den EIF einbetten. Kleine und mittelständische Unternehmen, die von der Europäischen Union unterstützt werden.",
    },
    title: {
      en: "EIF4SMEs",
      fr: "EIF4SMEs",
      es: "EIF4SMEs",
      it: "EIF4SMEs",
      de: "EIF4SMEs",
    },
    client: "Y",
    gsID: 1,
    githubURL: null,
    image: "Image1",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_scale,w_500/v1571155010/Project%20Copperfield/eif4smes.png",
  },
  {
    displayURL: "https://wdc.dev/health",
    dateAdded: "2019-05-21",
    description: {
      en: "Display data about the usage of the Web Data Connectors built by The Information Lab.",
      fr: "Afficher des données sur l'utilisation des connecteurs de données Web construits par The Information Lab.",
      es: "Visualización de datos a través de los Web Data Connectors desarrollados por The Information Lab.",
      it: "Visualizza i dati sull'uso dei Web Data Connectors costruiti da The Information Lab.",
      de: "Zeigt Daten über die Nutzung der von The Information Lab entwickelten Web Data Connectors an.",
    },
    title: {
      en: "TIL Web Data Connectors",
      fr: "Connecteurs de données Web TIL",
      es: "Conectores de Datos Web de TIL",
      it: "TIL Web Data Connectors",
      de: "TIL Web Data Connectors",
    },
    client: "N",
    gsID: 2,
    githubURL: null,
    image: "Image2",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_scale,w_500/v1571156177/Project%20Copperfield/wdcdev.png",
  },
  {
    displayURL: "https://react-tableau-jsapi.now.sh/",
    dateAdded: "2019-05-21",
    description: {
      en: "Examples and code snippets of embedding Tableau with ReactJS",
      fr: "Exemples et extraits de code d'incorporation Tableau avec ReactJS",
      es: "Ejemplos y fragmentos de código de analítica embebida con ReactJS",
      it: "Esempi e frammenti di codice per incorporare Tableau con ReactJS",
      de: "Beispiele und Codeausschnitte für die Einbettung von Tableau mit ReactJS",
    },
    title: {
      en: "React Tableau JS API Examples",
      fr: "React Tableau JS API Exemples",
      es: "Ejemplos de la Tableau JS API y React",
      it: "Esempi con le API React Tableau JS",
      de: "React Tableau JS API Beispiele",
    },
    client: "N",
    gsID: 3,
    githubURL: "https://github.com/andre347/tableau-react-jsapi",
    image: "Image3",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_scale,w_500/v1571154675/Project%20Copperfield/reactjssamples.png",
  },
  {
    displayURL: "https://www.artofthevizable.com/",
    dateAdded: "2019-05-21",
    description: {
      en: "A collaboration between Bethany Lyons (Tableau) and Craig Bloodworth (The Information Lab) that contains a whole host of examples of Set Actions.",
      fr: "Une collaboration entre Bethany Lyons (Tableau) et Craig Bloodworth (The Information Lab) qui contient une multitude d'exemples de Set Actions.",
      es: "Colaboración entre Bethany Lyons (Tableau) y Craig Bloodworth (The Information Lab) con toda una lista de ejemplos de Acciones de Conjunto.",
      it: "Una collaborazione tra Bethany Lyons (Tableau) e Craig Bloodworth (The Information Lab) che contiene tutta una serie di esempi di Set Actions.",
      de: "Eine Zusammenarbeit zwischen Bethany Lyons (Tableau) und Craig Bloodworth (The Information Lab) mit vielen Beispielen für Set Actions",
    },
    title: {
      en: "Art of the Vizable",
      fr: "L'art de la visualisation",
      es: "El arte de la visualización de datos",
      it: "Art of the Vizable",
      de: "Die Kunst des Vizable",
    },
    client: "N",
    gsID: 4,
    githubURL: null,
    image: "Image4",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_scale,w_500/v1571155359/Project%20Copperfield/artofthevizable.png",
  },
  {
    displayURL: "https://standdemos.theinformationlab.io/",
    dateAdded: "2019-05-21",
    description: {
      en: "Stand Demos is a page created by The Information Lab that demonstrates and displays Tableau Public visualisations that are created by the team at the Information Lab.",
      fr: "Stand Demos est une page créée par le Laboratoire d'information qui démontre et affiche les visualisations de Tableau Public qui sont créées par l'équipe de The Information Lab",
      es: "Las demos de stand es una página creada por The information Lab para demostrar visualizaciones de Tableau Public creadas por el equipo de The Information Lab.",
      it: "Stand Demos è una pagina creata da The Information Lab che dimostra e mostra le visualizzazioni di Tableau Public create dal team di Information Lab.",
      de: "Stand Demos ist eine Seite von The Information Lab. Sie zeigt Tableau Public-Visualisierungen, die das Team von The Information Lab erstellt hat.",
    },
    title: {
      en: "TIL Standdemos",
      fr: "TIL Standdemos",
      es: "TIL demos de stand",
      it: "TIL Standdemos",
      de: "TIL Standdemos",
    },
    client: "N",
    gsID: 5,
    githubURL: null,
    image: "Image5",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_scale,w_500/v1571155312/Project%20Copperfield/standdemos.png",
  },
  {
    displayURL: "https://alumni.theinformationlab.io/",
    dateAdded: "2019-10-14",
    description: {
      en: "Embed Example of where The Dataschool Alumni have ended up working upon graduation.",
      fr: "Exemple d'intégration des anciens élèves de la Dataschool en poste après l'obtention de leur diplôme.",
      es: "Ejemplo de dashboard embebido sobre donde han acabado trabajando los miembros del Alumni de nuestra Data School.",
      it: "Esempio di dove gli alunni della Dataschool sono finiti a lavorare dopo la scuola.",
      de: "Betten Sie hier Beispiele ein, wo die Dataschool Alumni nach ihrem Abschluss gearbeitet haben.",
    },
    title: {
      en: "Dataschool Alumni Map",
      de: "Dataschool Alumni Map",
      fr: "Dataschool Alumni Map",
      es: "Mapa del Alumni de Data School",
      it: "Mappa degli alumni della Dataschool",
    },
    client: "N",
    gsID: 6,
    githubURL: null,
    image: "Image6",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_scale,w_500/v1571154675/Project%20Copperfield/alumni.png",
  },
  {
    displayURL: "https://superstore.theinformationlab.io/",
    dateAdded: "2019-10-14",
    description: {
      en: "Embed Example of Sample Superstore Embedded",
      fr: "Exemple intégré de l'échantillon Superstore",
      es: "Ejemplo embebido de Supertienda de Tableau",
      it: "Il Sample Superstore incorporato",
      de: "Beispiel eines Sample Superstores Embedded",
    },
    title: {
      en: "Superstore Embedded",
      de: "Superstore Embedded",
      fr: "Superstore Embedded",
      es: "Supertienda Embebida",
      it: "Superstore incorporato",
    },
    client: "N",
    gsID: 7,
    githubURL: "https://github.com/AlexFrid/Tableau-Embed-Example",
    image: "Image7",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_scale,w_500/v1571154676/Project%20Copperfield/superstore.png",
  },
  {
    displayURL: "https://scrollytelling.theinformationlab.io/",
    dateAdded: "2020-03-05",
    description: {
      en: "Scrollytelling with Mapbox & Tableau - Global Wildfires",
      fr: "Scrollytelling with Mapbox & Tableau - Feux de foret dans le monde",
      es: "Scrollytelling con Mapbox & Tableau - Incendios en el mundo",
      it: "Scrollytelling con Mapbox e Tableau - Gli incendi globali",
      de: "Scrollytelling mit Mapbox & Tableau - Globale Waldbrände",
    },
    title: {
      en: "Scrollytelling Embedded - Part One",
      fr: "Scrollytelling Embedded - Première partie",
      es: "Scrollytelling Embedded - Primera parte",
      es: "Scrollytelling incorporato - Parte uno",
      de: "Scrollytelling Embedded - Teil eins",
    },
    client: "N",
    gsID: 8,
    githubURL: "https://github.com/AlexFrid/Tableau-Embed-Example",
    image: "Image7",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_thumb,w_200,g_face/v1583427097/Project%20Copperfield/scrollytelling.png",
  },
  {
    displayURL: "https://soha-elghany.github.io/US-Mexico-Border/",
    dateAdded: "2020-04-08",
    description: {
      en: "Scrollytelling with Mapbox & Tableau - US Mexico Border",
      fr: "Scrollytelling with Mapbox & Tableau - US Mexico Border",
      es: "Scrollytelling con Mapbox & Tableau - La frontera entre EEUU y México",
      it: "Scrollytelling con Mapbox e Tableau - Confine USA Messico",
      de: "Scrollytelling mit Mapbox & Tableau - US-Mexiko-Grenze",
    },
    title: {
      en: "Scrollytelling Embedded - Part Two",
      de: "Scrollytelling Embedded - Teil zwei",
      fr: "Scrollytelling Embedded - Seconde partie",
      es: "Scrollytelling Embedded - Segunda parte",
      it: "Scrollytelling incorporato - Parte seconda",
    },
    client: "N",
    gsID: 9,
    githubURL: "https://github.com/soha-elghany/US-Mexico-Border",
    image: "Image7",
    imageURL:
      "https://res.cloudinary.com/dmim37dbf/image/upload/c_thumb,w_200,g_face/v1583427097/Project%20Copperfield/scrollytelling.png",
  },
  {
    displayURL:
      "https://www.eloutlierincomprendido.com/relacion-entre-la-renta-y-el-voto-en-el-municipio-de-madrid/",
    dateAdded: "2020-08-26",
    description: {
      en: "Blog post in Spanish containing embed charts from Tableau Public",
      fr: "Article du blog en espagnol contenant des graphiques intégrés de Tableau Public",
      es: "Post en español con open data y gráficos embebidos desde Tableau Public",
      it: "Blog post in spagnolo contenente grafici incorporati da Tableau Public",
      de: "Blogbeitrag auf Spanisch mit eingebetteten Diagrammen von Tableau Public",
    },
    title: {
      en: "Relationship between income and voting in the municipality of Madrid",
      es: "Relación entre la renta y el voto en el municipio de Madrid",
      fr: "Relation entre le revenu et le vote à Madrid",
      it: "Relación entre la renta y el voto en el municipio de Madrid",
      de: "Zusammenhang zwischen Einkommen und Wahlbeteiligung in der Gemeinde Madrid",
    },
    client: "N",
    gsID: 10,
    githubURL: null,
    image: "Image7",
    imageURL: null,
  },
  {
    displayURL:
      "https://www.eloutlierincomprendido.com/analisis-de-pacientes-con-covid-19-en-espana-notificados-a-renave/",
    dateAdded: "2020-08-26",
    description: {
      en: "Blog post in Spanish containing embed charts from Tableau Public",
      fr: "Article de blog en espagnol contenant des graphiques intégrés de Tableau Public",
      es: "Post en español con open data y gráficos embebidos desde Tableau Public",
      it: "Blog post in spagnolo contenente grafici incorporati da Tableau Public",
      de: "Blogbeitrag auf Spanisch mit eingebetteten Diagrammen von Tableau Public",
    },
    title: {
      en: "Characteristics of patients with COVID-19",
      es: "Características de los pacientes con COVID-19",
      fr: "Caractéristiques des patients atteints de COVID-19",
      it: "Características de los pacientes con COVID-19",
      de: "Merkmale von Patient:innen mit COVID-19",
    },
    client: "N",
    gsID: 11,
    githubURL: null,
    image: "Image7",
    imageURL: null,
  },
  {
    displayURL: "https://cv19.theinformationlab.io/",
    dateAdded: "2020-09-10",
    description: {
      en: "Covid 19 Dashboard Updated Daily with the latest UK numbers",
      fr: "Covid 19 Dashboard mis à jour quotidiennement avec les derniers chiffres du Royaume-Uni",
      es: "Dashboard sobre la COVID-19 en el Reino Unido actualizado diariamente",
      it: "Dashboard aggiornata quotidianamente con gli ultimi numeri del Regno Unito sul Covid 19",
      de: "COVID-19 Dashboard mit tagesaktuellen Zahlen aus dem Vereinigten Königreich",
    },
    title: {
      en: "COVID-19 in the United Kingdom",
      fr: "COVID-19 au Royaume-Uni",
      es: "COVID-19 en el Reino Unido",
      it: "COVID-19 nel Regno Unito",
      de: "COVID-19 im Vereinigten Königreich",
    },
    client: "N",
    gsID: 12,
    githubURL: "https://github.com/TheInformationLab/UK-Covid-Dashboard",
    image: "Image7",
    imageURL: null,
  },
];
