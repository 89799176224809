import React, { useContext } from "react";
import { Link } from "@reach/router";
import IndustryIcons from "./IndustryIcons";
import {GlobalContext} from '../../context/GlobalState';

function IndustryCard(props) {
  const { name, shortDescription, slug, dashboards } = props.industry;
  const { language } = useContext(GlobalContext);
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0"></div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between hover:bg-gray-50">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-til-blue">
            <IndustryIcons icon={name['en']} />
          </p>
          <Link to={slug} className="block">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
              {name[language] ? name[language] : name['en']}
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-500">
              {shortDescription[language] ? shortDescription[language] : shortDescription['en']}
            </p>
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex text-sm leading-5 text-gray-500">
            <span>
              {dashboards.length}{" "}
              {dashboards.length > 1 ? "dashboards" : "dashboard"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndustryCard;
