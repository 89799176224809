import React from "react";
import LayoutPortal from "../components/Portal/LayoutPortal";
import DesktopEmbed from "../components/Tableau/DesktopEmbed";

function VizVocab() {
  const [viz, setViz] = React.useState(null);
  const [url, setUrl] = React.useState(
    "https://clientreporting.theinformationlab.co.uk/t/Copperfield-Dev/views/VisualVocabulary/VisualVocabulary"
  );
  const [number, setNumber] = React.useState(0);

  function goBacktoMain() {
    if (viz) {
      setViz(null);
      setNumber(() => number + 1);
      viz.dispose();
      setUrl(
        () =>
          "https://clientreporting.theinformationlab.co.uk/t/Copperfield-Dev/views/VisualVocabulary/VisualVocabulary"
      );
    }
  }

  return (
    <LayoutPortal>
      <div className="px-4 py-4 sm:px-6">
        <button
          type="button"
          className="inline-flex mb-4 items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
          onClick={() => {
            goBacktoMain();
          }}
        >
          Return to Main Page
        </button>
        <DesktopEmbed url={url} viz={viz} setViz={setViz} number={number} />
      </div>
    </LayoutPortal>
  );
}

export default VizVocab;
