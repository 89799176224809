import React from "react";

// load mobile component
import MobileEmbed from "./MobileEmbed";
// load desktop component
import DesktopEmbed from "./DesktopEmbed";

function TableauEmbed({ url, device, viz, setViz, exportPDFHandler }) {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  if (device) {
    return <MobileEmbed url={url} viz={viz} setViz={setViz} />;
  } else {
    return width < breakpoint ? (
      <MobileEmbed url={url} viz={viz} setViz={setViz} />
    ) : (
      <DesktopEmbed url={url} viz={viz} setViz={setViz} />
    );
  }
}

export default TableauEmbed;
