import React from "react";
import Layout from "../Layout";

import { Auth } from "aws-amplify";
import { navigate } from "@reach/router";
import { logout } from "../../utils/auth";
import { AuthenticatedRoute } from "../authentication/AuthenticatedRoute";

// function signOut() {
//   Auth.signOut()
//     .then((data) => console.log(data))
//     .catch((err) => console.log(err));
// }

function TestComponent() {
  return (
    <div>
      <AuthenticatedRoute>
        <Layout>
          <p>This is for testing the login</p>
          <button
            onClick={() =>
              Auth.signOut()
                .then(logout(() => navigate("/login")))
                .catch((err) => console.log("eror:", err))
            }
          >
            Sign Out
          </button>
        </Layout>
      </AuthenticatedRoute>
    </div>
  );
}

export default TestComponent;
