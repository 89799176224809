import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import Layout from "../components/Layout";
import { usePageTitle } from "../utils/usePageTitle";

import { Switch } from "@headlessui/react";
import { API } from "aws-amplify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getLanguage(lang = "en") {
  switch (lang) {
    case "en":
      return "UK&I";
    case "it":
      return "Italy";
    case "es":
      return "Spain";
    case "de":
      return "Germany";
    case "fr":
      return "France";
    default:
      return "UK&I";
  }
}

export default function Contact({ title }) {
  const [agreed, setAgreed] = useState(false);
  const [submit, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const { language, Dictionary } = useContext(GlobalContext);

  // Change Page title for each route
  usePageTitle(title);

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    let object = {};
    data.forEach((value, key) => (object[key] = value));
    object["country"] = getLanguage(language);

    try {
      let apiName = "contactForm";
      let path = "/contact";
      let myData = {
        body: object,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const resp = await API.post(apiName, path, myData);
      setSubmitted(true);
      console.log({ resp });
      event.target.reset();
      setError(false);
      return resp;
    } catch (error) {
      event.target.reset();
      setSubmitted(true);
      setError(true);
      console.log("Got an error", error);
      return;
    }
  }

  return (
    <Layout>
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <svg
            className="absolute left-full transform translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          {!submit && (
            <div className="text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Contact Us
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-500">
                Are you interested in Embedded Analytics with Tableau and want
                to setup a chat with
                <a className="font-medium text-gray-700">
                  {" "}
                  The Information Lab
                </a>
                ? Let us know via this form and we will get in touch!
              </p>
            </div>
          )}
          {submit && !error && (
            <div className="text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Thank you for contacting us
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-500">
                We will get in touch with you soon!
              </p>
            </div>
          )}
          {submit && error && (
            <div className="text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-red-900 sm:text-4xl">
                Error submitting your form
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-500">
                Please try again later!
              </p>
            </div>
          )}
          {!submit && (
            <div className="mt-12">
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="given-name"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      autoComplete="family-name"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="organization"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={6}
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      defaultValue={
                        "I would like to learn more about how my organisation can leverage Tableau for external facing analytics."
                      }
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Switch
                        checked={agreed}
                        onChange={setAgreed}
                        className={classNames(
                          agreed ? "bg-indigo-600" : "bg-gray-200",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                      >
                        <span className="sr-only">Agree to policies</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            agreed ? "translate-x-5" : "translate-x-0",
                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                    </div>
                    <div className="ml-3">
                      <p className="text-base text-gray-500">
                        By selecting this, you agree that we can contact you
                        regarding Embedded Analytics with Tableau
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <button
                    type="submit"
                    disabled={!agreed}
                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-til-blue hover:bg-til-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-til-purple"
                  >
                    Let's talk
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
