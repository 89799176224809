import React from "react";
import { motion } from "framer-motion";
import { videos } from "../utils/videoLinks";

let easing = [0.175, 0.85, 0.42, 0.96];

const imageVariants = {
  exit: { y: 100, opacity: 0, transition: { duration: 0.5, ease: easing } },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      ease: easing,
    },
  },
};

function VideoCard(props) {
  const { title, url, length, description, image } = props.video;
  const { setIsopen, isOpen, setvideoID, videoID } = props;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={imageVariants}
      whileHover={{ scale: 1.03 }}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer"
    >
      <div
        className="flex-shrink-0"
        onClick={() => {
          setIsopen(!isOpen);
          const id = url.split("=")[1];
          setvideoID(id);
        }}
      >
        <motion.img
          className="h-48 w-full object-cover"
          src={image}
          alt={title}
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between hover:bg-gray-50">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-til-blue">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              Video
            </a>
          </p>
          <a
            // href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
              {title}
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-500">
              {description}
            </p>
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex text-sm leading-5 text-gray-500">
            <span>{length} watch</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default VideoCard;
