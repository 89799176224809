export const setUser = (user) =>
  (window.localStorage.tableauUser = JSON.stringify(user));

const getUser = () => {
  if (window.localStorage.tableauUser) {
    let user = JSON.parse(window.localStorage.tableauUser);
    return user ? user : {};
  }
  return {};
};

export const getAuthTokenTableau = () => {
  if (window.sessionStorage.hasOwnProperty("authToken")) {
    let token = JSON.parse(window.sessionStorage.authToken);
    return token ? token : null;
  }
  return null;
};

export const setAuthTokenTableau = (token) => {
  window.sessionStorage.authToken = JSON.stringify(token);
};

export const isLoggedIn = () => {
  const user = getUser();
  if (user) return !!user.username;
};

export const gotSessionToken = () => {
  const token = getAuthTokenTableau();
  if (token) return !!token;
};

export const getCurrentUser = () => getUser();

export const logout = (callback) => {
  setUser(null);
  window.sessionStorage.authToken = null;
  callback();
};
