import React, { useEffect, useRef } from "react";
const { tableau } = window;

function MobileEmbed({ url, viz, setViz }) {
  const ref = useRef(null);

  const initViz = () => {
    let options = {
      hideTabs: true,
      hideToolbar: true,
      width: ref.offsetWidth,
      height: ref.offsetHeight,
      device: "mobile",
      // onFirstInteractive: () => console.log("Mobile Viz loaded"),
    };
    setViz(new tableau.Viz(ref.current, url, options));
  };

  useEffect(() => {
    initViz();
    return () => {
      // console.log("Disposing viz!");
      if (viz) {
        viz.dispose();
      }
    };
  }, []);

  return <div className="h-auto w-auto" ref={ref} />;
}

export default MobileEmbed;
