import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

// import Layout

import Layout from "../components/Layout";
// import tableau component
import TableauEmbed from "../components/Tableau/TableauEmbed";
// import mobile toggle
import Toggle from "../components/Toggle";
import { usePageTitle } from "../utils/usePageTitle";

function Basic({ title }) {
  const { language, Dictionary } = useContext(GlobalContext);
  const [value, setValue] = React.useState(false);
  const [viz, setViz] = React.useState(null);
  function toggle() {
    setValue(!value);
  }

  // Change Page title for each route
  usePageTitle(title);

  return (
    <Layout>
      <div className="max-w-screen-xl-til mx-auto py-6 sm:px-6 lg:px-8 antialiased">
        <div className="px-4 py-4 sm:px-0">
          <div className="rounded-lg h-auto">
            <div className="relative bg-white">
              <div className="relative max-w-screen-xl-til mx-auto px-4 py-8 sm:py-8 sm:px-6 lg:py-8">
                <div>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-til-blue text-white">
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
                    </svg>
                  </div>
                </div>
                <h3 className="mt-4 text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  {Dictionary.explore[language].title}
                </h3>
                <div className="mt-4">
                  <span className="leading-7 text-gray-400 sm:hidden md:block lg:block">
                    <Toggle value={value} setValue={setValue} toggle={toggle} />
                  </span>
                </div>
                <p className="mt-4 text-base leading-6 text-gray-500">
                  {Dictionary.explore[language].desc}
                </p>
              </div>
            </div>
          </div>
        </div>
        {viz && (
          <div className="max-w-screen-xl-til lg:px-8 antialiased">
            <div className="hidden sm:block">
              <div className="">
                <nav className="flex -mb-px">
                  <button
                    onClick={() => viz.showExportPDFDialog()}
                    className="group inline-flex items-center py-4 px-1 font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 "
                  >
                    <svg
                      className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>PDF</span>
                  </button>
                  <button
                    onClick={() => viz.showExportImageDialog()}
                    className="ml-8 group inline-flex items-center py-4 px-1 font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                  >
                    <svg
                      className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <span>Image</span>
                  </button>
                  <button
                    onClick={() => viz.showExportCrossTabDialog()}
                    className="ml-8 group inline-flex items-center py-4 px-1 font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                  >
                    <svg
                      className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6 13a4 4 0 010-8 4 4 0 118 0 4 4 0 010 8h-3V8a1 1 0 10-2 0v5H6zM9 13h2v2.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 15.586V13z"></path>
                    </svg>
                    <span>Data</span>
                  </button>
                  <button
                    onClick={() => viz.showExportPowerPointDialog()}
                    className="ml-8 group inline-flex items-center py-4 px-1 font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                  >
                    <svg
                      className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                    </svg>
                    <span>PowerPoint</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
        <div className="rounded-lg p-2 shadow-md mx-auto">
          <TableauEmbed
            url={Dictionary.explore[language].url}
            device={value}
            viz={viz}
            setViz={setViz}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Basic;
