export const getTrustedTicket = async (username, site) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");

  var raw = JSON.stringify({
    server: "https://clientreporting.theinformationlab.co.uk",
    username: username,
    site: site ? site : "Embedding",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    "https://0xnnbcwhgk.execute-api.eu-west-2.amazonaws.com/dev/tableau/ticket",
    requestOptions
  );
  const result = await response.text();
  return result;
  // .then(response => response.text())
  // .then(result => {
  //   return result;
  // })
  // .catch(error => {
  //   return error;
  // });
};
