import React, { useState, useRef } from "react";
import { Auth } from "aws-amplify";
import {
  logout,
  setUser,
  getCurrentUser,
  setAuthTokenTableau,
  getAuthTokenTableau,
} from "../../utils/auth";

import { Link, navigate } from "@reach/router";

// import navLink for the portal
import { NavLinkPortal } from "../../utils/Links";

import { AuthenticatedRoute } from "../authentication/AuthenticatedRoute";
import Footer from "../Footer";
// global context
import { GlobalContext } from "../../context/GlobalState";

export default function LayoutPortal({ children }) {
  const [open, setOpen] = useState(false);
  const [mobileOpen, setmobileOpen] = useState(false);
  const ref = useRef();
  // const [userAttributes, setUserAttributes] = useState({});
  const { userAttributes, setUserAttributes } = React.useContext(GlobalContext);
  // const { email } = getCurrentUser();

  // const userData = JSON.parse(window.localStorage.tableauUser);
  // // const firstName = userData["custom:firstname"];
  // // const email = userData.email;
  // // const picture = userData["custom:picture"];

  function toggle() {
    setOpen(!open);
    setmobileOpen(false);
  }
  function mobileToggle() {
    setmobileOpen(!mobileOpen);
  }

  function setUserDetails() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("Setting user details in global provider", user.attributes);
        setUserAttributes(user.attributes);
      })
      .catch((err) => {
        console.log(err);
        window.localStorage.setItem("error", err);
        window.localStorage.setItem("localStatedashboards", null);
        console.log("Error with the login object");
        navigate("/login/");
      });
  }

  React.useEffect(() => {
    setUserDetails();
  }, []);

  return (
    // <AuthenticatedRoute>
    <>
      {!userAttributes && <div>Loading..</div>}
      {userAttributes && (
        <div className="antialiased flex flex-col min-h-screen">
          <div className="bg-gray-800 pb-32">
            <nav className="bg-gray-800">
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="border-b border-gray-700">
                  <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 600 600"
                          className="h-8 w-8"
                        >
                          <path
                            d="M341.64 158.34c0-85.87-69.62-155.47-155.48-155.47-85.85 0-155.46 69.6-155.46 155.47 0 85.88 69.61 155.48 155.46 155.48 85.87 0 155.48-69.61 155.48-155.48"
                            fill="#3b97d3"
                          />
                          <path
                            d="M324.85 516.13c0-44.14-35.78-79.89-79.89-79.89-44.14 0-79.91 35.75-79.91 79.89 0 44.11 35.76 79.89 79.91 79.89 44.11-.01 79.89-35.79 79.89-79.89"
                            fill="#7ea9ba"
                          />
                          <path
                            d="M170.13 415.86c0-41.21-33.42-74.64-74.63-74.64-41.21 0-74.61 33.42-74.61 74.64 0 41.21 33.4 74.62 74.61 74.62s74.63-33.41 74.63-74.62"
                            fill="#f1893d"
                          />
                          <path
                            d="M578.38 353.48c0-73.24-59.38-132.63-132.64-132.63-73.23 0-132.62 59.39-132.62 132.63 0 73.25 59.4 132.64 132.62 132.64 73.26 0 132.64-59.4 132.64-132.64"
                            fill="#7383a9"
                          />
                          <circle
                            cx="95.54"
                            cy="564.89"
                            r="28.17"
                            fill="#010202"
                          />
                        </svg>
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline text-gray-300">
                          <NavLinkPortal
                            to="/portal/dashboards"
                            className="px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:text-white focus:bg-gray-700 bg-gray-900 text-white"
                          >
                            Home
                          </NavLinkPortal>
                          <NavLinkPortal
                            to="/vizvocab"
                            className="ml-4 px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                          >
                            Viz Vocabulary
                          </NavLinkPortal>
                          <NavLinkPortal
                            to="/report"
                            className="ml-4 px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                          >
                            Viz Creator
                          </NavLinkPortal>
                          <NavLinkPortal
                            to="/askdata"
                            className="ml-4 px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                          >
                            Viz Conversation
                          </NavLinkPortal>
                          <NavLinkPortal
                            to="#"
                            className="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                          >
                            About
                          </NavLinkPortal>
                          <NavLinkPortal
                            to="/"
                            className="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                          >
                            Back
                          </NavLinkPortal>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        <button className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700">
                          <svg
                            className="h-6 w-6"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                          </svg>
                        </button>
                        <div className="ml-3 relative">
                          <div>
                            <button
                              onClick={toggle}
                              className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                            >
                              <img
                                className="h-8 w-8 rounded-full"
                                src={userAttributes["custom:picture"]}
                                alt="logo"
                              />
                            </button>
                          </div>
                          <div
                            className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg ${
                              open ? "block" : "hidden"
                            }`}
                            ref={ref}
                          >
                            <div className="py-1 rounded-md bg-white shadow-xs">
                              <Link
                                to="/portal/account"
                                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                              >
                                Your Account
                              </Link>
                              <Link
                                to="/portal/settings"
                                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                              >
                                Settings
                              </Link>
                              <div
                                onClick={() =>
                                  Auth.signOut()
                                    .then(
                                      logout(() => {
                                        // make sure the dashboard local storage is set to null so it requests new ones when people login
                                        window.localStorage.setItem(
                                          "localStatedashboards",
                                          null
                                        );

                                        navigate("/login");
                                        setUserAttributes({});
                                      })
                                    )
                                    .catch((err) => console.log("eror:", err))
                                }
                                className="block select-none cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                              >
                                Logout
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      <button
                        onClick={mobileToggle}
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                      >
                        <svg
                          className="h-6 w-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className={`${
                              mobileOpen ? "hidden" : "inline-flex"
                            }`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                          <path
                            className={`${
                              mobileOpen ? "inline-flex" : "hidden"
                            }`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  mobileOpen ? "block" : "hidden"
                } border-b border-gray-700 md:hidden}`}
              >
                <div className="px-2 py-3 sm:px-3">
                  <NavLinkPortal
                    to="#"
                    className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Dashboard
                  </NavLinkPortal>
                  <NavLinkPortal
                    to="vizvocab"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Viz Vocabulary
                  </NavLinkPortal>
                  <NavLinkPortal
                    to="#"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Projects
                  </NavLinkPortal>
                  <NavLinkPortal
                    to="#"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Calendar
                  </NavLinkPortal>
                  <NavLinkPortal
                    to="#"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Reports
                  </NavLinkPortal>
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={userAttributes["custom.picture"]}
                        alt="logo"
                      />
                    </div>
                    <div className="ml-3">
                      <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                        {userAttributes.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2">
                    <NavLinkPortal
                      to="portal/account"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                    >
                      Your Account
                    </NavLinkPortal>
                    <NavLinkPortal
                      to="/portal/settings"
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                    >
                      Settings
                    </NavLinkPortal>
                    <div
                      onClick={() =>
                        Auth.signOut()
                          .then(logout(() => navigate("/login")))
                          .catch((err) => console.log("eror:", err))
                      }
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                    >
                      Sign out
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <header className="py-10">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                  Welcome! {userAttributes["custom:firstname"]}
                </h2>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-gray-500 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Updated as of 25/01/2021
                  </div>
                </div>
              </div>
            </header>
          </div>
          <main className="-mt-32 flex-grow">
            <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 flex-grow">
              <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                {children}
              </div>
            </div>
          </main>
          <Footer />
        </div>
      )}
    </>
    // {/* </AuthenticatedRoute> */}
  );
}
